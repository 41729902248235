import React, { useContext, useEffect, useState } from "react";
import Button from "../Button/Button";
import Modal from "../Modal/Modal";
import Textarea from "../Textarea/Textarea";
import "./Share.styles.scss";
import Autocomplete from "../Autocomplete/Autocomplete";
import { iUser } from "../../core/models/Users";
import { GlobalContext } from "../../GlobalContext";
import { iPublication } from "../../core/models/Publication";
import SharePreview from "./Preview/Preview";
import useNotification from '../../core/hooks/useNotification';
import axios from 'axios';
import { getApiLink } from '../../core/api';
import { getEnding } from '../../core/helpers';
import { IconShare } from '../../icons';

interface iProps {
    isOpened: boolean;
    close: () => void;
    sharedItem: iPublication;
    showPreview?: boolean;
}

interface iUserOption {
    id: number | string;
    title: string;
    subtitle?: string;
}

const Share: React.FC<iProps> = (
    {
        close,
        isOpened,
        sharedItem,
        showPreview,
    }
) => {
    const { usersData, getRem } = useContext(GlobalContext);
    const { addNotification } = useNotification();
    const [message, setMessage] = useState("");
    const [usersToOptions, setUsersToOptions] = useState<iUserOption[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<(number | string)[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleShare = async () => {
        setIsLoading(true);

        try {
            const params = {
                documentType: sharedItem.publicationType,
                articleId: sharedItem.id,
            };
            const users = usersData.filter(({ id }) => selectedUsers.includes(id));
            await axios.post(getApiLink(`SharedArticle/Share`, params), {
                users,
                message,
            });
            addNotification({
                message: (
                    <span>
                        You've shared <b>{sharedItem.title}</b> with your {getEnding('colleague', selectedUsers.length)}
                    </span>
                ),
                icon: <IconShare />,
            });
            close();
        } catch (error) {
            addNotification({
                message: <span>Something went wrong...</span>,
                status: 'error',
            });
        } finally {
            setIsLoading(false);
        }
    };
    const modalStyles = {
        container: {
            width: "100%",
            maxWidth: getRem(700),
            maxHeight: "92%",
            padding: 0,
            background: "black",
            marginLeft: "auto",
            marginRight: "auto",
            overflow: "auto"
        },
        overlay: {
            background: `rgba(40, 48, 54, 0.88)`
        },
        close: {
            display: "none"
        }
    };

    useEffect(() => {
        if (!usersData.length) return;
        const options = usersData.map((user: iUser) => ({
            id: user.id,
            title: user.email,
            subtitle: `${user.firstName} ${user.lastName}`
        }));
        setUsersToOptions(options);
    }, [usersData]);

    useEffect(() => {
        if (!isOpened) {
            setSelectedUsers([]);
            setMessage("");
        }
    }, [isOpened]);

    const handleChangeSelectUsers = (ids: (number | string)[]) => {
        setSelectedUsers(ids);
    };

    return (
        <Modal isOpened={isOpened} close={close} closeOnEsc style={modalStyles}>
            <div className="Share">
                {showPreview && (
                    <div className="Share__preview">
                        <SharePreview sharedItem={sharedItem} />
                    </div>
                )}

                <div className="Share__content">
                    <div className="Share__header">
                        <div className="Share__title">Share this item</div>
                    </div>

                    <div className="Share__body">
                        <form className="Share__form">
                            <div className="Share__form-row">
                                <label className="Share__form-group">
                                    <div className="Share__form-label">To</div>
                                </label>
                                <Autocomplete isDisabled={isLoading} options={usersToOptions} value={selectedUsers} onChange={handleChangeSelectUsers} />
                            </div>

                            <div className="Share__form-row">
                                <label className="Share__form-group">
                                    <div className="Share__form-label">Add message (optional)</div>
                                    <div className="Share__form-textarea">
                                        <Textarea isDisabled={isLoading} onChange={setMessage} value={message} placeholder="Type your message here" />
                                    </div>
                                </label>
                            </div>
                        </form>
                    </div>

                    <div className="Share__footer">
                        <Button className="Share__footer-action" onClick={close} options={["secondary"]}>
                            Close
                        </Button>
                        <Button
                            className="Share__footer-action"
                            onClick={handleShare}
                            options={["primary"]}
                            isDisabled={!selectedUsers.length || isLoading}
                        >
                            Share
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default Share;
