import { useRef } from 'react';
import { IconCheck } from '../../icons';
import cn from 'classnames';
import './Checkbox.styles.scss';

export type CheckboxColorType = 'red' | 'green' | 'white';

interface iProps {
    isChecked: boolean;
    isPartialChecked?: boolean;
    isDisabled?: boolean;
    isHiddenIcon?: boolean;
    isRadio?: boolean;
    label: string | React.ReactNode;
    modifiers?: string[];
    className?: string;
    isToggle?: boolean;
    color?: CheckboxColorType;
    onChange: () => void;
}

const Checkbox: React.FC<iProps> = (
    {
        isChecked,
        isPartialChecked,
        isDisabled,
        isHiddenIcon,
        isRadio,
        label,
        onChange,
        className,
        modifiers = [],
        isToggle,
        color = 'red',
    }
) => {
    const checkboxRef = useRef(null);

    const classList = cn(
        'Checkbox',
        `Checkbox--${color}`,
        modifiers.map((o) => `Button--${o}`),
        isChecked ? 'Checkbox--checked' : '',
        isPartialChecked ? 'Checkbox--partial-checked' : '',
        isDisabled ? 'Checkbox--disabled' : '',
        isToggle ? 'Checkbox--toggle' : '',
        className
    );

    const iconClassList = cn(
        "Checkbox__icon",
        isRadio ? "is-radio" : "",
        isToggle ? "is-toggle" : "",
    );

    return (
        <div className={classList}>
            <label className="Checkbox__label">
                <input
                    ref={checkboxRef}
                    type="checkbox"
                    onChange={onChange}
                    checked={isChecked}
                    className="Checkbox__input"
                    disabled={isDisabled}
                />
                {!isHiddenIcon && !isToggle && (
                    <div className={iconClassList}>
                        <IconCheck className="Checkbox__icon-image"/>
                    </div>
                )}

                {isToggle && (
                    <div className="Checkbox__toggle-icon"></div>
                )}

                <div className="Checkbox__text">{label}</div>
            </label>
        </div>
    );
};

export default Checkbox;
