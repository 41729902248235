import { iPublicationClient__API } from '../apiSchema';
import { iTag, Tag } from './Tag';
import { iFile, mapFile__Api } from './File';
import FileTypesEnum from '../enums/FileTypesEnum';
import PublicationTypesEnum, { getPublicationType } from '../enums/PublicationTypesEnum';
import { getApiLink } from '../api';
import { iSubscriptionPackage, SubscriptionPackage } from "./SubscriptionPackage";

export interface iPublication {
    id: number;
    title: string;
    description: string;
    image: string;
    thumbnail: string;
    featuredImage?: string;
    createdDate: string;
    numberOfPages: number;
    minutesToRead: number;
    hasAccess: boolean;
    isLiked: boolean;
    isAddedToReadingList: boolean;
    tags: iTag[];
    subscriptionPackages: iSubscriptionPackage[];
    buttonCategoryId: number;
    titleCategoryId: number;
    publicationId: number;
    publicationTitle: string;
    customLink?: string;
    files: iFile[];
    publicationType: PublicationTypesEnum;
    score?: number;
}

type iPublicationData = {
    subModel?: Partial<iPublication>;
    apiModel?: iPublicationClient__API;
};

export class Publication implements iPublication {
    id = Publication.defaultData.id;
    title = Publication.defaultData.title;
    description = Publication.defaultData.description;
    image = Publication.defaultData.image;
    thumbnail = Publication.defaultData.thumbnail;
    featuredImage = Publication.defaultData.featuredImage;
    createdDate = Publication.defaultData.createdDate;
    numberOfPages = Publication.defaultData.numberOfPages;
    minutesToRead = Publication.defaultData.minutesToRead;
    hasAccess = Publication.defaultData.hasAccess;
    isLiked = Publication.defaultData.isLiked;
    isAddedToReadingList = Publication.defaultData.isAddedToReadingList;
    tags: iTag[] = Publication.defaultData.tags;
    subscriptionPackages: iSubscriptionPackage[] = Publication.defaultData.subscriptionPackages;
    buttonCategoryId = Publication.defaultData.buttonCategoryId;
    titleCategoryId = Publication.defaultData.titleCategoryId;
    publicationId = Publication.defaultData.publicationId;
    publicationTitle = Publication.defaultData.publicationTitle;
    customLink = Publication.defaultData.customLink;
    files: iFile[] = Publication.defaultData.files;
    publicationType = Publication.defaultData.publicationType;
    score = Publication.defaultData.score;

    static defaultData: iPublication = {
        id: 0,
        title: '',
        description: '',
        image: '',
        thumbnail: '',
        featuredImage: '',
        createdDate: '',
        numberOfPages: 0,
        minutesToRead: 0,
        hasAccess: true,
        isLiked: false,
        isAddedToReadingList: false,
        tags: [],
        subscriptionPackages: [],
        buttonCategoryId: 0,
        titleCategoryId: 0,
        publicationId: 0,
        publicationTitle: '',
        customLink: '',
        files: [],
        publicationType: PublicationTypesEnum.Publication,
        score: undefined,
    };

    constructor(data?: iPublicationData) {
        if (data) {
            const { apiModel, subModel } = data;
            if (apiModel) {
                this.mapFromApi(apiModel);
            }
            else if (subModel) {
                this.mapFromClient(subModel);
            }
        }
    }

    private setData (model: iPublication) {
        ({
            id: this.id,
            title: this.title,
            description: this.description,
            image: this.image,
            thumbnail: this.thumbnail,
            featuredImage: this.featuredImage,
            createdDate: this.createdDate,
            numberOfPages: this.numberOfPages,
            minutesToRead: this.minutesToRead,
            hasAccess: this.hasAccess,
            isLiked: this.isLiked,
            isAddedToReadingList: this.isAddedToReadingList,
            tags: this.tags,
            subscriptionPackages: this.subscriptionPackages,
            files: this.files,
            buttonCategoryId: this.buttonCategoryId,
            titleCategoryId: this.titleCategoryId,
            publicationId: this.publicationId,
            publicationTitle: this.publicationTitle,
            publicationType: this.publicationType,
            customLink: this.customLink,
            score: this.score,
        } = model);

        this.files.sort((a, b) => a.type <= b.type ? -1 : 1);
    }

    private mapFromApi (apiModel: iPublicationClient__API) {
        const isMac = getPublicationType(apiModel.publicationType) === PublicationTypesEnum.Mac;
        const {
            id = Publication.defaultData.id,
            imageUrl,
            bigImageUrl,
            featuredContentImageUrl,
            createDate,
            numberOfPages = Publication.defaultData.numberOfPages,
            minutesToRead = Publication.defaultData.minutesToRead,
            hasAccess = Publication.defaultData.hasAccess,
            isLiked = Publication.defaultData.isLiked,
            isAddedToReadingList = Publication.defaultData.isAddedToReadingList,
            publicationType,
            tags,
            subscriptionPackages,
            files,
            score,
            ...rest
        } = apiModel;

        const macFile: iFile = {
            type: FileTypesEnum.Ppt,
            fileUrl: getApiLink('PublicationClient/GetMacDocument', { macId: id }),
        };

        this.setData({
            ...rest,
            id,
            numberOfPages,
            minutesToRead,
            // hasAccess: Math.random() > 0.5,
            hasAccess,
            isAddedToReadingList,
            isLiked,
            thumbnail: imageUrl,
            image: bigImageUrl,
            featuredImage: featuredContentImageUrl,
            createdDate: isMac ? '' : createDate,
            tags: tags.map(tag => new Tag({ apiModel: tag })),
            subscriptionPackages: (subscriptionPackages || []).map(item => new SubscriptionPackage({ apiModel: item })),
            files: isMac ? [macFile] : files.map(mapFile__Api).filter(f => f.type !== FileTypesEnum.NotSet),
            publicationType: getPublicationType(publicationType),
            customLink: isMac ? `${process.env.REACT_APP_SNAP}/view-document?id=${apiModel.id}&doctype=3` : '',
            score,
        });
    }

    private mapFromClient (clientModel: Partial<iPublication>) {
        this.setData({
            ...Publication.defaultData,
            ...clientModel,
        });
    }
}