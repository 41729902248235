import React, { useContext, useEffect, useMemo, useState } from 'react';
import Page from '../../components/Page/Page';
import Routes, { getRoutesByAria } from '../../core/Routes';
import { Link, useLocation } from 'react-router-dom';
import RoutesEnum, { RoutesAriaEnum } from '../../core/enums/RoutesEnum';
import Tabs, { iTabData } from '../../components/Tabs/Tabs';
import Shelve from '../../components/Shelve/Shelve';
import { getSlidesPerView } from '../../core/formatters';
import { GlobalContext } from "../../GlobalContext";
import { iPublication } from '../../core/models/Publication';
import Loader from '../../components/Loader/Loader';
import { splitArrayIntoChunks } from '../../core/helpers';
import './ReadingList.styles.scss';
import useReadingList from '../../core/apiHooks/useReadingList';
import BackTo from '../../components/BackTo/BackTo';
import NoResults from '../../components/NoResults/NoResults';
import { RemoveFromReadingListButtonContext } from '../../components/RemoveFromReadingListButton/RemoveFromReadingListButton';
import { TileContext } from '../../components/Tile/Tile';
import useReadingListShared from '../../core/apiHooks/useReadingListShared';

export default function ReadingList() {
    const { wWidth, getRem } = useContext(GlobalContext);
    const listPrivate = useReadingList();
    const listShared = useReadingListShared();
    const [activeData, setActiveData] = useState<iPublication[]>([]);
    const location = useLocation();
    const route =
    Routes.find(({ path }) => path === location.pathname) || Routes[0];
    const isSharedList = useMemo(() => route.path === RoutesEnum.SharedWithMeList, [route.path]);
    useEffect(() => {
        if ( isSharedList ) {

        }
        setActiveData(isSharedList ? listShared.data : listPrivate.data )
    }, [isSharedList, listPrivate.data, listShared.data]);
    const tabsData: iTabData[] = getRoutesByAria(
        RoutesAriaEnum.ReadingList
    ).map(({ title, path }) => ({ title, link: path }));

    const tilesPerView = getSlidesPerView(wWidth - 2 * getRem(48), 210);
    const rows: iPublication[][] = splitArrayIntoChunks(activeData, tilesPerView);
    const isLoading = listPrivate.isLoading || listShared.isLoading;

    return (
        <Page
            title={route!.title}
            headerContent={<BackTo />}
        >
            <RemoveFromReadingListButtonContext.Provider value={{ callback: listPrivate.reFetch }}>
                <TileContext.Provider value={{
                    isActiveRemove: true,
                    isShared: isSharedList
                }}>
                    <div className="ReadingList">
                        <div className="ReadingList__header">
                            <Tabs data={tabsData} />
                        </div>
                        <div className="ReadingList__content">
                            {isLoading && <Loader />}
                            {!isLoading && (
                                <>
                                    {rows.map((row, index) => (
                                        <Shelve
                                            key={index}
                                            items={row}
                                            columnsNumber={tilesPerView}
                                        />
                                    ))}
                                </>
                            )}

                            {!isLoading && rows.length === 0 && (
                                <div className="ReadingList__no-result">
                                    <NoResults
                                        title="Looks like nothing’s been added to your list yet."
                                        subTitle={
                                            <>
                                                {'Please go back to the '}
                                                <Link to={RoutesEnum.Home}>Report Pro homepage</Link>
                                                {' and try again.'}
                                            </>
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </TileContext.Provider>
            </RemoveFromReadingListButtonContext.Provider>
        </Page>
    );
}
