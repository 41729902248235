import FileTypesEnum, { getFileType } from '../enums/FileTypesEnum';
import { iFile__API } from '../apiSchema';
import { FunctionComponent } from 'react';
import { IconPdf, IconPpt, IconXls } from '../../icons';
import { getApiLink } from '../api';

export interface iFile {
    type: FileTypesEnum;
    fileUrl: string;
    name?: string;
}

export function mapFile__Api (file: iFile__API): iFile {
    return {
        type: getFileType(file.type),
        fileUrl: getApiLink('File/Get', { filename: file.filename }),
        name: file.filename
    };
}

interface iFileData {
    icon: FunctionComponent;
    title: string;
}

function getFileIcon (type: FileTypesEnum): FunctionComponent {
    switch(type) {
        case FileTypesEnum.Ppt: return IconPpt;
        case FileTypesEnum.Xml1: return IconXls;
        case FileTypesEnum.Xml2: return IconXls;
        default: return IconPdf;
    }
}

function getFileTitle (type: FileTypesEnum): string {
    switch(type) {
        case FileTypesEnum.Pdf: return 'Report PDF';
        case FileTypesEnum.Ppt: return 'Report PPT';
        case FileTypesEnum.Xml1: return 'Consumer Data Tables';
        case FileTypesEnum.Xml2: return 'Operator Data Tables';
        default: return 'other';
    }
}

export function getFileData(file: iFile): iFileData {
    return {
        icon: getFileIcon(file.type),
        title: getFileTitle(file.type),
    };
}