const EXTENSION_SIZE = 1;

export function getShiftedIndex(slidesCount: number, index: number, shift: number): number {
    const normalizedShift = shift % slidesCount;
    const dumbShiftedIndex = index + normalizedShift;

    if (dumbShiftedIndex < 0) return dumbShiftedIndex + slidesCount;
    if (dumbShiftedIndex >= slidesCount) return dumbShiftedIndex - slidesCount;
    return dumbShiftedIndex;
}

export function getSlides<T>(slides: T[], slidesPerView: number, startIndex: number): T[] {
    const extendedSlides: T[] = Array(EXTENSION_SIZE * 2 + 1).fill(slides).flat();


    const normalizedStartIndex = startIndex + slides.length * EXTENSION_SIZE;
    return extendedSlides.slice(normalizedStartIndex, normalizedStartIndex + slidesPerView);
}

export function getSlidesData(
    sliderMove: number,
    lastMove: number,
    slidesPerView: number,
    tilesTotalCount: number,
) {
    const currentSlideIndex = getShiftedIndex(5, 0, sliderMove);
    const prevSlideIndex = getShiftedIndex(5, currentSlideIndex, -lastMove);
    const nextSlideIndex = getShiftedIndex(5, currentSlideIndex, lastMove);
    const prePrevSlideIndex = getShiftedIndex(5, currentSlideIndex, -2 * lastMove);
    const hiddenSlideIndex = getShiftedIndex(5, currentSlideIndex, 2 * lastMove);

    const getTilesCount = (inx: number) => {
        switch (inx) {
            case currentSlideIndex:
                return slidesPerView;
            case prevSlideIndex:
                return slidesPerView;
            case nextSlideIndex:
                return 1;
            case prePrevSlideIndex:
                return 1;
            default:
                return 0;
        }
    };

    const getSlideTranslateX = (inx: number) => {
        switch (inx) {
            case currentSlideIndex:
                return '0%';
            case prevSlideIndex:
                return `${-lastMove * 100}%`;
            case nextSlideIndex:
                return `${lastMove * 100}%`;
            case prePrevSlideIndex:
                return `${-2 * lastMove * 100}%`;
            case hiddenSlideIndex:
                return `${2 * lastMove * 100}%`;
            default:
                return '0%';
        }
    };

    const getSlideTransitionDuration = (inx: number) => {
        switch (inx) {
            case hiddenSlideIndex:
                return `0ms`;
            default:
                return '1000ms';
        }
    };

    const getTilesPosition = (inx: number) => {
        switch (inx) {
            case nextSlideIndex:
                return lastMove === -1 ? 'end' : 'start';
            case prePrevSlideIndex:
                return lastMove === -1 ? 'start' : 'end';
            default:
                return 'start';
        }
    };

    const getTilesStartIndex = (inx: number) => {
        switch (inx) {
            case currentSlideIndex:
                return getShiftedIndex(tilesTotalCount, 0, slidesPerView * sliderMove);
            case prevSlideIndex:
                return getShiftedIndex(tilesTotalCount, 0, slidesPerView * (sliderMove - lastMove));
            case nextSlideIndex:
                return getShiftedIndex(
                    tilesTotalCount,
                    0,
                    lastMove === -1 ? slidesPerView * sliderMove - 1 : slidesPerView * (sliderMove + lastMove),
                );
            case prePrevSlideIndex:
                return getShiftedIndex(
                    tilesTotalCount,
                    0,
                    lastMove === -1 ? slidesPerView * ((sliderMove - lastMove) - lastMove) : slidesPerView * (sliderMove - lastMove) - lastMove,
                );
            default:
                return 0;
        }
    };

    return Array(5).fill(null).map((_, i) => ({
        count: getTilesCount(i),
        startIndex: getTilesStartIndex(i),
        position: getTilesPosition(i),
        translateX: getSlideTranslateX(i),
        duration: getSlideTransitionDuration(i),
    }));
}