import axios, { AxiosResponse } from 'axios';
import { getApiLink } from '../api';
import { iSnapUser__API } from '../apiSchema';
import { useData } from '../hooks/useData';
import { iUser, User } from '../models/Users';

async function getUsers(): Promise<iUser[]> {
    const response: AxiosResponse<iSnapUser__API[]> = await axios.get(
        getApiLink('User/GetSnapUsers')
    );
    return response.data.map(p => new User({ apiModel: p }));
}

export default function useUsers() {
    return useData([], getUsers);
}