import axios, { AxiosResponse } from 'axios';
import { ContentWatchDto } from '../apiSchema';
import { useData } from '../hooks/useData';
import { ContentWatch, iContentWatch } from '../models/ContentWatch';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getApiLink } from '../api';
import { ID } from '../models';
import { toggleInArray } from '../helpers';

async function getContentWatch(): Promise<ContentWatch> {
    const response: AxiosResponse<ContentWatchDto | null> = await axios.get(getApiLink('api/ContentWatch/Get'));
    return new ContentWatch({ apiModel: response.data || undefined });
}

async function saveContentWatch(data: ContentWatchDto): Promise<boolean> {
    await axios.post(getApiLink('api/ContentWatch/Post'), data);
    return true;
}

async function addTag(id: ID): Promise<boolean> {
    await axios.put(getApiLink(`api/ContentWatch/Tags/${id}`));
    return true;
}

async function removeTag(id: ID): Promise<boolean> {
    await axios.delete(getApiLink(`api/ContentWatch/Tags/${id}`));
    return true;
}

async function addSubscriptionPackage(id: ID): Promise<boolean> {
    await axios.put(getApiLink(`api/ContentWatch/SubscriptionPackages/${id}`));
    return true;
}

async function removeSubscriptionPackage(id: ID): Promise<boolean> {
    await axios.delete(getApiLink(`api/ContentWatch/SubscriptionPackages/${id}`));
    return true;
}

export default function useContentWatch() {
    const apiData = useData(new ContentWatch(), getContentWatch);
    const [data, updateData] = useState<ContentWatch>(new ContentWatch());
    const [isLoading, setIsLoading] = useState(false);

    const setData = useCallback((subModel: Partial<iContentWatch>) => {
        const model = {
            ...data,
            ...subModel,
        };

        updateData(new ContentWatch({ model }));
    }, [data]);

    const save = () => {
        setIsLoading(true);
        saveContentWatch(data.mapForApi()).then(() => {
            setIsLoading(false);
            apiData.reFetch();
        });
    };

    useEffect(() => {
        if (!apiData.isLoading) {
            console.log('here');
            updateData(apiData.data);
        }
    }, [apiData.isLoading, apiData.data]);

    const toggleTag = useCallback((id: ID) => {
        setIsLoading(true);
        const promise = data.tagsIds.includes(id) ? removeTag(id) : addTag(id);
        setData({
            tagsIds: toggleInArray(data.tagsIds, id),
        });

        promise.finally(() => {
            setIsLoading(false);
            // apiData.reFetch();
        });
    }, [data, apiData]);

    const toggleSubscriptionPackage = useCallback((id: ID) => {
        setIsLoading(true);
        const promise = data.subscriptionPackagesIds.includes(id) ? removeSubscriptionPackage(id) : addSubscriptionPackage(id);
        setData({
            subscriptionPackagesIds: toggleInArray(data.subscriptionPackagesIds, id),
        });

        promise.finally(() => {
            setIsLoading(false);
            // apiData.reFetch();
        });
    }, [data, apiData]);

    const getIsTagAdded = useCallback(
        (id: ID) => data.tagsIds.includes(id),
        [data]
    );

    const getIsSubscriptionPackageAdded = useCallback(
        (id: ID) => data.subscriptionPackagesIds.includes(id),
        [data]
    );

    return {
        data,
        isLoading: apiData.isLoading || isLoading,
        setData,
        save,
        toggleTag,
        toggleSubscriptionPackage,
        getIsTagAdded,
        getIsSubscriptionPackageAdded,
    };
}