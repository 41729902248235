import React, { useState } from "react";
import "./TileImage.style.scss";
import { IconDeny, IconLogoBig } from '../../icons';
import cn from "classnames";

interface iTileImageProps {
    src: string;
    isDisabled?: boolean;
    title?: string;
    id?: number;
    hideLoader?: boolean;
    lazyLoad?: boolean;
    deferLoad?: boolean;
    style?: any;
}

const TileImage: React.FC<iTileImageProps> = (
    {
        src,
        isDisabled = false,
        title,
        id,
        hideLoader = false,
        lazyLoad = false,
        deferLoad = false,
        style,
    }
) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [isShownLoader, setIsShownLoader] = useState(!hideLoader);
    const isImageShown = src && isImageLoaded && !deferLoad;
    const className = cn("TileImage", {
        // "TileImage--disabled": isDisabled,
        "TileImage--placeholder": !isImageShown
    });

    return (
        <div className={className} style={style}>
            {src && !deferLoad && (
                <img
                    onLoad={() => setIsImageLoaded(true)}
                    onError={() => setIsShownLoader(false)}
                    data-src={lazyLoad ? src : ""}
                    src={lazyLoad ? "" : src}
                    key={id}
                    className={`TileImage__img ${lazyLoad ? "swiper-lazy" : ""}`}
                    alt=""
                />
            )}
            {/*
                { lazyLoad && <div className="swiper-lazy-preloader">{isShownLoader && src && <Loader />}</div> }
             */}
            {isDisabled && (
                <div className="TileImage__lock">
                    <IconDeny/>
                </div>
            )}
            {!isImageShown && <div className="TileImage__title">{title}</div>}
            <div className="TileImage__logo">
                <IconLogoBig />
            </div>
        </div>
    );
};

export default TileImage;
