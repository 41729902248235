import React, { useContext } from 'react';
import Tile from '../Tile/Tile';
import './Grid.styles.scss';
import SectionTitle from '../SectionTitle/SectionTitle';
import { iContentRibbon } from '../../core/models/ContentRibbon';
import cn from 'classnames';
import { isNumberModulo } from '../../core/helpers';
import { GlobalContext } from "../../GlobalContext";

interface iProps {
    data: iContentRibbon;
    columnsNumber: number;
}

const Grid: React.FC<iProps> = ({ data, columnsNumber }) => {
    const { title, subTitle, items } = data;
    const { getRem } = useContext(GlobalContext);
    return (
        <div className={`Grid Grid--${columnsNumber}`}>
            <div className="Grid__title">
                <SectionTitle text={title} subText={subTitle} />
            </div>
            <div className="Grid__items">
                {items.map((item, index) => {
                    const classNames = cn(
                        'Grid__item',
                        isNumberModulo(columnsNumber, index + 1, 1) && 'is-first-visible',
                        isNumberModulo(columnsNumber, index + 1, 0) && 'is-last-visible',
                    );
                    return (
                        <div
                            key={item.id}
                            className={classNames}
                            style={{
                                width: `calc((100% - ${getRem(columnsNumber * 8)}px) / ${columnsNumber})`,
                                maxWidth: `calc((100% - ${getRem(columnsNumber * 8)}px) / ${columnsNumber})`
                            }}
                        >
                            <Tile data={item} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Grid;
