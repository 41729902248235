import { ID } from './index';
import { ContentWatchDto, FrequencyEnum } from '../apiSchema';

export enum Frequency {
    Immediate = 1,
    Weekly = 2,
    Monthly = 3,
    Off = 4,
}

function mapFrequencyFromApi(apiValue?: FrequencyEnum): Frequency {
    switch (apiValue) {
        case FrequencyEnum.NUMBER_1:
            return Frequency.Immediate;
        case FrequencyEnum.NUMBER_2:
            return Frequency.Weekly;
        case FrequencyEnum.NUMBER_3:
            return Frequency.Monthly;
        case FrequencyEnum.NUMBER_4:
            return Frequency.Off;
        default:
            return Frequency.Off;
    }
}

function mapFrequencyForApi(value?: Frequency): FrequencyEnum {
    switch (value) {
        case Frequency.Immediate:
            return FrequencyEnum.NUMBER_1;
        case Frequency.Weekly:
            return FrequencyEnum.NUMBER_2;
        case Frequency.Monthly:
            return FrequencyEnum.NUMBER_3;
        case Frequency.Off:
            return FrequencyEnum.NUMBER_4;
        default:
            return FrequencyEnum.NUMBER_4;
    }
}

export enum SubscriptionType {
    Everything = 1,
    Related = 2,
}

export interface iContentWatch {
    frequency: Frequency;
    subscriptionType: SubscriptionType;
    tagsIds: ID[];
    subscriptionPackagesIds: ID[];
    isInMyLibraryOnly: boolean;
}

type ApiModel = ContentWatchDto;

interface iData {
    apiModel?: ApiModel;
    model?: iContentWatch;
}

export class ContentWatch implements iContentWatch {
    static defaultValue: iContentWatch = {
        frequency: Frequency.Off,
        subscriptionType: SubscriptionType.Everything,
        tagsIds: [],
        subscriptionPackagesIds: [],
        isInMyLibraryOnly: true,
    }

    frequency = ContentWatch.defaultValue.frequency;
    subscriptionType = ContentWatch.defaultValue.subscriptionType;
    tagsIds = ContentWatch.defaultValue.tagsIds;
    subscriptionPackagesIds = ContentWatch.defaultValue.subscriptionPackagesIds;
    isInMyLibraryOnly = ContentWatch.defaultValue.isInMyLibraryOnly;

    constructor(data?: iData) {
        if (data) {
            if (data.apiModel) {
                this.mapFromApi(data.apiModel);
            } else if (data.model) {
                this.setData(data.model);
            }
        }
    }

    private setData(model: iContentWatch) {
        Object.assign(this, model);
    }

    public mapFromApi(apiModel: ApiModel) {
        const {
            isEverything,
            subscriptionPackages,
            userOnlySubscriptionPackage,
            tags,
            frequency,
        } = apiModel;

        this.setData({
            isInMyLibraryOnly: userOnlySubscriptionPackage ?? ContentWatch.defaultValue.isInMyLibraryOnly,
            subscriptionPackagesIds: subscriptionPackages || [],
            tagsIds: tags || [],
            subscriptionType: isEverything ? SubscriptionType.Everything : SubscriptionType.Related,
            frequency: mapFrequencyFromApi(frequency),
        });
    }

    public mapForApi (): ApiModel {
        return {
            isEverything: this.subscriptionType === SubscriptionType.Everything,
            subscriptionPackages: this.subscriptionPackagesIds as number[],
            userOnlySubscriptionPackage: this.isInMyLibraryOnly,
            tags: this.tagsIds as number[],
            frequency: mapFrequencyForApi(this.frequency),
        };
    }
}