import React, { createContext, FC, useContext } from 'react';
import { IconDelete, IconRL } from '../../icons';
import Button from '../Button/Button';
import ConfirmPopup from '../ConfirmPopup/ConfirmPopup';
import axios from 'axios';
import { getApiLink } from '../../core/api';
import useNotification from '../../core/hooks/useNotification';
import useConfirmPopup from '../../core/hooks/useConfirmPopup';
import { iPublication } from '../../core/models/Publication';
import { AnalyticsAction } from "../../core/models/AnalyticsAttributes";

interface iProps {
    className: string;
    data: iPublication;
}

interface iRemoveFromReadingListButtonContext {
    callback: () => void;
}

export const RemoveFromReadingListButtonContext = createContext<iRemoveFromReadingListButtonContext>({
    callback: () => {},
});

const RemoveFromReadingListButton: FC<iProps> = (
    {
        className,
        data,
    }
) => {
    const {
        id: articleId,
        title: articleTitle,
        publicationType: documentType,
    } = data;

    const { addNotification } = useNotification();
    const { callback } = useContext(RemoveFromReadingListButtonContext);
    const removeConfirm = useConfirmPopup();
    const handleRemove = () => {
        removeConfirm.show(articleId, articleTitle);
    }

    const removeItem = () => {
        addNotification({
            message: <span>You've removed <b>{articleTitle}</b> from your list</span>,
            icon:  <IconRL />
        });
        axios
            .post(getApiLink('FavouriteArticle/RemoveFromReadingList', { articleId, documentType }))
            .then(() => {
                callback();
            });
    }


    return (
        <>
            <Button className={className} onClick={handleRemove} tooltipText="Remove">
                <IconDelete className="Icon32" />
            </Button>
            <ConfirmPopup
                title={(
                    <>
                        Remove <span className="accent">{articleTitle}</span> from your list?
                    </>
                )}
                message="Are you sure to remove this item?"
                shown={removeConfirm.shown}
                onConfirm={() => removeItem()}
                closeModal={removeConfirm.hide}
                submitButtonLabel="Remove"
                confirmButtonAnalyticsData={{
                    action: AnalyticsAction.ReadingListRemove,
                    reportId: articleId,
                }}
            />
        </>
    );
};

export default RemoveFromReadingListButton;
