import { ListChildComponentProps } from "react-window";
import Checkbox from "../Checkbox/Checkbox";
import cn from "classnames";
import { iOption } from "./Select.helpers";
import { IconArrowDown } from "../../icons";
import { ID } from "../../core/models";

interface iOptionProps extends ListChildComponentProps {
    data: {
        options: iOption[];
        value: ID[];
        handleSelect: any;
        isCheckboxHidden?: boolean;
        handleSelectGroup?: any;
        isMultiple?: boolean;
        handleToggleGroup?: any;
    };
}

const Option: React.FC<iOptionProps> = ({
    index,
    style,
    data: { options, value, handleSelect, isCheckboxHidden, isMultiple, handleSelectGroup, handleToggleGroup }
}) => {
    const item = options[index];
    const isChecked = value.includes(item.id);
    const isNested = item.hasOwnProperty("groupId") && !item.isOptGroup;
    const isOptGroup = item.isOptGroup;

    const classList = cn(
        "Select__option",
        isChecked && isCheckboxHidden ? "is-selected" : "",
        isNested ? "is-nested" : "",
        isOptGroup ? "is-opt-group" : "",
        !isMultiple && isOptGroup ? "is-opt-group-single" : "",
        isNested && !isMultiple ? "is-nested-single" : ""
    );

    const handleClick = () => {
        if (isOptGroup) {
            const selectAll = !item.isChecked;
            if (isMultiple) {
                handleSelectGroup(item.groupId, selectAll);
            }
        } else {
            handleSelect(item.id);
        }
    };

    const toggleGroup = () => {
        handleToggleGroup(item.groupId);
    }

    return (
        <div className={classList} key={item.id} style={style}>
            {isOptGroup && (
                <>
                    <Checkbox
                        isChecked={item.isChecked || false}
                        isPartialChecked={item.isPartialChecked}
                        label={item.title}
                        onChange={handleClick}
                        className="Select__option-checkbox"
                        isHiddenIcon={isCheckboxHidden || !isMultiple}
                        isRadio={!isMultiple}
                    />
                    <div className={`Select__option-toggle ${item.isHidden ? "is-collapsed" : ""}`} onClick={toggleGroup}>
                        <IconArrowDown className="Select__option-toggle-icon" />
                    </div>
                </>
            )}

            {!isOptGroup && (
                <Checkbox
                    isChecked={isChecked}
                    label={item.title}
                    onChange={handleClick}
                    className="Select__option-checkbox"
                    isHiddenIcon={isCheckboxHidden}
                    isRadio={!isMultiple}
                />
            )}
        </div>
    );
};

export default Option;
