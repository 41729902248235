import { iSnapUser__API } from "../apiSchema";

export interface iCurrentUser {
    email: string;
    firstName: string;
    lastName: string;
    showOnboardingScreens: boolean;
    isInMyLibraryToggledOn: boolean;
    isAdmin: boolean;
}

interface iData {
    apiModel?: iSnapUser__API;
}

export class CurrentUser implements iCurrentUser {
    email = "";
    firstName = "";
    lastName = "";
    showOnboardingScreens = false;
    isInMyLibraryToggledOn = false;
    isAdmin = false;

    constructor(data?: iData) {
        if (data && data.apiModel) {
            this.mapFromApi(data.apiModel);
        }
    }

    private mapFromApi(data: iSnapUser__API) {
        this.email = data.email;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.showOnboardingScreens = data.showOnboardingScreens;
        this.isInMyLibraryToggledOn = data.isInMyLibraryToggledOn;
        this.isAdmin = data.isAdmin;
    }
}
