import './core/interceptors';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import './App.styles.scss';
import useWindowSizeDebounced from './core/hooks/useWindowSizeDebounced';
import { HelmetProvider } from 'react-helmet-async';
import { iUser } from './core/models/Users';
import { getCurrentUserInitials, getResponsiveScale } from './core/helpers';
import useCurrentUser from './core/apiHooks/useCurrentUser';
import useUsers from './core/apiHooks/useUsers';
import Loader from "./components/Loader/Loader";
import { GlobalContext } from "./GlobalContext";
import { addNotify, removeNotify } from "./globalNotifications";
import { AppId, cssX, Env, GtmInit, PlatformContextProvider } from "@datassential/platform-ui-lib";
import Router from "./core/router";
import { Slide, ToastContainer } from "react-toastify";
import cn from "classnames";

function AppContent() {
    const { currentUser: { isAdmin}, } = useContext(GlobalContext);
    const env = useMemo(
        () => {
            switch ((process.env.REACT_APP_PLATFORM_ENV || '').toLowerCase()) {
                case 'qa': return Env.QA;
                case 'staging': return Env.Staging;
                default: return Env.Live;
            }
        },
        []
    );

    return (
        <PlatformContextProvider
            appId={AppId.ReportPro}
            env={env}
            enableDevTools={env !== Env.Live}
            additionalUserMenu={isAdmin ? [{
                id: 'admin',
                content: (
                    <a
                        className={cn(cssX("Dropdown__item"), 'is-tall')}
                        target={'_blank'}
                        href={process.env.REACT_APP_ADMIN_URL ?? 'no-link'}
                    >
                        Tool Admin
                    </a>
                ),
            }] : undefined}
        >
            {process.env.NODE_ENV !== 'development' && <GtmInit/>}
            <Router/>
        </PlatformContextProvider>
    );
}

function App() {
    const [wWidth, wHeight] = useWindowSizeDebounced(1000);
    const [isSearchOpened, setIsSearchOpened] = useState(false);
    const [shouldOpenSearchOnType, setShouldOpenSearchOnType] = useState(true);
    const [isSearchDebugEnabled, setIsSearchDebugEnabled] = useState(false);
    const [users, setUsers] = useState<iUser[]>([]);
    const currentUser = useCurrentUser();
    const [currentUserInitials, setCurrentUserInitials] = useState("");
    const getCompanyUsers = useUsers();
    const [inMyLibrary, setInMyLibrary] = useState(true);

    useEffect(() => {
        setUsers(getCompanyUsers.data);
    }, [getCompanyUsers.data]);

    useEffect(() => {
        if ( !currentUser.data ) return;
        const userInitials = getCurrentUserInitials(currentUser.data);
        setCurrentUserInitials(userInitials);

        setInMyLibrary(currentUser.data.isInMyLibraryToggledOn);
    }, [currentUser.data]);

    const getRem = (num: number) => getResponsiveScale(num, wWidth);

    function toggleIsSearchOpened() {
        setIsSearchOpened(!isSearchOpened);
    }

    useEffect(() => {
        const cb = (event: MouseEvent) => {
            if (event.altKey && event.metaKey) {
                setIsSearchDebugEnabled(!isSearchDebugEnabled);
            }
        };
        window.addEventListener('click', cb, true);

        return () => {
            window.removeEventListener('click', cb, true);
        };
    }, [isSearchDebugEnabled]);

    return (
        <div className="App">
            <HelmetProvider>
                <GlobalContext.Provider
                    value={{
                        wWidth,
                        wHeight,
                        isSearchOpened,
                        toggleIsSearchOpened,
                        setIsSearchOpened,
                        shouldOpenSearchOnType,
                        setShouldOpenSearchOnType,
                        usersData: users,
                        getRem,
                        addNotify,
                        removeNotify,
                        currentUser: currentUser.data,
                        currentUserInitials,
                        inMyLibrary,
                        setInMyLibrary,
                        isSearchDebugEnabled,
                    }}
                >
                    <ToastContainer
                        position="bottom-right"
                        autoClose={5000}
                        hideProgressBar
                        closeOnClick
                        pauseOnFocusLoss
                        draggable={false}
                        pauseOnHover
                        transition={Slide}
                    />

                    {currentUser.isLoading ? <Loader/> : <AppContent/>}
                </GlobalContext.Provider>
            </HelmetProvider>
        </div>
    );
}

export default App;
