import "./FramePdf.styles.scss";

interface iProps {
    file: string;
    title: string;
}
const FramePdf: React.FC<iProps> = ({ file, title }) => {
    return <iframe className="FramePdf" title={title} src={file} itemType={"application/pdf"} />
}

export default FramePdf;