import axios from 'axios';
import { addNotify } from '../globalNotifications';
import { getApiLink } from './api';

axios.interceptors.response.use(
    (response) => {
        const newServerBuildVersion = response.headers['server_build_version'];
        const oldServerBuildVersion = localStorage.getItem('server_build_version');

        console.log(newServerBuildVersion);

        if (newServerBuildVersion) {
            if (!oldServerBuildVersion || oldServerBuildVersion === 'null') {
                localStorage.setItem('server_build_version', newServerBuildVersion)
            } else if (oldServerBuildVersion !== newServerBuildVersion) {
                localStorage.setItem('server_build_version', 'null');
                window.location.reload();
            }
        }

        return response;
    },
    (error) => {
        const config = error.config;
        if (!config) {
            return Promise.reject(error);
        }
        // eslint-disable-next-line
        if (error.response && error.response.status == 401) {
            window.location.href = getApiLink('userAuth', { returnUrl: window.location.href })!;
        }
        // eslint-disable-next-line
        if (error.response && error.response.status == 403) {
            window.location.href = process.env.REACT_APP_SNAP!;
        }
        // eslint-disable-next-line
        if (error.response && error.response.status == 500) {
            addNotify({
                message: "Something went wrong",
                status: "error",
                icon: null,
                id: "500"
            });
        }
        // eslint-disable-next-line
        if (error.response && error.response.status == 503) {
            addNotify({
                message: "Service unavailable.",
                status: "error",
                icon: null,
                id: "503"
            });
        }

        return Promise.reject(error.response.data);
    }
);