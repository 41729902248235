import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import './Tag.styles.scss';

const Tag: React.FC<{
    children: ReactNode[];
}> = ({
    children,
}) => {
    return (
        <div className="Tag">
            {children.map((child, index) => (
                <>
                    {index !== 0 && <div className="Tag__delimiter"/>}
                    <div className="Tag__chunk">
                        {child}
                    </div>
                </>
            ))}
        </div>
    )
};

export default Tag;
