import { iUseData, useData } from './useData';
import { useEffect, useState } from 'react';
import { useDebouncedCallback } from './useDebouncedCallback';

export function useDebouncedData<ResT>(
    debounceTimeMs: number,
    defaultData: ResT,
    fetchFn: (...reqData: any) => Promise<ResT>,
    ...reqData: any
): iUseData<ResT> {
    const [debReqData, setDebReqData] = useState(reqData);
    const [isLoading, setIsLoading] = useState(false);

    const updateReqData = useDebouncedCallback(() => {
        setDebReqData(reqData);
    }, debounceTimeMs);

    useEffect(() => {
        setIsLoading(true);
        updateReqData();
    }, [JSON.stringify(reqData)]);

    const data = useData(defaultData, fetchFn, ...debReqData);

    useEffect(() => {
        setIsLoading(data.isLoading);
    }, [data.isLoading]);

    return {
        ...data,
        isLoading,
    };
}