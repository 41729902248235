import React, { FC, useEffect, useState } from 'react';
import { IconAddedToRL, IconAddToRL, IconRL } from '../../icons';
import Button from '../Button/Button';
import axios from 'axios';
import { getApiLink } from '../../core/api';
import useNotification from '../../core/hooks/useNotification';
import { iPublication } from '../../core/models/Publication';
import { AnalyticsAction } from "../../core/models/AnalyticsAttributes";

interface iProps {
    className: string;
    data: iPublication;
}

const AddToReadingListButton: FC<iProps> = (
    {
        className,
        data,
    }
) => {
    const {
        isAddedToReadingList: initialValue,
        id: articleId,
        publicationType: documentType,
    } = data;

    const [isAdded, setIsAdded] = useState(initialValue);
    const { addNotification } = useNotification();
    useEffect(() => {
        setIsAdded(initialValue);
    }, [initialValue]);

    function toggleLiked(title: string) {
        setIsAdded(!isAdded);
        const requestName = isAdded ? 'RemoveFromReadingList' : 'AddToReadingList';
        const notifyMessage = isAdded ? <span>You've removed <b>{title}</b> from your list</span>: <span>You've added <b>{title}</b> to your list</span>;

        axios.post(getApiLink(`FavouriteArticle/${requestName}`, { articleId, documentType }));
        addNotification({
                message: <span>{notifyMessage}</span>,
                icon: isAdded ? <IconRL /> : <IconAddedToRL />
        });
    }

    return (
        <Button
            className={className}
            onClick={() => toggleLiked(data.title)}
            tooltipText={isAdded ? 'Remove from reading list' : 'Add to reading list'}
            analyticsData={{
                action: AnalyticsAction.ReadingListAdd,
                reportId: data.id,
            }}
        >
            {isAdded ? <IconAddedToRL className="Icon32" /> : <IconAddToRL className="Icon32" />}
        </Button>
    );
};

export default AddToReadingListButton;