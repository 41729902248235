import React, { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Page from '../../components/Page/Page';
import Loader from '../../components/Loader/Loader';
import axios from 'axios';
import { getApiLink } from '../../core/api';
import RoutesEnum from '../../core/enums/RoutesEnum';

const SnapDocumentDetails: React.FC<any> = () => {
    const match = useRouteMatch<{ id: string }>();
    const history = useHistory();

    useEffect(() => {
        axios.get(getApiLink('Article/GetId', { snapDocumentId: +match.params.id }))
            .then((result) => {
                history.replace(RoutesEnum.DocumentDetails.replace(':id', result.data));
            })
            .catch(() => {
                history.replace(RoutesEnum.Home)
            });
    }, []);


    return (
        <Page title={''}>
            <Loader />
        </Page>
    );
};

export default SnapDocumentDetails;
