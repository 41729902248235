export enum SortTypesEnum {
    TitleAsc = 1,
    TitleDesc = 2,
    DateAsc = 3,
    DateDesc = 4,
}

export enum FiltersEnum {
    ribbonsIds = "topics",
    categoriesIds = "category",
    subCategoriesIds = "subCategories",
    packages = 'packages',
    tags = 'tags',
    orderBy = "orderBy",
    sortOrder = "sortOrder"
}

export enum SORT_ENUM {
    ASC = "asc",
    DESC = "desc",
    DATE = "CreateDateToOrder",
    TITLE = "Title"
}

export interface iCategoryButton {
    id: number;
    title: string;
}

export const sortOptions = [
    {id: SortTypesEnum.DateDesc, title: "Newest first" },
    {id: SortTypesEnum.DateAsc, title: "Oldest first" },
    {id: SortTypesEnum.TitleAsc, title: "Publication name A → Z" },
    {id: SortTypesEnum.TitleDesc, title: "Publication name Z → A" },
];

export const getSortTypes = (type: number) => {
    switch (type) {
        case SortTypesEnum.TitleAsc:
            return { sortBy: SORT_ENUM.TITLE, direction: SORT_ENUM.ASC}
        case SortTypesEnum.TitleDesc:
            return { sortBy: SORT_ENUM.TITLE, direction: SORT_ENUM.DESC}
        case SortTypesEnum.DateAsc:
            return { sortBy: SORT_ENUM.DATE, direction: SORT_ENUM.ASC}
        case SortTypesEnum.DateDesc:
            return { sortBy: SORT_ENUM.DATE, direction: SORT_ENUM.DESC}
        default:
            return { sortBy: SORT_ENUM.DATE, direction: SORT_ENUM.DESC}
    }
}

export const getParamItems = (items: string | (string | null)[] | null) => {
    if ( !items ) return [];
    return (items as string).split(",").map((i) => +i);
}