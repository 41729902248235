import { FileTypesStringEnum } from "../enums/FileTypesEnum";

const PREFIX = 'data-reportpro';

export enum AnalyticsAction {
    Navigate = 'Navigate',
    Download = 'Download',
    Read = 'Read',
    ReadSample = 'Read Sample',
    GlobalSearchOpen = 'Global Search: Open',
    ReadingListAdd = 'Reading List: Add',
    ReadingListRemove = 'Reading List: Remove',
    Like = 'Like',
    Unlike = 'Unlike',
    RequestAccess = 'Request Access',
}

export interface AnalyticsAttributesData {
    action: AnalyticsAction;
    reportId?: number;
    reportTitle?: string;
    fileType?: FileTypesStringEnum;
}

enum AnalyticsAttributesEnum {
    Action = 'action',
    ReportId = 'report-id',
    ReportTitle = 'report-title',
    FileType = 'file-type',
}

function getAnalyticsAttributeName (name: AnalyticsAttributesEnum): string {
    return `${PREFIX}-${name}`;
}

export interface AnalyticsAttribute {
    key: AnalyticsAttributesEnum;
    value: string;
}

function getAvailableAttributes (data: AnalyticsAttributesData): AnalyticsAttribute[] {
    const attributes: AnalyticsAttribute[] = [
        { key: AnalyticsAttributesEnum.Action, value: data.action },
    ];

    if (data.reportId) {
        attributes.push({ key: AnalyticsAttributesEnum.ReportId, value: `${data.reportId}` },);
    }

    if (data.reportTitle) {
        attributes.push({ key: AnalyticsAttributesEnum.ReportTitle, value: `${data.reportTitle}` },);
    }

    if (data.fileType) {
        attributes.push({ key: AnalyticsAttributesEnum.FileType, value: `${data.fileType}` },);
    }

    return attributes;
}

export function getAnalyticsAttributes (data: AnalyticsAttributesData) {
    const attributes = getAvailableAttributes(data);

    const result: any = {};

    attributes.forEach((attribute) => {
        result[getAnalyticsAttributeName(attribute.key)] = attribute.value;
    });

    return result;
}