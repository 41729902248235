import React, { useContext, useState } from "react";
import { GlobalContext } from "../../GlobalContext";
import { iNotification, NotificationStatus } from "../../components/Notifications/NotificationsItem";

interface iNotificationAdd {
    message: React.ReactNode;
    status?: NotificationStatus;
    icon?: React.ReactNode;
    id?: string;
    customWidth?: string;
}
const getId = () => '_' + Math.random().toString(36).substr(2, 9);

export default function useNotification() {
    const { addNotify, removeNotify } = useContext(GlobalContext);
    const [notificationIds, setNotificationIds] = useState<string[]>([]);

    const addNotification = (params: iNotificationAdd) => {
        const { message, status, icon, customWidth } = params;
        const currentId = getId();
        setNotificationIds(prevState => [...prevState, currentId]);
        const notify: iNotification = {
            message: message,
            status: status || "default" as NotificationStatus,
            icon: icon || null,
            id: currentId,
            customWidth,
        };
        if ( notificationIds.length > 4 ) {
            removeNotify(notificationIds[0]);
            setNotificationIds(prevState => prevState.filter((_, index) => index > 0));
        }
        addNotify(notify);
    }

    return { addNotification };
}
