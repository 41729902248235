import { FC, ReactNodeArray, useContext, useEffect, useState } from 'react';
import { GlobalContext } from "../../GlobalContext";
import './ColumnSlider.styles.scss';
import { getSlides, getSlidesData } from './ColumnSlider.helpers';
import { IconArrowLeft, IconArrowRight } from '../../icons';
import cn from 'classnames';
import { isNumberModulo } from '../../core/helpers';
import VisibilitySensor from 'react-visibility-sensor';

interface iProps {
    children: ReactNodeArray;
    slidesPerView?: number;
}

const ColumnSlider: FC<iProps> = (
    {
        children,
        slidesPerView = 5,
    },
) => {
    const flatSlides = children.flat().filter(item => item);
    const [sliderMove, setSliderMove] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const [lastMove, setLastMove] = useState(-1);

    const slidesData = getSlidesData(sliderMove, lastMove, slidesPerView, flatSlides.length);
    const hasEnoughItems = flatSlides.length > slidesPerView;

    const { getRem } = useContext(GlobalContext);

    const moveSlider = (move: number) => {
        setLastMove(move);
        setSliderMove((prevIndex) => prevIndex + move);
    };

    const handleMoveForward = () => {
        moveSlider(1);
    };

    const handleMoveBackward = () => {
        moveSlider(-1);
    };

    const handleWindowKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'ArrowRight') {
            handleMoveForward();
        }
        if (event.key === 'ArrowLeft') {
            handleMoveBackward();
        }
    };

    useEffect(() => {
        if (isVisible) {
            window.addEventListener('keydown', handleWindowKeyDown);
        }
        return () => {
            window.removeEventListener('keydown', handleWindowKeyDown);
        };
    }, [isVisible]);

    const renderSlides = (slides: ReactNodeArray) => {
        return slides.map((slide, index) => {
            const classNames = cn(
                'ColumnSlider__slide',
                isNumberModulo(slidesPerView, index + 1, 1) && 'is-first-visible',
                isNumberModulo(slidesPerView, index + 1, 0) && 'is-last-visible',
            );
            return (
                <div
                    key={flatSlides.indexOf(slide)}
                    className={classNames}
                    style={{
                        width: `calc((100% - ${getRem(slidesPerView * 8)}px) / ${slidesPerView})`,
                        maxWidth: `calc((100% - ${getRem(slidesPerView * 8)}px) / ${slidesPerView})`
                    }}
                >
                    {slide}
                </div>
            );
        });
    };

    return (
        <div className="ColumnSlider">
            <div className="ColumnSlider__viewport">
                <VisibilitySensor
                    onChange={(isVisible) => {
                        setIsVisible(isVisible);
                    }}
                    partialVisibility={false}
                    offset={{
                        top: 100,
                        bottom: 100,
                    }}
                >
                    <div className="ColumnSlider__sensor"/>
                </VisibilitySensor>
                <div
                    className="ColumnSlider__slide ColumnSlider__slide--hidden"
                    style={{
                        width: `calc((100% - ${getRem(slidesPerView * 8)}px) / ${slidesPerView})`,
                    }}
                >
                    {flatSlides[0]}
                </div>
                {hasEnoughItems ? (
                    <>
                        <div
                            onClick={handleMoveBackward}
                            className="ColumnSlider__nav ColumnSlider__nav--prev"
                        >
                            <IconArrowLeft className="Icon32"/>
                        </div>
                        <div
                            onClick={handleMoveForward}
                            className="ColumnSlider__nav ColumnSlider__nav--next"
                        >
                            <IconArrowRight className="Icon32"/>
                        </div>
                        {Array(5).fill(null).map((_, i) => (
                            <div
                                key={i}
                                className="ColumnSlider__view"
                                style={{
                                    transitionDuration: slidesData[i].duration,
                                    transform: `translateX(${slidesData[i].translateX})`,
                                    justifyContent: `flex-${slidesData[i].position}`,
                                }}
                            >
                                {renderSlides(getSlides(flatSlides, slidesData[i].count, slidesData[i].startIndex))}
                            </div>
                        ))}
                    </>
                ) : (
                    <div className="ColumnSlider__view">
                        {renderSlides(flatSlides)}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ColumnSlider;