import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import './Tabs.styles.scss';

export interface iTabData {
    title: string;
    link?: string;
    onClick?: () => void;
    isActive?: boolean;
}

interface iProps {
    data: iTabData[];
}

const Tabs: React.FC<iProps> = ({ data }) => {
    return (
        <nav className="Tabs">
            {data.map((item) => {
                const { title, link, onClick, isActive } = item;
                const className: string = classNames('Tab', {
                    active: isActive
                });

                if (link) {
                    return (
                        <NavLink
                            key={title}
                            exact={true}
                            to={link}
                            className={className}
                        >
                            {title}
                        </NavLink>
                    );
                }

                return (
                    <div key={title} onClick={onClick} className={className}>
                        {title}
                    </div>
                );
            })}
        </nav>
    );
};

export default Tabs;
