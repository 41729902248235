import { FunctionComponent, ReactElement } from 'react';
import {
    LogoWorldBites,
    LogoCreativeConcepts,
    LogoInternationalConcepts,
    LogoFoodBytes,
    LogoHotShots,
    LogoTrendWatch,
    LogoDineAround,
    LogoKeynotes,
    LogoPulse,
    LogoBuzz,
    LogoMac,
} from '../../icons';

enum ImagedCategoryIdsEnum {
    Other,
    WorldBites = 19,
    CreativeConcepts = 20,
    InternationalConcepts = 21,
    FoodBytes = 22,
    HotShots = 27,
    TrendWatch = 32,
    DineAround = 33,
    Keynotes = 38,
    Pulse = 36,
    Buzz = 40,
    Mac = 100,
}

function getImagedCategory(categoryId: number): ImagedCategoryIdsEnum {
    switch(categoryId) {
        case 19: return ImagedCategoryIdsEnum.WorldBites;
        case 20: return ImagedCategoryIdsEnum.CreativeConcepts;
        case 21: return ImagedCategoryIdsEnum.InternationalConcepts;
        case 22: return ImagedCategoryIdsEnum.FoodBytes;
        case 27: return ImagedCategoryIdsEnum.HotShots;
        case 32: return ImagedCategoryIdsEnum.TrendWatch;
        case 33: return ImagedCategoryIdsEnum.DineAround;
        case 38: return ImagedCategoryIdsEnum.Keynotes;
        case 36: return ImagedCategoryIdsEnum.Pulse;
        case 40: return ImagedCategoryIdsEnum.Buzz;
        case 100: return ImagedCategoryIdsEnum.Mac;
        default: return ImagedCategoryIdsEnum.Other;
    }
}

function getCategoryImage(category: ImagedCategoryIdsEnum): FunctionComponent | null{
    switch (category) {
        case ImagedCategoryIdsEnum.WorldBites: return LogoWorldBites;
        case ImagedCategoryIdsEnum.CreativeConcepts: return LogoCreativeConcepts;
        case ImagedCategoryIdsEnum.InternationalConcepts: return LogoInternationalConcepts;
        case ImagedCategoryIdsEnum.FoodBytes: return LogoFoodBytes;
        case ImagedCategoryIdsEnum.HotShots: return LogoHotShots;
        case ImagedCategoryIdsEnum.TrendWatch: return LogoTrendWatch;
        case ImagedCategoryIdsEnum.DineAround: return LogoDineAround;
        case ImagedCategoryIdsEnum.Keynotes: return LogoKeynotes;
        case ImagedCategoryIdsEnum.Pulse: return LogoPulse;
        case ImagedCategoryIdsEnum.Buzz: return LogoBuzz;
        case ImagedCategoryIdsEnum.Mac: return LogoMac;
        default: return null;
    }
}

export function getCategoryImageById(categoryId: number): FunctionComponent | null {
    return getCategoryImage(getImagedCategory(categoryId));
}

export default ImagedCategoryIdsEnum;