import Toast from "../Toast/Toast";
import "./NotificationsItem.styles.scss";

export type NotificationStatus = "default" | "success" | "error";

export interface iNotification {
    message: React.ReactNode;
    status: NotificationStatus;
    icon: React.ReactNode;
    id: string;
    customWidth?: string;
}

const NotificationsItem: React.FC<{
    notify: iNotification;
}> = ({ notify }) => {
    const { status, icon, message } = notify;

    return (
        <Toast status={status} icon={icon}>
            {message}
        </Toast>
    );
};

export default NotificationsItem;
