import React, { FC, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import BackTo from '../../components/BackTo/BackTo';
import Routes from '../../core/Routes';
import Page from '../../components/Page/Page';
import { useLocation } from 'react-router-dom';
import './ContentWatch.styles.scss';
import { GlobalContext } from '../../GlobalContext';
import Button from '../../components/Button/Button';
import { ID } from '../../core/models';
import cn from 'classnames';
import Tooltip from '../../components/Tooltip/Tooltip';
import Checkbox from '../../components/Checkbox/Checkbox';
import useSubscriptionPackages from '../../core/apiHooks/useSubscriptionPackages';
import useTags from '../../core/apiHooks/useTags';
import Select from '../../components/Select/Select';
import { iOption } from '../../components/Select/Select.helpers';
import { TagsSelectType } from '../../components/Filters/Filters';
import { IconClose, IconPlusSquare } from '../../icons';
import { Frequency, SubscriptionType } from '../../core/models/ContentWatch';
import useContentWatch from '../../core/apiHooks/useContentWatch';
import Loader from '../../components/Loader/Loader';

const frequencyOptions: Array<{
    id: Frequency;
    title: string;
    statusStr?: string;
    tooltip: ReactNode;
}> = [
    {
        id: Frequency.Immediate,
        title: 'Immediate',
        statusStr: 'immediately',
        tooltip: <>We’ll email you as soon as new publications<br/> are available, which may be daily.</>,
    },
    {
        id: Frequency.Weekly,
        title: 'Weekly',
        statusStr: 'once a week',
        tooltip: <>We’ll email you once a week<br/> with new publications.</>,
    },
    {
        id: Frequency.Monthly,
        title: 'Monthly',
        statusStr: 'once a month',
        tooltip: <>We’ll email you once a month<br/> with new publications.</>,
    },
    {
        id: Frequency.Off,
        title: 'Off',
        tooltip: <>We won’t notify you<br/>about new publications.</>,
    },
];

const Tab: FC<{
    title: string;
    tooltip?: ReactNode;
    onClick: () => void;
    isActive?: boolean;
}> = ({
    title,
    tooltip,
    onClick,
    isActive,
}) => {
    return (
        <Button
            onClick={onClick}
            className={cn('ContentWatch__tab',)}
            options={[ isActive ? 'primary' : 'secondary' ]}
        >
            {title}
            {tooltip && (
                <Tooltip
                    className="ContentWatch__tooltip"
                    options={['bottom', 'bottom-gutter', 'gray']}
                >
                    {tooltip}
                </Tooltip>
            )}
        </Button>
    );
};

const ContentWatch: FC = () => {
    const { currentUser: { email }, getRem } = useContext(GlobalContext);
    const subscriptionPackages = useSubscriptionPackages();
    const tags = useTags();
    const {
        isLoading,
        data: {
            tagsIds,
            subscriptionPackagesIds,
            isInMyLibraryOnly,
            frequency,
            subscriptionType,
        },
        setData,
        save,
    } = useContentWatch();
    const location = useLocation();
    const route = Routes.find(({ path }) => path === location.pathname) || Routes[0];

    const statusText = useMemo(() => {
        if (frequency === Frequency.Off) {
            return 'Content Watch is off. You won’t receive any email notifications for new publications.';
        }
        const activeOption = frequencyOptions.find(o => o.id === frequency);
        if (activeOption) {
            let result = `We’ll email you ${activeOption.statusStr} with any new publications`;

            if (isInMyLibraryOnly) {
                result += ' in your subscription package';
            }

            if (subscriptionType === SubscriptionType.Related) {
                result += ' related to your topics only';
            }

            result += '.'

            return result;
        }

        return '';
    }, [frequency, subscriptionType, isInMyLibraryOnly]);

    const tagsOptions: iOption[] = useMemo(() => {
        return [
            ...subscriptionPackages.data
                .filter(item => item.title.toLowerCase().trim().indexOf('enable login') === -1)
                .filter(item => !subscriptionPackagesIds.includes(item.id))
                .map(item => ({
                    id: `${TagsSelectType.Package}-${item.id}`,
                    title: item.title,
                })),
            ...tags.data.filter(tag => !tagsIds.includes(tag.id)).map(tag => ({
                id: `${TagsSelectType.Tag}-${tag.id}`,
                title: tag.title,
            })),
        ];
    }, [
        tags.data,
        subscriptionPackages.data,
        tagsIds,
        subscriptionPackagesIds,
    ]);

    const handleChangeTagsSelect = useCallback((ids: ID[]) => {
        const id = ids[0];

        if (id) {
            const parsedType = (id as string).split('-')[0];
            const parsedId = +(id as string).split('-')[1];

            if (parsedId) {
                switch (parsedType) {
                    case TagsSelectType.Tag: {
                        setData({
                            tagsIds: [...tagsIds, parsedId],
                        });
                        break;
                    }
                    case TagsSelectType.Package: {
                        setData({
                            subscriptionPackagesIds: [...subscriptionPackagesIds, parsedId],
                        });
                        break;
                    }
                }
            }
        }
    }, [tagsIds, subscriptionPackagesIds, setData]);

    const selectedTags = useMemo(() => {
        return [
            ...subscriptionPackages.data
                .filter(item => subscriptionPackagesIds.includes(item.id))
                .map(item => ({
                    id: `${TagsSelectType.Package}-${item.id}`,
                    title: item.title,
                })),
            ...tags.data.filter(tag => tagsIds.includes(tag.id)).map(tag => ({
                id: `${TagsSelectType.Tag}-${tag.id}`,
                title: tag.title,
            })),
        ];
    }, [
        tags.data,
        subscriptionPackages.data,
        tagsIds,
        subscriptionPackagesIds,
    ]);

    const handleRemoveTag = useCallback((id: ID) => {
        const parsedType = (id as string).split('-')[0];
        const parsedId = +(id as string).split('-')[1];

        if (parsedId) {
            switch (parsedType) {
                case TagsSelectType.Tag: {
                    setData({
                        tagsIds: tagsIds.filter(i => i !== parsedId),
                    });
                    break;
                }
                case TagsSelectType.Package: {
                    setData({
                        subscriptionPackagesIds: subscriptionPackagesIds.filter(i => i !== parsedId),
                    });
                    break;
                }
            }
        }
    }, [tagsIds, subscriptionPackagesIds, setData]);

    return (
        <Page
            title={route!.title}
            headerContent={<BackTo />}
        >
            <div className="ContentWatch">
                <div className="ContentWatch__title">
                    Content Watch
                </div>
                <div className="ContentWatch__sub-title">
                    <b>Content Watch keeps you  up to date on new content as it’s published!</b>
                    Your Content Watch notification will be sent to <i>{email}</i>.
                </div>
                {isLoading ? <Loader/> : (
                    <>
                        <div className="ContentWatch__section">
                            <div className="ContentWatch__status">
                                <span>Current Settings:&nbsp;</span>{statusText}
                            </div>
                        </div>
                        <div className="ContentWatch__section">
                            <div className="ContentWatch__sub-title"><b>Frequency</b></div>
                            <div className="ContentWatch__columns">
                                <div>How often would you like to receive emails?</div>
                                <div className="ContentWatch__tabs col4">
                                    {frequencyOptions.map(option => (
                                        <Tab
                                            key={option.id}
                                            title={option.title}
                                            tooltip={option.tooltip}
                                            onClick={() => setData({ frequency: option.id })}
                                            isActive={option.id === frequency}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className={cn(
                            "ContentWatch__section",
                            'mb-small',
                            frequency === 4 && 'is-disabled',
                        )}>
                            <div className="ContentWatch__sub-title"><b>Content Preference</b></div>
                            <div className="ContentWatch__columns">
                                <div>What would you like to be notified about?</div>
                                <div className="ContentWatch__tabs col2">
                                    <Tab
                                        title="Tell me everything"
                                        onClick={() => setData({ subscriptionType: SubscriptionType.Everything, })}
                                        isActive={subscriptionType === SubscriptionType.Everything}
                                    />
                                    <Tab
                                        title="My topics only"
                                        onClick={() => setData({ subscriptionType: SubscriptionType.Related, })}
                                        isActive={subscriptionType === SubscriptionType.Related}
                                    />
                                </div>
                            </div>
                        </div>
                        {subscriptionType === SubscriptionType.Related && (
                            <div className={cn(
                                "ContentWatch__section",
                                'mt-small',
                                'mb-big',
                                frequency === 4 && 'is-disabled',
                            )}>
                                <div>Topics I’m following:</div>
                                {selectedTags.length > 0 && (
                                    <div className="ContentWatch__topics">
                                        {selectedTags.map(tag => (
                                            <div
                                                key={tag.id}
                                                onClick={() => handleRemoveTag(tag.id)}
                                                className="FilterTag"
                                            >
                                                {tag.title}
                                                <IconClose className="FilterTag__icon"/>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                <Select
                                    inputButton
                                    buttonOptions={["filters"]}
                                    buttonContent={(
                                        <>
                                            <IconPlusSquare/>
                                            &nbsp;&nbsp;add topic
                                        </>
                                    )}
                                    options={tagsOptions}
                                    isLoading={tags.isLoading || subscriptionPackages.isLoading}
                                    value={[]}
                                    onChange={handleChangeTagsSelect}
                                    isSearchable
                                    autoFocus
                                    dropdownHeight={240}
                                    closeOnSelect={false}
                                    isChipsView
                                    isClearable
                                    styles={{
                                        selectDropdown: {
                                            width: getRem(400),
                                            transform: `translateY(-${getRem(50)}px)`
                                        },
                                        selectContainer: {
                                            paddingTop: getRem(10),
                                            width: getRem(140),
                                        }
                                    }}
                                >
                                </Select>
                            </div>
                        )}
                        <div className={cn(
                            "ContentWatch__section",
                            frequency === 4 && 'is-disabled',
                        )}>
                            <div className="ContentWatch__columns">
                                <Checkbox
                                    isChecked={isInMyLibraryOnly}
                                    label="Only include publications in my subscription package"
                                    onChange={() => setData({ isInMyLibraryOnly: !isInMyLibraryOnly, })}
                                />
                                <div/>
                            </div>
                        </div>
                        <div className="ContentWatch__section">
                            <div className="ContentWatch__columns">
                                <div/>
                                <Button
                                    options={['primary']}
                                    onClick={save}
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Page>
    );
};

export default ContentWatch;
