export interface iAdminRibbon__API {
    id: number;
    name: string;
    publicationIds: number[];
}

export interface iArticleAdmin__API {
    id: number;
    title: string;
    description: string;
    tagsIds: number[];
    tags?: iTagShort__API[] | null;
    numberOfPages: number;
    minutesToRead: number;
    bigImageName?: string | null;
    featuredContentImageName?: string | null;
    files: iFile__API[];
}

export interface iButtonCategory__API {
    titleCategories?: iTitleCategory__API[] | null;
    buttonCategories?: iTitleCategory__API[] | null;
}

export interface iChapterAdmin__API {
    id: number;
    title: string;
    description: string;
    tagsIds: number[];
    tags?: iTagShort__API[] | null;
    numberOfPages: number;
    minutesToRead: number;
    bigImageName?: string | null;
    featuredContentImageName?: string | null;
    files: iFile__API[];
    publicationId: number;
    pageFrom: number;
    pageTo: number;
}

export interface iChapter__API {
    id: number;
    title: string;
    snapDocumentId: number;
    createdDate: string;
}

export interface ContentWatchDto {
    frequency?: FrequencyEnum;
    isEverything?: boolean;
    userOnlySubscriptionPackage?: boolean;
    subscriptionPackages?: number[] | null;
    tags?: number[] | null;
}

export interface ContentWatchEmailModel {
    emailTo?: string | null;
    userFirstName?: string | null;
    tags?: string[] | null;
    userOnlySubscriptionPackage?: boolean;
    publications?: ContentWatchEmailPublicationModel[] | null;
    userId?: number;
    contentWatchLink?: string | null;
}

export interface ContentWatchEmailPublicationModel {
    id?: number;
    image?: string | null;
    name?: string | null;
    description?: string | null;
    link?: string | null;
}

export enum DocumentType {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

export interface iFile__API {
    filename: string;
    type: string;
}

export enum FrequencyEnum {
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4
}

export interface GetAllWithFilterModel {
    ribbonsIds?: number[] | null;
    categoriesIds?: number[] | null;
    subscriptionPackageIds?: number[] | null;
    tagsIds?: number[] | null;
    skip?: number;
    take?: number;
    orderBy?: string | null;
    sortOrder?: string | null;
    hasAccess?: boolean;
}

export enum HttpStatusCode {
    NUMBER_100 = 100,
    NUMBER_101 = 101,
    NUMBER_102 = 102,
    NUMBER_103 = 103,
    NUMBER_200 = 200,
    NUMBER_201 = 201,
    NUMBER_202 = 202,
    NUMBER_203 = 203,
    NUMBER_204 = 204,
    NUMBER_205 = 205,
    NUMBER_206 = 206,
    NUMBER_207 = 207,
    NUMBER_208 = 208,
    NUMBER_226 = 226,
    NUMBER_300 = 300,
    NUMBER_301 = 301,
    NUMBER_302 = 302,
    NUMBER_303 = 303,
    NUMBER_304 = 304,
    NUMBER_305 = 305,
    NUMBER_306 = 306,
    NUMBER_307 = 307,
    NUMBER_308 = 308,
    NUMBER_400 = 400,
    NUMBER_401 = 401,
    NUMBER_402 = 402,
    NUMBER_403 = 403,
    NUMBER_404 = 404,
    NUMBER_405 = 405,
    NUMBER_406 = 406,
    NUMBER_407 = 407,
    NUMBER_408 = 408,
    NUMBER_409 = 409,
    NUMBER_410 = 410,
    NUMBER_411 = 411,
    NUMBER_412 = 412,
    NUMBER_413 = 413,
    NUMBER_414 = 414,
    NUMBER_415 = 415,
    NUMBER_416 = 416,
    NUMBER_417 = 417,
    NUMBER_421 = 421,
    NUMBER_422 = 422,
    NUMBER_423 = 423,
    NUMBER_424 = 424,
    NUMBER_426 = 426,
    NUMBER_428 = 428,
    NUMBER_429 = 429,
    NUMBER_431 = 431,
    NUMBER_451 = 451,
    NUMBER_500 = 500,
    NUMBER_501 = 501,
    NUMBER_502 = 502,
    NUMBER_503 = 503,
    NUMBER_504 = 504,
    NUMBER_505 = 505,
    NUMBER_506 = 506,
    NUMBER_507 = 507,
    NUMBER_508 = 508,
    NUMBER_510 = 510,
    NUMBER_511 = 511
}

export enum ImageType {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

export interface iMacSnapLight__API {
    id?: number;
    title?: string | null;
    createDate?: string | null;
    updateDate?: string | null;
    bigImageName?: string | null;
}

export interface iPublicationAdmin__API {
    id: number;
    title: string;
    description: string;
    tagsIds: number[];
    numberOfPages: number;
    minutesToRead: number;
    bigImageName?: string | null;
    featuredContentImageName?: string | null;
    files: iFile__API[];
    price?: number | null;
    isPublished?: boolean;
    createDate: string;
    buttonCategoryId?: number;
    titleCategoryId?: number;
    chapters?: iChapterAdmin__API[] | null;
    subscriptionPackageIds: number[];
    tags?: iTagShort__API[] | null;
}

export interface iPublicationClientDetail__API {
    publication: iPublicationClient__API;
    relatedPublications: iPublicationClient__API[];
}

export interface iPublicationClient__API {
    id?: number;
    title: string;
    description: string;
    imageUrl: string;
    tags: iTagShort__API[];
    numberOfPages: number;
    minutesToRead: number;
    isLiked: boolean;
    isShared: boolean;
    isAddedToReadingList: boolean;
    hasAccess: boolean;
    publicationType: DocumentType;
    files: iFile__API[];
    createDateToOrder?: string;
    bigImageUrl: string;
    featuredContentImageUrl: string;
    price: number;
    createDate: string;
    updateDate: string;
    buttonCategoryId: number;
    titleCategoryId: number;
    chapters: iPublicationClient__API[];
    publicationId: number;
    pageFrom: number;
    pageTo: number;
    hasChapters?: boolean;
    publicationTitle: string;
    score?: number;
    isPublished?: boolean;
    subscriptionPackages?: iSubscriptionPackage__API[] | null;
}

export interface iPublicationClientWrapper__API {
    data?: iPublicationClient__API[] | null;
}

export interface iPublicationInfo__API {
    id?: number;
    title?: string | null;
    buttonCategoryName?: string | null;
    titleCategoryName?: string | null;
    buttonCategoryId?: number;
    titleCategoryId?: number;
    featureIds?: number[] | null;
    createDate?: string;
}

export interface iPublicationShort__API {
    id: number;
    title: string;
    createDate: string;
    titleCategoryName: string;
    subscriptionPackageIds?: number[] | null;
}

export interface iPublication__API {
    id: number;
    title: string;
    snapDocumentId: number;
    createdDate: string;
    chapterCount: number;
    chapters: iChapter__API[];
    titleCategoryName: string;
    buttonCategoryName: string;
}

export interface iPublicationsBlock__API {
    items?: iPublicationClient__API[] | null;
    totalCount?: number;
}

export interface iRibbon__API {
    id: number;
    name: string;
    publications: iPublicationClient__API[];
}

export interface SearchRequestDto {
    query?: string | null;
    takeDocs?: number;
}

export interface SearchRequestModel {
    q?: string | null;
    inMyLibrary?: boolean;
    skipDocs?: number;
    takeDocs?: number;
    skipMacs?: number;
    takeMacs?: number;
}

export interface iSearch__API {
    macsResults?: iPublicationClient__API[] | null;
    documentsResults?: iPublicationClient__API[] | null;
    macsResultsCount?: number;
    otherResultsCount?: number;
}

export interface iSharePublication__API {
    users?: iSnapUser__API[] | null;
    message: string;
}

export interface iSnapPublication__API {
    id: number;
    title: string;
    snapDocumentId: number;
    createdDate: string;
    chapterCount: number;
    chapters: iChapter__API[];
    publicationFamily: string;
}

export interface iSnapUser__API {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    showOnboardingScreens: boolean;
    companyName: string;
    companyId: number;
    isInMyLibraryToggledOn: boolean;
    isAdmin: boolean;
}

export interface iSubscriptionPackage__API {
    id: number;
    name: string;
}

export interface iTagShort__API {
    id: number;
    value: string;
}

export interface iTag__API {
    id: number;
    value: string;
    publicationsIds?: number[] | null;
}

export interface TestEmail {
    emailBcc?: string | null;
    html?: string | null;
}

export interface iTitleCategory__API {
    id: number;
    name: string;
}

export interface iUserAccessRequest__API {
    firstName: string;
    lastName: string;
    companyName: string;
    articleId: number;
    articleTitle: string;
    userId: number;
    documentType: DocumentType;
    titleCategoryName: string;
    packages: string;
    email: string;
}
