import { getResponsiveScale } from "./helpers";

export function makeEnding(string: string, count: number = 0) {
    switch (string) {
        default: {
            return `${string}${count === 1 ? '' : 's'}`;
        }
    }
}

export function getSlidesPerView(
    viewportWidth: number,
    approxTileWidth: number
) {
    return Math.floor(viewportWidth / getResponsiveScale(approxTileWidth, viewportWidth));
}

export function formatThumbSrc(src: string, size: number) {
    return src.replace('size=182', `size=${size}`);
}
