import React, { useContext } from 'react';
import './Shelve.styles.scss';
import { iPublication } from '../../core/models/Publication';
import Tile from '../Tile/Tile';
import { GlobalContext } from "../../GlobalContext";
import cn from 'classnames';
import { isNumberModulo } from '../../core/helpers';

interface iProps {
    items: iPublication[];
    columnsNumber: number;
}

const Shelve: React.FC<iProps> = ({ items, columnsNumber }) => {
    const { getRem } = useContext(GlobalContext);

    return (
        <div className="Shelve">
            <div className="Shelve__items">
                {items.map((item, index) => {
                    const classNames = cn(
                        'Shelve__item',
                        isNumberModulo(columnsNumber, index + 1, 1) && 'is-first-visible',
                        isNumberModulo(columnsNumber, index + 1, 0) && 'is-last-visible',
                    );
                    return (
                        <div
                            key={item.id}
                            className={classNames}
                            style={{
                                width: `calc((100% - ${getRem((columnsNumber - 1) * 8)}px) / ${columnsNumber})`,
                                maxWidth: `calc((100% - ${getRem((columnsNumber - 1) * 8)}px) / ${columnsNumber})`
                            }}
                        >
                            <Tile
                                data={item}
                                hasBottomReflection
                            />
                        </div>
                    );
                })}
                {Array(columnsNumber - items.length)
                    .fill(null)
                    .map((_, i) => (
                        <div
                            key={`fake-${i}`}
                            className="Shelve__item"
                            style={{
                                width: `calc((100% - ${getRem((columnsNumber - 1) * 8)}px) / ${columnsNumber})`,
                                maxWidth: `calc((100% - ${getRem((columnsNumber - 1) * 8)}px) / ${columnsNumber})`
                            }}
                        />
                    ))}
            </div>
            <div
                className="Shelve__desk"
                style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/wood.png)`
                }}
            />
        </div>
    );
};

export default Shelve;
