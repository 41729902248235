import axios, { AxiosResponse } from 'axios';
import { iTagShort__API } from '../apiSchema';
import { getApiLink } from '../api';
import { useData } from '../hooks/useData';
import { iTag, Tag } from '../models/Tag';

async function getTags(): Promise<iTag[]> {
    const response: AxiosResponse<iTagShort__API[]> = await axios.get(getApiLink('Tag/GetAllShort'));

    return response.data.map(p => new Tag({ apiModel: p }));
}

export default function useTags() {
    return useData([], getTags);
}