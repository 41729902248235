import axios, { AxiosResponse } from 'axios';
import { iButtonCategory__API } from '../apiSchema';
import { getApiLink } from '../api';
import { useData } from '../hooks/useData';
import { Category } from '../models/Categories';

async function getCategories(): Promise<Category> {
    const response: AxiosResponse<iButtonCategory__API> = await axios.get(
        getApiLink('Category/GetAllCategories')
    );
    return new Category({ apiModel: response.data });
}

export default function useCategories() {
    return useData(null, getCategories);
}