import { Link } from 'react-router-dom';
import RoutesEnum from '../../core/enums/RoutesEnum';
import './NoResults.styles.scss';
import { FC, ReactChild } from 'react';

interface iProps {
    title?: ReactChild;
    subTitle?: ReactChild;
    isAbsolute?: boolean;
}

const NoResults: FC<iProps> = (
    {
        title = 'Hmm, we didn\'t find any results.',
        subTitle = (
            <>
                {'Please go back to the '}
                <Link to={RoutesEnum.Home}>Report Pro homepage</Link>
                {' and try again.'}
            </>
        ),
        isAbsolute
    }
) => {
    return (
        <div className={`NoResults ${isAbsolute ? 'is-absolute' : ''}`}>
            <div className="NoResults__message">{title}</div>
            <div className="NoResults__backward">{subTitle}</div>
        </div>
    );
};

export default NoResults;
