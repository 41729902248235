import axios, { AxiosResponse } from 'axios';
import { getApiLink } from '../api';
import { useData } from '../hooks/useData';
import { CurrentUser, iCurrentUser } from '../models/CurrentUser';
import { iSnapUser__API } from "../apiSchema";

async function getCurrentUser(): Promise<iCurrentUser> {
    const response: AxiosResponse<iSnapUser__API> = await axios.get(
        getApiLink('User/GetUser')
    );
    return new CurrentUser({ apiModel: response.data });
}

export default function useCurrentUser() {
    return useData(new CurrentUser(), getCurrentUser);
}