import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Modal from '../../../components/Modal/Modal';
import { GlobalContext } from '../../../GlobalContext';
import qs from "query-string";
import Scrollbar from '../../../components/Scrollbar/Scrollbar';
import Loader from '../../../components/Loader/Loader';
import { getSlidesPerView, makeEnding } from '../../../core/formatters';
import useSearchResults from '../../../core/apiHooks/useSearchResults';
import { ContentRibbon } from '../../../core/models/ContentRibbon';
import NoResults from '../../../components/NoResults/NoResults';
import { isLetterTyped, isTargetInput } from '../../../core/helpers';
import Grid from '../../../components/Grid/Grid';
import Button from '../../../components/Button/Button';
import { IconArrowRight, IconBack } from '../../../icons';
import './GlobalSearch.styles.scss';
import Checkbox from "../../../components/Checkbox/Checkbox";
import RoutesEnum from '../../../core/enums/RoutesEnum';
import { getQueryString } from '../../../core/api';

function isAllowedRoute(locationPath: string) {
    const DISALLOWED_ROUTES: string[] = [
        RoutesEnum.ContentWatch,
    ];
    return !DISALLOWED_ROUTES.includes(locationPath);
}

export default function GlobalSearch() {
    const {
        wWidth,
        shouldOpenSearchOnType,
        isSearchOpened,
        setIsSearchOpened,
        inMyLibrary,
        setInMyLibrary,
    } = useContext(GlobalContext);

    const [value, setValue] = useState('');
    const location = useLocation();
    const history = useHistory();
    const bestTilesCount = 3;
    const otherTilesCount = getSlidesPerView(wWidth - 100, 210);
    const macsTilesCount = getSlidesPerView(wWidth - 100, 210);
    const [isOtherResultsOpened, setIsOtherResultsOpened] = useState(false);
    const [isMacsResultsOpened, setIsMacsResultsOpened] = useState(false);

    const {
        isLoading,
        data: { macsResults, macsResultsCount, docsResults, docsResultsCount }
    } = useSearchResults(
        value,
        inMyLibrary,
        {
            takeDocs: bestTilesCount + otherTilesCount,
            takeMacs: macsTilesCount
        }
    );

    const {
        isLoading: isRestMacsLoading,
        data: { macsResults: restMacsResults }
    } = useSearchResults(
        value,
        inMyLibrary,
        {
            takeMacs: isMacsResultsOpened ? macsTilesCount * 10 : 0,
            skipMacs: macsTilesCount
        }
    );

    const {
        isLoading: isRestOtherLoading,
        data: { docsResults: restOtherResults }
    } = useSearchResults(
        value,
        inMyLibrary,
        {
            takeDocs: isOtherResultsOpened ? otherTilesCount * 10 : 0,
            skipDocs: bestTilesCount + otherTilesCount
        }
    );

    const bestResults = docsResults.slice(0, bestTilesCount);
    const otherResults = docsResults.slice(bestTilesCount);
    const otherResultsCount = docsResultsCount - bestTilesCount;
    const allOtherResults = [...otherResults, ...restOtherResults];
    const allMacsResults = [...macsResults, ...restMacsResults];

    function handleDocumentKeyDown(event: KeyboardEvent) {
        if (!isTargetInput(event) && !isSearchOpened && isAllowedRoute(location.pathname) && isLetterTyped(event)) {
            event.stopPropagation();
            setIsSearchOpened(true);
        }
    }

    const hasResults = docsResultsCount > 0 || macsResultsCount > 0;

    useEffect(() => {
        return () => {
            if (isSearchOpened) {
                setTimeout(() => {
                    setValue('');
                }, 300);
            }
        }
    }, [isSearchOpened]);

    useEffect(() => {
        if (!isSearchOpened && shouldOpenSearchOnType) {
            document.addEventListener('keydown', handleDocumentKeyDown, {
                capture: true
            });
        }
        return () => {
            document.removeEventListener('keydown', handleDocumentKeyDown, {
                capture: true
            });
        };
    }, [isSearchOpened, shouldOpenSearchOnType, location]);

    useEffect(() => {
        const handleSearchClick = (event: MouseEvent) => {
            if (isSearchOpened && (event.target as HTMLElement)?.nodeName.toLowerCase() === 'a') {
                const oldSearch = qs.parse(location.search);

                history.replace({
                    search: getQueryString({
                        ...oldSearch,
                        q: value,
                    }),
                })
            }
        };

        document.addEventListener('click', handleSearchClick, { capture: true });
        return () => {
            document.removeEventListener('click', handleSearchClick, { capture: true });
        };
    }, [isSearchOpened, value, location, history]);

    useEffect(() => {
        const parsed = qs.parse(location.search);
        const searchQuery = parsed.q as string;
        if (searchQuery) {
            setIsSearchOpened(true);
            setValue(searchQuery);
        }
    }, [location.search]);

    useEffect(() => {
        if (isSearchOpened) {
            setIsSearchOpened(false);
        }
    }, [location.pathname]);

    useEffect(() => {
        setIsMacsResultsOpened(false);
        setIsOtherResultsOpened(false);
    }, [isLoading]);

    const renderedBestResults = (
        <div className={`GlobalSearch__result ${isMacsResultsOpened || isOtherResultsOpened ? 'collapsed' : ''}`}>
            <Grid
                columnsNumber={getSlidesPerView(wWidth - 100, 260)}
                data={new ContentRibbon({
                    clientModel: {
                        id: 1,
                        title: <span className="GlobalSearch__best-results-title">Your best bets</span>,
                        items: bestResults
                    }
                })}
            />
        </div>
    );

    const renderedOtherResults = (
        <div className={`GlobalSearch__result ${isMacsResultsOpened ? 'collapsed' : ''}`}>
            <Grid
                columnsNumber={otherTilesCount}
                data={new ContentRibbon({
                    clientModel: {
                        id: 3,
                        title: (
                            <>
                                Other titles feature&nbsp;<span className="accent">{value}</span>
                                <Button
                                    className="ShowAllButton"
                                    onClick={() => setIsOtherResultsOpened(!isOtherResultsOpened)}
                                >
                                    {isOtherResultsOpened && <IconBack/>}
                                    Show {isOtherResultsOpened ? 'less' : 'all'}
                                    {!isOtherResultsOpened && <IconArrowRight/>}
                                </Button>
                            </>
                        ),
                        subTitle: `found in ${otherResultsCount} ${makeEnding('report', otherResultsCount)}`,
                        items: allOtherResults
                    }
                })}
            />
            {isRestOtherLoading && <Loader/>}
        </div>
    );

    const renderedMacsResults = (
        <div className={`GlobalSearch__result ${isOtherResultsOpened ? 'collapsed' : ''}`}>
            <Grid
                columnsNumber={macsTilesCount}
                data={new ContentRibbon({
                    clientModel: {
                        id: 2,
                        title: (
                            <>
                                MACs
                                <Button
                                    className="ShowAllButton"
                                    onClick={() => setIsMacsResultsOpened(!isMacsResultsOpened)}
                                >
                                    {isMacsResultsOpened && <IconBack/>}
                                    Show {isMacsResultsOpened ? 'less' : 'all'}
                                    {!isMacsResultsOpened && <IconArrowRight/>}
                                </Button>
                            </>
                        ),
                        subTitle: `found in ${macsResultsCount} ${makeEnding('report', macsResultsCount)}`,
                        items: allMacsResults
                    }
                })}
            />
            {isRestMacsLoading && <Loader/>}
        </div>
    );

    const totalResultsCount = docsResultsCount + macsResultsCount;
    const showMessage = !isLoading && (!bestResults.length && !otherResults.length && !macsResults.length);

    return (
        <Modal
            isOpened={isSearchOpened}
            close={() => {
                let search = qs.parse(location.search || '');
                delete search.q;
                history.replace({ search: getQueryString(search) });
                setIsSearchOpened(false);
            }}
            hasInner={false}
            closeOnEsc
            animationName="slide-top"
        >
            <div className={`GlobalSearch`}>
                <div className="GlobalSearch__header">
                    <input
                        className="GlobalSearch__input"
                        placeholder="Start typing here..."
                        autoFocus
                        value={value}
                        onChange={(event) => setValue(event.target.value)}
                    />
                    <div className="GlobalSearch__header-text">
                        <div style={{ opacity: 0.7 }}>
                            Search for Topical Deep Dives, Menu Adoption Cycles, Trendspotting and Quick Reports
                        </div>
                        {totalResultsCount > 0 && <div>found in {totalResultsCount} documents</div>}
                    </div>
                    <div className="GlobalSearch__toggle">
                        <Checkbox
                            color="white"
                            isChecked={inMyLibrary}
                            label="In my library"
                            onChange={() => setInMyLibrary(!inMyLibrary)}
                            isToggle
                        />
                    </div>
                </div>
                {value.trim() !== '' && (
                    <div className="GlobalSearch__results">
                        <Scrollbar>
                            <div className="GlobalSearch__results-inner">
                                {isLoading && <Loader/>}
                                {!isLoading && hasResults && (
                                    <>
                                        {bestResults.length > 0 && renderedBestResults}
                                        {otherResults.length > 0 && renderedOtherResults}
                                        {macsResults.length > 0 && renderedMacsResults}
                                    </>
                                )}
                                {showMessage && (
                                    <NoResults
                                        title={'Sorry, no results for this search'}
                                        subTitle={'Please try again, you can search words like pizza, burger, breakfast, etc'}
                                        isAbsolute
                                    />
                                )}
                            </div>
                        </Scrollbar>
                    </div>
                )}
            </div>
        </Modal>
    );
}
