import { iSubscriptionPackage__API } from "../apiSchema";
import { iSubscriptionPackage, SubscriptionPackage } from "../models/SubscriptionPackage";
import axios, { AxiosResponse } from "axios";
import { getApiLink } from "../api";
import { useData } from "../hooks/useData";

async function getSubscriptionPackages (): Promise<iSubscriptionPackage[]> {
    const response: AxiosResponse<iSubscriptionPackage__API[]> = await axios.get(getApiLink('SubscriptionPackage/Get'));

    return response.data.map(i => new SubscriptionPackage({ apiModel: i }));
}

const useSubscriptionPackages = () => {
    return useData([], getSubscriptionPackages);
}

export default useSubscriptionPackages;