import { useContext } from 'react';
import { IconShare } from '../../icons';
import Button from '../Button/Button';
import cn from 'classnames';
import { iPublication } from '../../core/models/Publication';
import { SharePopupContext } from '../../core/router';

interface iProps {
    className?: string;
    isDisabled?: boolean;
    data: iPublication;
}

const ShareButton: React.FC<iProps> = ({ className, isDisabled, data }) => {
    const { open } = useContext(SharePopupContext);

    const classNames = cn(
        'ShareButton',
        isDisabled ? 'ShareButton--disabled' : '',
        className,
    );

    const handleOpen = (e: any) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        open(data);
    };

    return (
        <>
            <Button
                className={classNames}
                onClick={handleOpen}
                tooltipText="Share"
            >
                <IconShare className="Icon32" />
            </Button>
        </>
    );
};

export default ShareButton;
