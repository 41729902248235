import React, { ReactNode, useContext, useEffect, useMemo, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import { toggleInArray, addToArray, removeFromArray } from "../../core/helpers";
import useComponentVisible from "../../core/hooks/useComponentVisible";
import { IconArrowDown, IconClose, IconSearch } from "../../icons";
import CustomScrollbarsVirtualList from "../CustomScrollbarsVirtualList/CustomScrollbarsVirtualList";
import Dropdown from "../Dropdown/Dropdown";
import "./Select.styles.scss";
import { iOption, filterDataBySearch, transformDataByGroup, getGroups, iGroup } from "./Select.helpers";
import Option from "./Option";
import LineSpinner from "./LineSpinner/LineSpinner";
import { GlobalContext } from "../../GlobalContext";
import Button from "../Button/Button";
import { ID } from "../../core/models";
import Scrollbar from '../Scrollbar/Scrollbar';

interface iProps {
    isClearable?: boolean;
    isDisabled?: boolean;
    isLoading?: boolean;
    isActive?: boolean;
    isSearchable?: boolean;
    isCheckboxHidden?: boolean;
    isMultiple?: boolean;
    isHiddenSelectedItems?: boolean;
    isSimpleInput?: boolean;
    isChipsView?: boolean;

    autoFocus?: boolean;

    valuePlaceholder?: string;
    searchPlaceholder?: string;
    noOptionsMessage?: string;
    title?: string;

    options: iOption[];
    value: ID[];

    dropdownHeight?: number;

    onChange: (ids: ID[]) => void;

    styles?: { [key: string]: React.CSSProperties };
    dropdownPosition?: string;
    inputButton?: boolean;
    buttonOptions?: string[];
    buttonContent?: ReactNode;
    closeOnSelect?: boolean;
}

const Select: React.FC<iProps> = (
    {
        isClearable,
        isDisabled,
        isSearchable,
        isLoading,
        isActive,
        isCheckboxHidden,
        isMultiple,
        title,
        value,
        searchPlaceholder,
        valuePlaceholder,
        options,
        dropdownHeight = 240,
        isChipsView= false,
        onChange,
        autoFocus,
        noOptionsMessage,
        styles,
        isHiddenSelectedItems,
        isSimpleInput = false,
        dropdownPosition,
        inputButton = false,
        buttonOptions = [],
        closeOnSelect = true,
        buttonContent,
    }
) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredData, setFilteredData] = useState<iOption[]>(options);
    const [groups, setGroups] = useState<iGroup[]>([]);
    const { getRem } = useContext(GlobalContext);
    useEffect(() => {
        const opts = isHiddenSelectedItems ? options.filter(({ id }) => !value.includes(id)) : options;
        const filteredBySearch = filterDataBySearch(opts, searchQuery);
        const sortByGroups = transformDataByGroup(groups, filteredBySearch, value, searchQuery);

        if (isMultiple && !groups.length && !isComponentVisible) {
            sortByGroups.sort((a: iOption) => (value.includes(a.id) ? -1 : 1));
        }

        setFilteredData(sortByGroups);
    }, [options, searchQuery, groups, value, isMultiple, isComponentVisible, isHiddenSelectedItems]);

    useEffect(() => {
        setGroups(getGroups(options));
    }, [options]);

    useEffect(() => {
        if (isComponentVisible) {
            setSearchQuery("");
        }
    }, [isComponentVisible]);

    const handleDropdownToggle = () => {
        if (!isDisabled && !isLoading) {
            setIsComponentVisible(!isComponentVisible);
        }
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    const handleSelect = (optionId: ID) => {
        if (isMultiple) {
            onChange(toggleInArray(value, optionId));
        } else {
            onChange([optionId]);
            if (closeOnSelect) {
                setIsComponentVisible(false);
            }
        }
    };

    const handleSelectGroup = (groupId: number, selectAll: boolean) => {
        const ids = options.filter((item) => item.groupId === groupId).map(({ id }) => id);
        if (selectAll && isMultiple) {
            onChange(addToArray(value, ids));
        } else {
            onChange(removeFromArray(value, ids));
        }
    };

    const handleClearSelected = (e: any) => {
        e.stopPropagation();
        onChange([]);
    };

    const handleToggleInputButton = (e: any) => {
        if ( value.length > 0 ) {
            handleClearSelected(e);
        } else {
            handleDropdownToggle();
        }
    }

    const handleToggleGroup = (groupId: number) => {
        const updatedGroups = groups.map((group) => {
            if (group.id === groupId) {
                return {
                    ...group,
                    isHidden: !group.isHidden
                };
            }

            return group;
        });

        setGroups(updatedGroups);
    };

    const selectClassList = new Set([
        "Select",
        isDisabled ? "Select--disabled" : "Select--active",
        isSimpleInput ? "Select--simple" : ""
    ]);

    const selectRenderValue = () => {
        const optionsCount = options.length;
        const checkedOptionsCount = value.length;

        if (isMultiple) {
            if (checkedOptionsCount > 0 && !isSimpleInput) {
                if (checkedOptionsCount === 1) {
                    return options.find((item) => item.id === value[0])?.title;
                }

                if (checkedOptionsCount === optionsCount) {
                    return "All selected";
                }

                if (checkedOptionsCount > 1 && checkedOptionsCount < optionsCount) {
                    return `${options.find((item) => item.id === value[0])?.title} + ${checkedOptionsCount - 1}`;
                }
            } else if (isSimpleInput && checkedOptionsCount > 0) {
                return `${valuePlaceholder} (${checkedOptionsCount})`;
            } else {
                return valuePlaceholder;
            }
        } else {
            return checkedOptionsCount ? options.find((item) => item.id === value[0])?.title : valuePlaceholder;
        }
    };

    const showClearableButton = isClearable && !!value.length;

    const inputButtonContent = useMemo(() => {
        if (buttonContent) return buttonContent;
        return value.length > 0 ? `${options.find((item) => item.id === value[0])?.title}` : valuePlaceholder;
    }, [value, options, valuePlaceholder, buttonContent]);

    return (
        <div className={Array.from(selectClassList).join(" ")} style={styles?.selectContainer}>
            {title && <div className="Select__title">{title}</div>}
            <div ref={ref} style={{ position: isSimpleInput ? "relative" : "static" }}>
                {!inputButton && (
                    <div
                        className={`Select__input ${isClearable ? "has-clear" : ""} ${value.length ? "has-value" : ""}`}
                        onClick={handleDropdownToggle}
                        style={styles?.input}
                    >
                        {isLoading && (
                            <div className="Select__loader">
                                <LineSpinner />
                            </div>
                        )}

                        {!isLoading && (
                            <div className="Select__input-label" style={styles?.inputLabel}>
                                <div
                                    className={`
                                    Select__input-value
                                    ${selectRenderValue() === valuePlaceholder ? "Select__input-value--placeholder" : ""}
                                `}
                                >
                                    {selectRenderValue()}
                                </div>
                            </div>
                        )}

                        <div className="Select__input-group" style={styles?.inputGroup}>
                            {showClearableButton && (
                                <div className="Select__input-clear" onClick={handleClearSelected}>
                                    <IconClose className="Select__input-clear-icon" />
                                </div>
                            )}
                            <div className="Select__input-arrow">
                                <IconArrowDown className={`Select__input-arrow-icon ${isComponentVisible ? "is-opened" : ""}`} />
                            </div>
                        </div>
                    </div>
                )}

                {inputButton && (
                    <Button
                        options={buttonOptions}
                        onClick={handleToggleInputButton}
                        className={`${value.length > 0 || isActive ? "is-active" : ""}`}
                    >
                        {inputButtonContent}
                    </Button>
                )}

                <Dropdown shown={isComponentVisible} position={dropdownPosition}>
                    <div className="Select__dropdown" style={styles?.selectDropdown}>
                        {isSearchable && (
                            <label className="Select__search">
                                <div className="Select__search-icon">
                                    <IconSearch />
                                </div>
                                <input
                                    className="Select__search-input"
                                    type="text"
                                    placeholder={searchPlaceholder}
                                    value={searchQuery}
                                    onChange={handleSearch}
                                    autoFocus={autoFocus}
                                />
                                {searchQuery.length > 0 && (
                                    <div
                                        className="Select__input-clear"
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                        }}
                                        onClick={() => setSearchQuery('')}
                                    >
                                        <IconClose className="Select__input-clear-icon" />
                                    </div>
                                )}
                            </label>
                        )}

                        {!!filteredData.length && (
                            <>
                                {isChipsView ? (
                                    <div className="Select__options" style={{ height: getRem(dropdownHeight) }}>
                                        <Scrollbar>
                                            <div className="Select__chips">
                                                {filteredData.map(option => (
                                                    <div
                                                        key={option.id}
                                                        className="Select__chip"
                                                        onClick={() => handleSelect(option.id)}
                                                    >
                                                        {option.title}
                                                    </div>
                                                ))}
                                            </div>
                                        </Scrollbar>
                                    </div>
                                ) : (
                                    <div className="Select__options" style={{ height: getRem(dropdownHeight) }}>
                                        <AutoSizer>
                                            {({ height, width }) => (
                                                <List
                                                    className="List"
                                                    outerElementType={CustomScrollbarsVirtualList}
                                                    height={height}
                                                    itemCount={filteredData.length}
                                                    itemSize={getRem(40)}
                                                    width={width}
                                                    itemData={{
                                                        options: filteredData,
                                                        value,
                                                        handleSelect,
                                                        isCheckboxHidden,
                                                        isMultiple,
                                                        handleSelectGroup,
                                                        handleToggleGroup
                                                    }}
                                                >
                                                    {Option}
                                                </List>
                                            )}
                                        </AutoSizer>
                                    </div>
                                )}
                            </>
                        )}
                        {!filteredData.length && <div className="Select__no-match-items">{noOptionsMessage}</div>}
                    </div>
                </Dropdown>
            </div>
        </div>
    );
};

Select.defaultProps = {
    isClearable: false,
    isSearchable: false,
    isCheckboxHidden: false,
    isDisabled: false,
    searchPlaceholder: "Search...",
    valuePlaceholder: "Nothing selected...",
    noOptionsMessage: "No matches found...",
    options: [],
    autoFocus: false
};

export default Select;
