import { FC } from 'react';
import "./ScrollbarThumb.styles.scss";

export const renderVerticalThumb: FC = (props: any) => {
    const thumbStyle = {
        borderRadius: '20px',
        zIndex: 200
    };
    return (
        <div {...props} style={{ ...props.style, ...thumbStyle }} className="ScrollbarThumb is-vertical" />
    );
};

export const renderHorizontalThumb: FC = (props: any) => {
    const thumbStyle = {
        borderRadius: '20px',
        zIndex: 200
    };
    return (
        <div {...props} style={{ ...props.style, ...thumbStyle }}  className="ScrollbarThumb is-horizontal" />
    );
};

export const renderVerticalTrack: FC = (props: any) => {
    const trackStyle = {
        borderRadius: '3px',
    };
    return (
        <div {...props} style={{ ...props.style, ...trackStyle }}  className="ScrollbarTrack is-vertical" />
    );
};

export const renderHorizontalTrack: FC = (props: any) => {
    const trackStyle = {
        borderRadius: '3px',
    };
    return (
        <div {...props} style={{ ...props.style, ...trackStyle }}  className="ScrollbarTrack is-horizontal" />
    );
};