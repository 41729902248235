import React, { useContext, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import './Header.styles.scss';
import RoutesEnum from '../../core/enums/RoutesEnum';
import Button, { ButtonTypeEnum } from '../Button/Button';
import { GlobalContext } from "../../GlobalContext";
import { IconBell, IconLogo, IconRL, IconSearch, IconShowAll } from '../../icons';
import { AnalyticsAction } from "../../core/models/AnalyticsAttributes";

interface iProps {
    children?: React.ReactNode;
    rightChildren?: React.ReactNode;
}

const Header: React.FC<iProps> = (
    {
        children,
        rightChildren,
    }
) => {
    const { toggleIsSearchOpened, currentUserInitials } = useContext(GlobalContext);
    const el = useRef<HTMLAnchorElement>(null);
    useEffect(() => {
        (el.current as HTMLAnchorElement).focus();
    }, []);

    return (
        <header className="Header">
            <a
                ref={el}
                href="/#"
                style={{
                    opacity: 0,
                    position: 'absolute',
                    pointerEvents: 'none'
                }}
            >
                scroll-with-keyboard link
            </a>
            <div className="Header__left">
                <NavLink
                    to={RoutesEnum.Home}
                    exact
                    className="Header__logo"
                    style={{
                        fontSize: 0,
                        lineHeight: 0,
                    }}
                >
                    <IconLogo/>
                </NavLink>
            </div>
            <div className="Header__center">{children}</div>
            <div className="Header__right">
                {rightChildren}
                <Button
                    className="Header__button"
                    onClick={toggleIsSearchOpened}
                    analyticsData={{
                        action: AnalyticsAction.GlobalSearchOpen,
                    }}
                >
                    <IconSearch className="Icon32"/>
                </Button>
                <Button
                    className="Header__button"
                    type={ButtonTypeEnum.NavLink}
                    link={RoutesEnum.ReadingList}
                >
                    <IconRL className="Icon32"/>
                    &nbsp;My list
                </Button>
                <Button
                    className="Header__button"
                    type={ButtonTypeEnum.NavLink}
                    link={RoutesEnum.Browse}
                >
                    <IconShowAll className="Icon32"/>
                    &nbsp;Browse
                </Button>
                <Button
                    className="Header__button"
                    type={ButtonTypeEnum.NavLink}
                    link={RoutesEnum.ContentWatch}
                >
                    <IconBell className="Icon32"/>
                    &nbsp;Content Watch
                </Button>
                <div className="Header__button Header__button--gray Header__button--round">
                    {currentUserInitials}
                </div>
            </div>
        </header>
    );
};

export default Header;
