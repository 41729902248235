import { DocumentType } from '../apiSchema';

enum PublicationTypesEnum {
    Publication = DocumentType.NUMBER_0,
    Chapter = DocumentType.NUMBER_1,
    Mac = DocumentType.NUMBER_2,
}

export default PublicationTypesEnum;

export function getPublicationType(pt: DocumentType): PublicationTypesEnum {
    switch (pt) {
        case DocumentType.NUMBER_1: return PublicationTypesEnum.Chapter;
        case DocumentType.NUMBER_2: return PublicationTypesEnum.Mac;
        default: return PublicationTypesEnum.Publication;
    }
}