import { iTag__API, iTagShort__API } from '../apiSchema';

export interface iTag {
    id: number;
    title: string;
}


type ApiModel = iTagShort__API;

export class Tag implements iTag {
    id = 0;
    title = '';

    constructor(data?: {
        apiModel?: ApiModel;
    }) {
        if (data && data.apiModel) {
            this.mapFromApi(data.apiModel);
        }
    }

    private setData (model: iTag) {
        ({
            id: this.id,
            title: this.title,
        } = model);
    }

    private mapFromApi (model: ApiModel) {
        this.setData({
            id: model.id,
            title: model.value,
        });
    }
}