import React, { ReactChild } from 'react';
import { iPublication } from '../../../core/models/Publication';
import dayjs from 'dayjs';
import './PublicationSubTitle.styles.scss';

interface iProps {
    data: iPublication;
    uppercase?: boolean;
}

const PublicationSubTitle: React.FC<iProps> = (
    {
        data,
        uppercase,
    }
) => {
    const { createdDate, numberOfPages, minutesToRead } = data;
    const spans: ReactChild[] = [];

    if (createdDate) {
        spans.push((
            <>{dayjs(createdDate).format('MMMM \'YY')}</>
        ));
    }
    if (numberOfPages) {
        spans.push((
            <>{numberOfPages} pages</>
        ));
    }
    if (minutesToRead) {
        spans.push((
            <>{minutesToRead} min read</>
        ));
    }
    return (
        <div className="PublicationSubTitle">
            {spans.map((span, index) => (
                <div key={index} style={{ textTransform: uppercase ? 'uppercase' : 'none' }}>
                    {span}
                    {index < spans.length - 1 && <>&nbsp;·&nbsp;</>}
                </div>
            ))}
        </div>
    );
};

export default PublicationSubTitle;
