import React from "react";
import { iPublication } from "../../../core/models/Publication";
import TileImage from "../../TilleImage/TileImage";
import "./Preview.styles.scss";

interface iProps {
    sharedItem: iPublication;
}

const SharePreview: React.FC<iProps> = ({ sharedItem }) => {
    return (
        <div className="SharePreview">
            <div className="SharePreview__image">
                <TileImage src={sharedItem.image} />
            </div>

            <div className="SharePreview__text">
                <div className="SharePreview__title">{sharedItem.title}</div>
            </div>
        </div>
    );
};

export default SharePreview;
