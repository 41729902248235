import React, { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import "./Dropdown.styles.scss";

interface iProps {
    shown: boolean;
    onExited?: () => void;
    onExiting?: () => void;
    onEntering?: () => void;
    onEntered?: () => void;
    position?: string;
}

const Dropdown: React.FC<iProps> = ({ children, shown, onExited, onExiting, onEntering, onEntered, position }) => {
    const ref = useRef<HTMLDivElement>(null);

    return (
        <CSSTransition
            in={shown}
            appear
            timeout={300}
            onEntering={onEntering}
            onEntered={onEntered}
            onExited={onExited}
            onExiting={onExiting}
            classNames={`Dropdown Dropdown--${position} Dropdown`}
            unmountOnExit
            nodeRef={ref}
        >
            <div ref={ref}>{children}</div>
        </CSSTransition>
    );
};

export default Dropdown;
