import { iSnapUser__API } from "../apiSchema";

export interface iUser {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    companyName: string;
}

interface iUserData {
    apiModel?: iSnapUser__API;
}

export class User implements iUser {
    id = "";
    email = "";
    firstName = "";
    lastName = "";
    companyName = "";

    constructor(data?: iUserData) {
        if (data && data.apiModel) {
            this.mapFromApi(data.apiModel);
        }
    }

    private mapFromApi(data: iSnapUser__API) {
        this.id = data.id || "";
        this.email = data.email || "";
        this.firstName = data.firstName  || "";
        this.lastName = data.lastName || "";
        this.companyName = data.companyName || "no info";
    }
}