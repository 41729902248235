import { useHistory } from "react-router";
import RoutesEnum from "../../core/enums/RoutesEnum";
import { IconBack } from "../../icons";
import Button from "../Button/Button";

interface iProps {
    text?: string;
}
const BackTo: React.FC<iProps> = ({text = "Home"}) => {
    let history = useHistory();

    return (
        <Button onClick={() => history.push(RoutesEnum.Home)}>
            <IconBack className="Icon32" />
            {text}
        </Button>
    );
};


export default BackTo;