const nav: any = window.navigator;
const ua = window.navigator.userAgent;
const isSafariIOSDesktopMode = nav.platform !== undefined && nav.platform === "MacIntel" && nav.maxTouchPoints !== undefined && nav.maxTouchPoints > 1;

const isMobileDevice = isSafariIOSDesktopMode || /Mobi|Tablet|Android|iPad|iPhone/.test(ua);
const isModernBrowser = (window.Promise !== undefined);
const isFirefoxWithPDFJS = (!isMobileDevice && /irefox/.test(ua) && ua.split("rv:").length > 1) ? (parseInt(ua.split("rv:")[1].split(".")[0], 10) > 18) : false;
const supportsPdfMimeType = (nav.mimeTypes["application/pdf"] !== undefined);
const isIE = ("ActiveXObject" in window);

const SUPPORT_NATIVE_PDF = ( !isMobileDevice && !isIE ) && ( isModernBrowser || isFirefoxWithPDFJS ||  supportsPdfMimeType );
// const SUPPORT_NATIVE_PDF = false;
export { SUPPORT_NATIVE_PDF, isIE };
