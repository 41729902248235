import { IconClose, IconError, IconSuccess } from "../../icons";
import { NotificationStatus } from "../Notifications/NotificationsItem";
import "./Toast.styles.scss";

interface iProps {
    status: NotificationStatus;
    icon: React.ReactNode;
}

const Toast: React.FC<iProps> = ({ status, icon = null, children }) => {
    return (
        <div className="Toast">
            <div className={`Toast__icon ${status === "default" && icon ? "has-custom-icon" : ""}`}>
                {status === "success" && <IconSuccess />}
                {status === "error" && <IconError />}
                {status === "default" && icon}
            </div>
            <div className="Toast__message">{children}</div>
            <div className="Toast__close">
                <IconClose className="Icon24" />
            </div>
        </div>
    );
};

export default Toast;
