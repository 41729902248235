import { iCurrentUser } from "./models/CurrentUser";

export function deepCopy<T>(data: T): T {
    return JSON.parse(JSON.stringify(data));
}

export function getRandomInRange(min: number = 0, max: number) {
    return Math.round(Math.random() * (max - min)) + min;
}

export function formatThousand(n: number): string {
    if (+n < 0) return `-${formatThousand(-n)}`;
    let numberString = `${n}`;
    let result = '';
    while (numberString.length > 3) {
        result = `,${numberString.substr(numberString.length - 3)}${result}`;
        numberString = numberString.substr(0, numberString.length - 3);
    }

    result = `${numberString}${result}`;
    return result;
}

export function splitArrayIntoChunks<T>(array: T[], chunkSize: number): T[][] {
    return Array(Math.ceil(array.length / chunkSize))
        .fill(null)
        .map((_, index) => index * chunkSize)
        .map((begin) => array.slice(begin, begin + chunkSize));
}
export function toggleInArray<T>(array: T[], ...values: T[]) {
    let result = [...array];
    if (values.length === 0) return [];
    values.forEach((value) => {
        if (array.includes(value)) {
            result = result.filter((item) => item !== value);
        } else {
            result = [...result, value];
        }
    });
    return result;
}

export function addToArray<T>(array: T[], values: T[]) {
    let result = [...array];
    if (values.length === 0) return array;
    values.forEach((value) => {
        if (!array.includes(value)) {
            result = [...result, value];
        }
    });
    return result;
}

export function removeFromArray<T>(array: T[], values: T[]) {
    let result = [...array];
    if (values.length === 0) return array;
    values.forEach((value) => {
        if (array.includes(value)) {
            result = result.filter((item) => item !== value);
        }
    });
    return result;
}

export function isTargetInput(event: KeyboardEvent) {
    // @ts-ignore
    return event.target?.nodeName === 'INPUT';
}

export function isLetterTyped(event: KeyboardEvent) {
    const isLetterKey = /^[A-z0-9]$/g.test(event.key);
    const hasModifiers = event.altKey || event.ctrlKey || event.metaKey || event.shiftKey;

    return isLetterKey && !hasModifiers;
}

export function isNumberModulo (N: number, n: number, modulo: number): boolean {
    return n - Math.floor(n/N)*N === modulo;
}

export const MQ_VIEWPORT_SIZE = 1920;
export function getResponsiveScale(num: number, viewportWidth: number) {
    const scale = viewportWidth > MQ_VIEWPORT_SIZE ? Math.round((1000 * (viewportWidth / MQ_VIEWPORT_SIZE))) / 1000 : 1;
    return num * scale;
}

export function getCurrentUserInitials(data: iCurrentUser) {
    const { firstName, lastName, email } = data;
    const firstChar = `${firstName ? firstName.charAt(0).toUpperCase() : ""}`;
    const lastChar = `${lastName ? lastName.charAt(0).toUpperCase(): ""}`;
    const userInitials = !firstName && !lastName ? email.charAt(0).toUpperCase() : `${firstChar}${lastChar}`;
    return userInitials;
}

export function prepareForMatching(str: string) {
    return str.trim().replace(/\s+/gm, ' ').toLowerCase();
}

export function isEqualStrings(s1: string, s2: string) {
    return prepareForMatching(s1) === prepareForMatching(s2);
}

export function getEnding(title: string, count: number) {
    const clearedTitle = prepareForMatching(title);
    switch(clearedTitle) {
        default: {
            return `${title}${count === 1 ? '' : 's'}`;
        }
    }
}

export function randomizeArray<T>(arr: T[]): T[] {
    const arrCopy = [...arr];
    arrCopy.sort(() => Math.random() - 0.5);
    return arrCopy;
}