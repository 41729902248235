import { iRibbon__API } from '../apiSchema';

export interface iShortRibbon {
    id: number;
    title: string;
}

interface iData {
    apiModel?: iRibbon__API;
}

export class ShortRibbon implements iShortRibbon {
    id = 0;
    title = '';

    constructor(data?: iData) {
        if (data && data.apiModel) {
            this.mapFromApi(data.apiModel);
        }
    }

    private mapFromApi (data: iRibbon__API) {
        this.id = data.id;
        this.title = data.name || `Content Ribbon #${data.id}`;
    }
}