import { iFile } from '../models/File';

export enum FileTypesEnum {
    NotSet = 0,
    Ppt = 1,
    Pdf = 2,
    PdfSample = 3,
    Xml1 = 4,
    Xml2 = 5,
}

export enum FileTypesStringEnum {
    NotSet = '',
    Ppt = 'PPT',
    Pdf = 'PDF',
    PdfSample = 'PDF Sample',
    Xml1 = 'Consumer Data Table',
    Xml2 = 'Operator Data Table',
}

const downloadableItems = [
    FileTypesEnum.Pdf,
    FileTypesEnum.Ppt,
    FileTypesEnum.Xml1,
    FileTypesEnum.Xml2,
];

export function getFileType(fileType: string) {
    switch(fileType) {
        case 'Pdf': return FileTypesEnum.Pdf;
        case 'SamplePdf': return FileTypesEnum.PdfSample;
        case 'Ppt': return FileTypesEnum.Ppt;
        case 'ConsumerIdeaTool': return FileTypesEnum.Xml1;
        case 'OperatorIdeaTool': return FileTypesEnum.Xml2;
        default: return FileTypesEnum.NotSet;
    }
}

export function getFileTypeTitle(fileType: FileTypesEnum): FileTypesStringEnum {
    switch(fileType) {
        case FileTypesEnum.Pdf: return FileTypesStringEnum.Pdf;
        case FileTypesEnum.PdfSample: return FileTypesStringEnum.PdfSample;
        case FileTypesEnum.Ppt: return FileTypesStringEnum.Ppt;
        case FileTypesEnum.Xml1: return FileTypesStringEnum.Xml1;
        case FileTypesEnum.Xml2: return FileTypesStringEnum.Xml2;
        default: return FileTypesStringEnum.NotSet;
    }
}

export function isFileDownloadable (file: iFile): boolean {
    return downloadableItems.includes(file.type);
}

export default FileTypesEnum;