import { FC, CSSProperties } from 'react';
import { Link, NavLink } from 'react-router-dom';
import cn from 'classnames';

import './Button.styles.scss';
import Tooltip from '../Tooltip/Tooltip';
import { AnalyticsAttributesData, getAnalyticsAttributes } from "../../core/models/AnalyticsAttributes";

export enum ButtonTypeEnum {
    Button = 1,
    Link,
    NavLink,
    Anchor,
    DownloadButton,
}

interface iProps {
    type?: ButtonTypeEnum;
    onClick?: (e: any) => void;
    link?: string;
    options?: string[];
    className?: string;
    isDisabled?: boolean;
    style?: CSSProperties,
    tooltipText?: string;
    analyticsData?: AnalyticsAttributesData;
}

const Button: FC<iProps> = ({
    type = ButtonTypeEnum.Button,
    link,
    onClick,
    options = [],
    className,
    children,
    isDisabled,
    style,
    tooltipText,
    analyticsData ,
}) => {

    const props = {
        style,
        className: cn(
            'Button',
            options.filter(o => !!o).map((o) => `Button--${o}`),
            className,
            isDisabled ? "Button--disabled" : ""
        ),
        ...(analyticsData ? getAnalyticsAttributes(analyticsData) : {}),
    };

    const tooltip = tooltipText ? (
        <Tooltip isTextSmaller={tooltipText ? tooltipText.length > 25: false }>
            {tooltipText}
        </Tooltip>
    ) : null;

    const content = <>
        {children}
        {tooltip}
    </>;



    switch (type) {
        case ButtonTypeEnum.Button: {
            return (
                <button
                    onClick={onClick}
                    disabled={isDisabled}
                    {...props}
                >
                    {content}
                </button>
            );
        }
        case ButtonTypeEnum.Link: {
            return (
                <Link
                    to={link || '#'}
                    {...props}
                >
                    {content}
                </Link>
            );
        }
        case ButtonTypeEnum.NavLink: {
            return (
                <NavLink
                    to={link || '#'}
                    {...props}
                >
                    {content}
                </NavLink>
            );
        }
        case ButtonTypeEnum.Anchor: {
            return (
                <a
                    target="_blank"
                    href={link || '#'}
                    {...props}
                >
                    {content}
                </a>
            );
        }
        case ButtonTypeEnum.DownloadButton: {
            return (
                <a
                    download
                    href={link || '#'}
                    {...props}
                >
                    {content}
                </a>
            );
        }
        default:
            return null;
    }
};

export default Button;
