import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import './Slider.styles.scss';
import { IconArrowLeft, IconArrowRight } from '../../icons';
import { randomizeArray } from '../../core/helpers';

interface iOptions {
    intervalMs: number;
    randomizeSlides: boolean;
}

const DEFAULT_OPTIONS: iOptions = {
    intervalMs: 10000,
    randomizeSlides: true,
};

interface iProps {
    children: ReactNode[];
    options?: Partial<iOptions>;
}

const Slider: FC<iProps> = (
    {
        children,
        options,
    },
) => {
    const [slides, setSlides] = useState<ReactNode[]>([]);
    const [prevSlideIndex, setPrevSlideIndex] = useState(slides.length - 1);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const finalOptions = { ...DEFAULT_OPTIONS, ...options };

    const moveSlider = (move: -1 | 1) => {
        let possibleNexSlideIndex = currentSlideIndex + move;

        if (possibleNexSlideIndex < 0) possibleNexSlideIndex = slides.length - 1;
        else if (possibleNexSlideIndex >= slides.length) possibleNexSlideIndex = 0;

        setPrevSlideIndex(currentSlideIndex);
        setCurrentSlideIndex(possibleNexSlideIndex);
    };

    const handleMoveForward = () => {
        moveSlider(1);
    };

    const handleMoveBackward = () => {
        moveSlider(-1);
    };

    useEffect(() => {
        setSlides(randomizeArray(children));
    }, []);

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout>;

        timer = setTimeout(handleMoveForward, finalOptions.intervalMs);

        return () => clearTimeout(timer);
    }, [handleMoveForward]);

    return (
        <div className="Slider">
            <div key={prevSlideIndex} className="Slider__slide Slider__slide--prev">
                {slides[prevSlideIndex]}
            </div>
            <div key={currentSlideIndex} className="Slider__slide Slider__slide--current">
                {slides[currentSlideIndex]}
            </div>
            <div
                onClick={handleMoveBackward}
                className="Slider__nav Slider__nav--prev"
            >
                <IconArrowLeft className="Icon32" />
            </div>
            <div
                onClick={handleMoveForward}
                className="Slider__nav Slider__nav--next"
            >
                <IconArrowRight className="Icon32" />
            </div>
        </div>
    );
};

export default Slider;