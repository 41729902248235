import React from 'react';
import RoutesEnum, { RoutesAriaEnum } from './enums/RoutesEnum';
import Library from '../pages/Library/Library';
import DocumentDetails from '../pages/DocumentDetails/DocumentDetails';
import ReadingList from '../pages/ReadingList/ReadingList';
import Browse from '../pages/Browse/Browse';
import Testing from '../pages/Testing/Testing';
import SnapDocumentDetails from '../pages/SnapDocumentDetails/SnapDocumentDetails';
import ContentWatch from '../pages/ContentWatch/ContentWatch';

export interface iRoute {
    path: RoutesEnum;
    title: string;
    exact?: boolean;
    component: React.ComponentType<any>;
    aria: RoutesAriaEnum;
}

const Routes: iRoute[] = [
    {
        path: RoutesEnum.Home,
        title: '',
        exact: true,
        component: Library,
        aria: RoutesAriaEnum.Library
    },
    {
        path: RoutesEnum.Browse,
        title: 'Browse',
        component: Browse,
        aria: RoutesAriaEnum.Browse
    },
    {
        path: RoutesEnum.DocumentDetails,
        title: '',
        component: DocumentDetails,
        aria: RoutesAriaEnum.Document
    },
    {
        path: RoutesEnum.SnapDocumentDetails,
        title: '',
        component: SnapDocumentDetails,
        aria: RoutesAriaEnum.Document
    },
    {
        path: RoutesEnum.ReadingList,
        title: 'My reading list',
        component: ReadingList,
        aria: RoutesAriaEnum.ReadingList
    },
    {
        path: RoutesEnum.SharedWithMeList,
        title: 'Shared with me',
        component: ReadingList,
        aria: RoutesAriaEnum.ReadingList
    },
    {
        path: RoutesEnum.Test,
        title: 'Test page',
        component: Testing,
        aria: RoutesAriaEnum.Test
    },
    {
        path: RoutesEnum.ContentWatch,
        title: 'Content Watch',
        component: ContentWatch,
        aria: RoutesAriaEnum.ContentWatch
    },
];

export function getRoutesByAria(aria: RoutesAriaEnum) {
    return Routes.filter((route) => route.aria === aria);
}

export default Routes;
