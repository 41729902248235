import { iPublication, Publication } from '../models/Publication';
import axios, { AxiosResponse } from 'axios';
import { iSearch__API } from '../apiSchema';
import { getApiLink } from '../api';
import { useDebouncedData } from '../hooks/useDebouncedData';
import { MutableRefObject, Ref, useRef } from 'react';

interface iSearchResultsData {
    docsResults: iPublication[];
    docsResultsCount: number;
    macsResults: iPublication[];
    macsResultsCount: number;
}

const defaultData: iSearchResultsData = {
    docsResults: [],
    docsResultsCount: 0,
    macsResults: [],
    macsResultsCount: 0,
};

interface iGetSearchResults {
    takeMacs?: number;
    takeDocs?: number;
    skipMacs?: number;
    skipDocs?: number;
}

async function getSearchResults(
    q: string,
    inMyLibrary: boolean,
    reqData: iGetSearchResults,
    controller: MutableRefObject<AbortController>
): Promise<iSearchResultsData> {
    controller.current.abort();
    controller.current = new AbortController();
    const {
        takeMacs = 0,
        takeDocs = 0,
        skipMacs = 0,
        skipDocs = 0,
    } = reqData;

    if (q.trim() === '') return defaultData;
    if (!takeMacs && !takeDocs) return defaultData;
    const promise = axios.post(
        getApiLink('PublicationClient/Search'),
        {
            q,
            inMyLibrary,
            skipMacs,
            takeMacs,
            skipDocs,
            takeDocs,
        },
        {
            signal: controller.current.signal,
        },
    );

    const { data }: AxiosResponse<iSearch__API> = await promise;

    const {
        macsResults,
        macsResultsCount = 0,
        documentsResults,
        otherResultsCount = 0
    } = data;

    return {
        docsResultsCount: otherResultsCount,
        macsResultsCount,
        docsResults: (documentsResults || []).map(i => new Publication({ apiModel: i })),
        macsResults: (macsResults || []).map(i => new Publication({ apiModel: i })),
    };
}

export default function useSearchResults(
    q: string,
    inMyLibrary: boolean,
    reqData: iGetSearchResults,
) {
    const controller = useRef<AbortController>(new AbortController());
    return useDebouncedData(300, defaultData, getSearchResults, q, inMyLibrary, reqData, controller);
}