import { useContext, useMemo } from "react";
import { Link, matchPath, NavLink, useLocation } from "react-router-dom";
import {
    AppId,
    CountriesSelect,
    cssX, FCX,
    Header,
    HeaderLogo,
    Navigation,
    ProductLogo, SearchBox, Toggle
} from "@datassential/platform-ui-lib";
import { GlobalContext } from "../../GlobalContext";
import RoutesEnum from "../../core/enums/RoutesEnum";
import './PlatformHeader.scss';

const PlatformHeader: FCX = () => {
    const location = useLocation();
    const {
        setIsSearchOpened,
        inMyLibrary,
        setInMyLibrary,
    } = useContext(GlobalContext);

    const navigationItems = useMemo(
        () => {
            const result = [
                {
                    id: 'My List',
                    content: (
                        <NavLink
                            to={RoutesEnum.ReadingList}
                            className={"PlatformHeader__nav-item"}
                        >
                            My List
                        </NavLink>
                    ),
                },
                {
                    id: 'Browse',
                    content: (
                        <NavLink
                            to={RoutesEnum.Browse}
                            className={"PlatformHeader__nav-item"}
                        >
                            Browse
                        </NavLink>
                    ),
                },
                {
                    id: 'Content Watch',
                    content: (
                        <NavLink
                            to={RoutesEnum.ContentWatch}
                            className={"PlatformHeader__nav-item"}
                        >
                            Content Watch
                        </NavLink>
                    ),
                },
                {
                    id: 'search',
                    content: (
                        <SearchBox
                            testId={"selectDEMOHeaderReportProSearch"}
                            tooltipId='no-data'
                            onClick={() => setIsSearchOpened(true)}
                            style={{ cursor: "pointer" }}
                            inputProps={{
                                value: '',
                                setValue: () => {},
                                placeholder: 'Search in Report Pro',
                                testId: 'inputDEMOHeaderReportProSearch',
                                style: {
                                    pointerEvents: "none",
                                }
                            }}
                            dropdownProps={{
                                id: 'no-data',
                                isOpened: false,
                            }}
                        />
                    ),
                },
            ];

            if (matchPath(RoutesEnum.Home, location.pathname)) {
                result.unshift({
                    id: 'checkbox',
                    content: (
                        <Toggle
                            isChecked={inMyLibrary}
                            onChange={() => setInMyLibrary(!inMyLibrary)}
                            label="in My Library"
                        />
                    ),
                });
            }

            return result;
        },
        [inMyLibrary, setInMyLibrary, setIsSearchOpened, location.pathname]
    );

    return (
        <Header
            className="PlatformHeader"
            leftContent={<>
                <Link to="/" data-testid={"buttonDEMOHeaderLogo"}>
                    <HeaderLogo>
                        <ProductLogo appId={AppId.ReportPro}/>
                    </HeaderLogo>
                </Link>
                <CountriesSelect
                    style={{ marginLeft: 10 }}
                    options={[
                        { id: 'usa', shortName: 'USA', name: 'United States' },
                    ]}
                    isDisabled
                    value={'usa'}
                    setValue={() => {}}
                    testId={"selectDEMOHeaderCountry2"}
                />
            </>}
            rightContent={<Navigation data={navigationItems}/>}
        />
    );
}

export default PlatformHeader;