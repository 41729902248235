import { iSubscriptionPackage__API } from "../apiSchema";

export interface iSubscriptionPackage {
    id: number;
    title: string;
}

type ApiModel = iSubscriptionPackage__API;

export class SubscriptionPackage implements iSubscriptionPackage {
    id = SubscriptionPackage.defaultData.id;
    title = SubscriptionPackage.defaultData.title;

    static defaultData: iSubscriptionPackage = {
        id: 0,
        title: "",
    };

    constructor(data?: {
        apiModel?: ApiModel,
    }) {
        if (data) {
            if (data.apiModel) {
                this.mapFromApi(data.apiModel);
            }
        }
    }

    private setData (model: iSubscriptionPackage) {
        ({
            id: this.id,
            title: this.title,
        } = model);
    }

    private mapFromApi (model: ApiModel) {
        this.setData({
            id: model.id,
            title: model.name,
        });
    }
}