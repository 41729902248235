import { ReactChildren, ReactElement, ReactNode, useContext } from 'react';
import { GlobalContext } from "../../GlobalContext";
import Button from "../Button/Button";
import Modal from "../Modal/Modal";
import "./ConfirmPopup.styles.scss";
import { AnalyticsAttributesData } from "../../core/models/AnalyticsAttributes";

interface iProps {
    shown: boolean;
    title: ReactNode;
    message?: ReactNode;
    submitButtonLabel?: string;
    closeModal: () => void;
    onConfirm: () => void;
    confirmButtonAnalyticsData?: AnalyticsAttributesData;
}


const ConfirmPopup: React.FC<iProps> = (
    {
        title,
        message,
        shown,
        closeModal,
        onConfirm,
        submitButtonLabel = "Ok",
        confirmButtonAnalyticsData,
    }
) => {
    const { getRem } = useContext(GlobalContext);

    const handleConfirm = () => {
        onConfirm();
        closeModal();
    };

    const modalStyles = {
        container: {
            width: "100%",
            maxWidth: getRem(500),
            maxHeight: "92%",
            padding: 0
        },
        close: {
            display: "none"
        }
    };
    return (
        <Modal isOpened={shown} close={closeModal} closeOnEsc style={modalStyles}>
            <div className="ConfirmPopup">
                <div className="ConfirmPopup__title">{title}</div>
                <div className="ConfirmPopup__message">{message}</div>
                <div className="ConfirmPopup__actions">
                    <Button className="ConfirmPopup__actions-button" options={["secondary"]} onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button
                        className="ConfirmPopup__actions-button"
                        onClick={handleConfirm}
                        options={["primary"]}
                        analyticsData={confirmButtonAnalyticsData}
                    >
                        {submitButtonLabel}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmPopup;
