import { Publication, iPublication } from "../models/Publication";
import axios, { AxiosResponse } from "axios";
import { iPublicationClient__API } from "../apiSchema";
import { getApiLink } from "../api";
import { iSelectedFilters } from '../../pages/Browse/Browse';
import { useEffect, useState } from 'react';

export async function getDocumentsWithFilters(filtersData: iSelectedFilters, skip: number, take: number): Promise<iPublication[]> {
    const response: AxiosResponse<iPublicationClient__API[]> = await axios.post(
        getApiLink("PublicationClient/GetAllWithFilter"),
        { ...filtersData, skip, take }
    );

    return response.data.map((p) => new Publication({ apiModel: p }));
}

export function useDocumentsWithFilters(filtersData: iSelectedFilters, take: number) {
    const [storedData, setStoredData] = useState<iPublication[]>([]);
    const [hasNextItems, setHasNextItems] = useState(true);
    const [requestData, setRequestData] = useState({ filtersData, skip: 0 })

    const [isLoading, setIsLoading] = useState(true);

    const incSkip = () => {
        setRequestData({ ...requestData, skip: requestData.skip + take});
    };

    useEffect(() => {
        let isActual = true;
        setIsLoading(true);
        getDocumentsWithFilters(requestData.filtersData, requestData.skip, take)
            .then((newData) => {
                if (isActual) {
                    setStoredData((storedData) => {
                        return [...storedData, ...newData];
                    });
                    setHasNextItems(newData.length === take);
                    setIsLoading(false);
                }
            });

        return () => {
            isActual = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [take, JSON.stringify(requestData)]);

    useEffect(() => {
        setIsLoading(true);
        setStoredData([]);
        setHasNextItems(true);
        setRequestData({ ...requestData, filtersData, skip: 0 });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [take, JSON.stringify(filtersData)])


    return {
        isLoading,
        data: storedData,
        hasNextItems,
        incSkip,
    };
}
