import { FC } from 'react';
import cn from 'classnames';
import './Tooltip.styles.scss';

const Tooltip: FC<{
    isTextSmaller?: boolean;
    options?: string[];
    className?: string;
}> = (
    {
        children,
        isTextSmaller= false,
        options = [],
        className,
    }
) => {
    const classNames = cn(
        'Tooltip',
        className,
        isTextSmaller && 'Tooltip--smaller',
        ...options.map(o => `is-${o}`),
    );

    return (
        <div className={classNames}>{children}</div>
    );
};

export default Tooltip;