import axios, { AxiosResponse } from 'axios';
import { iRibbon__API } from '../apiSchema';
import { getApiLink } from '../api';
import { useData } from '../hooks/useData';
import { ContentRibbon, iContentRibbon } from '../models/ContentRibbon';

async function getContentRibbons(inMyLibrary: boolean): Promise<iContentRibbon[]> {
    const response: AxiosResponse<iRibbon__API[]> = await axios.get(
        getApiLink('Ribbon/GetAll', { inMyLibrary }),
    );
    return response.data.map(p => new ContentRibbon({ apiModel: p }));
}

export default function useContentRibbons(inMyLibrary: boolean) {
    return useData([], getContentRibbons, inMyLibrary);
}