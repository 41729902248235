import { FC, useEffect, useRef, useState } from 'react';
import Page from '../../components/Page/Page';

const Testing: FC = () => {

    return (
        <Page title="Testing">
        </Page>
    );
};

export default Testing;