import React from 'react';
import { Publication, iPublication } from './Publication';
import { iRibbon__API } from '../apiSchema';
import { isEqualStrings } from '../helpers';
import { MAC_CATEGORY_TITLE } from '../api';

export interface iContentRibbon {
    id: number;
    title: React.ReactNode | string;
    subTitle?: React.ReactNode | string;
    items: iPublication[];
    isMacRibbon: boolean;
}

interface iData {
    clientModel?: Partial<iContentRibbon>,
    apiModel?: iRibbon__API;
    shouldShowAvailableFirst?: boolean;
}

export class ContentRibbon implements iContentRibbon {
    id = 0;
    title = '';
    subTitle = '';
    items: iPublication[] = [];
    isMacRibbon = false;

    constructor(data?: iData) {
        if (data) {
            if (data.apiModel) {
                this.mapFromApi(data.apiModel);
            }
            else if (data.clientModel) {
                this.mapFromClient(data.clientModel);
            }
            // if (data.shouldShowAvailableFirst) {
            //     this.showAvailableFirst();
            // }
        }
    }

    private mapFromApi (data: iRibbon__API) {
        this.id = data.id;
        this.title = data.name || `Content Ribbon #${data.id}`;
        this.isMacRibbon = isEqualStrings(data.name, MAC_CATEGORY_TITLE);
        this.items = data.publications
            .map(publication => new Publication({ apiModel: publication }))
    }

    private mapFromClient (data: Partial<iContentRibbon>) {
        this.id = data.id || 0;
        this.title = (data.title as string) || '';
        this.subTitle = (data.subTitle as string) || '';
        this.items = (data.items || [])
        this.isMacRibbon = data.isMacRibbon || false;
    }

    private showAvailableFirst () {
        this.items.sort((a, b) => !a.hasAccess && b.hasAccess ? 1 : -1);
    }
}