import "./DownloadDetailsButton.styles.scss";

interface iProps {
    text?: string;
}

const DownloadDetailsButton: React.FC<iProps> = ({ text = "here" }) => {
    const SHOW_FILENAME = "Datassential Report Pro Brochure (website).pdf";
    const URL = "https://gp-pub-shared.s3-us-west-2.amazonaws.com/a5b3bfa9-1ec5-4a7f-86b1-d087cb323d87.pdf";
    return (
        <a className="DownloadDetailsButton" href={URL} download={SHOW_FILENAME}>{text}</a>
    );
};

export default DownloadDetailsButton;
