import React from 'react';

import './SectionTitle.styles.scss';

interface iProps {
    text: React.ReactNode;
    subText?: React.ReactNode;
}

const SectionTitle: React.FC<iProps> = (
    {
        text,
        subText
    }
) => {
    if (!text && !subText) return null;

    return (
        <h3 className="SectionTitle">
            <span className="SectionTitle__text">{text}</span>
            {subText && (
                <span className="SectionTitle__sub-text">{subText}</span>
            )}
        </h3>
    );
};

export default SectionTitle;
