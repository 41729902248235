import axios, { AxiosResponse } from 'axios';
import { iPublicationClient__API } from '../apiSchema';
import { getApiLink } from '../api';
import { useData } from '../hooks/useData';
import { iPublication, Publication } from '../models/Publication';

async function getReadingListShared(): Promise<iPublication[]> {
    const response: AxiosResponse<iPublicationClient__API[]> = await axios.get(
        getApiLink('SharedArticle/GetShared')
    );
    return response.data.map(p => new Publication({ apiModel: p }));
}

export default function useReadingListShared() {
    return useData([], getReadingListShared);
}
