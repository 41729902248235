import { iUser } from "./core/models/Users";
import { iNotification } from "./components/Notifications/NotificationsItem";
import React from "react";
import { CurrentUser, iCurrentUser } from './core/models/CurrentUser';

interface iGlobalContextData {
    wWidth: number;
    wHeight: number;
    isSearchOpened: boolean;
    shouldOpenSearchOnType: boolean;
    setIsSearchOpened: (isOpened: boolean) => void;
    toggleIsSearchOpened: () => void;
    setShouldOpenSearchOnType: (value: boolean) => void;
    usersData: iUser[];
    getRem: (num: number) => number;
    addNotify: (notify: iNotification) => void;
    removeNotify: (id: string) => void;
    currentUser: iCurrentUser;
    currentUserInitials: string;
    inMyLibrary: boolean;
    setInMyLibrary: (v: boolean) => void;
    isSearchDebugEnabled: boolean;
}

class GlobalContextData implements iGlobalContextData {
    wWidth = 0;
    wHeight = 0;
    isSearchOpened = false;
    shouldOpenSearchOnType = true;
    toggleIsSearchOpened = () => {};
    setIsSearchOpened: (isOpened: boolean) => void = () => undefined;
    setShouldOpenSearchOnType: (value: boolean) => void = () => {};
    usersData: iUser[] = [];
    getRem = (num: number) => num;
    addNotify = (notify: iNotification) => {};
    removeNotify = (id: string) => {};
    currentUser: iCurrentUser = new CurrentUser();
    currentUserInitials = "";
    inMyLibrary = true;
    setInMyLibrary: (v: boolean) => void = () => null;
    isSearchDebugEnabled = false;
}

export const GlobalContext: React.Context<GlobalContextData> = React.createContext(
    new GlobalContextData()
);
