import React, { FC, useEffect, useState } from 'react';
import { IconLike, IconLiked } from '../../icons';
import Button from '../Button/Button';
import axios from 'axios';
import { getApiLink } from '../../core/api';
import { iPublication } from '../../core/models/Publication';
import { AnalyticsAction } from "../../core/models/AnalyticsAttributes";

interface iProps {
    className: string;
    data: iPublication;
}

const LikeButton: FC<iProps> = (
    {
        className,
        data,
    }
) => {
    const {
        isLiked: initialValue,
        id: articleId,
        publicationType: documentType,
    } = data;

    const [isLiked, setIsLiked] = useState(initialValue);

    useEffect(() => {
        setIsLiked(initialValue);
    }, [initialValue]);

    function toggleLiked() {
        setIsLiked(!isLiked);
        const requestName = isLiked ? 'Unlike' : 'Like';
        axios.post(getApiLink(`LikedArticle/${requestName}`, { articleId, documentType }));
    }

    return (
        <Button
            className={className}
            onClick={toggleLiked}
            tooltipText={isLiked ? 'Unlike' : 'Like '}
            analyticsData={{
                action: isLiked ? AnalyticsAction.Unlike : AnalyticsAction.Like,
                reportId: data.id,
            }}
        >
            {isLiked ? <IconLiked className="Icon32" /> : <IconLike className="Icon32" />}
        </Button>
    );
};

export default LikeButton;