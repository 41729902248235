import { useEffect, useState, useRef } from 'react';

export default function useWindowSizeDebounced(debounce: number) {
    const [width, setWidth] = useState<number>(window.innerWidth);
    const [height, setHeight] = useState<number>(window.innerHeight);
    const timer = useRef<any>(null);

    function handleWindowResize() {
        clearTimeout(timer.current);

        timer.current = setTimeout(() => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        }, debounce);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [width, height];
}
