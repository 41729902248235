import React, { FC, useContext, useMemo } from 'react';
import { SUPPORT_NATIVE_PDF } from '../../core/supportNativePDF';
import FramePdf from '../FramePdf/FramePdf';
import { getApiLink } from '../../core/api';
import Modal from '../Modal/Modal';
import { GlobalContext } from "../../GlobalContext";

interface iProps {
    isOpened: boolean;
    close: () => void;
    title: string;
    fileUrl?: string;
    fileName?: string;
}

const PdfReader: FC<iProps> = (
    {
        isOpened,
        close,
        title,
        fileName,
        fileUrl,
    }
) => {
    const { getRem } = useContext(GlobalContext);

    const modalStyles = useMemo(() => ({
        container: {
            width: '96%',
            maxWidth: getRem(1460),
            height: '92%',
            maxHeight: '92%',
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: SUPPORT_NATIVE_PDF ? getRem(60) : 0,
            paddingLeft: SUPPORT_NATIVE_PDF ? getRem(60) : 0,
            background: SUPPORT_NATIVE_PDF ? 'none' : "black",
            overflow: 'hidden'
        },
        overlay: {
            background: `rgba(40, 48, 54, 0.88)`
        },
        close: {
            top: 0,
            right: 0,
            height: getRem(50),
            width: getRem(50),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
    }), [getRem]);

    const file = fileUrl || getApiLink(`File/${(SUPPORT_NATIVE_PDF ? 'Read' : 'Get')}`, { fileName }) || '';

    return (
        <Modal
            isOpened={isOpened}
            close={close}
            style={modalStyles}
            closeOnEsc
        >
            {SUPPORT_NATIVE_PDF && <FramePdf file={file} title={title} />}
            {!SUPPORT_NATIVE_PDF && (
                <div style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white',
                    fontSize: getRem(24),
                    textAlign: 'center'
                }}>
                    Your browser doesn't support PDF reading
                </div>
            )}
        </Modal>
    );
}

export default PdfReader;