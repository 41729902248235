enum RoutesEnum {
    Home = '/',
    Browse = '/browse',
    DocumentDetails = '/details/:id',
    SnapDocumentDetails = '/snapDocumentDetails/:id',
    ReadingList = '/lists',
    SharedWithMeList = '/lists/shared',
    ContentWatch = '/contentWatch',
    Test = '/test-page'
}

export enum RoutesAriaEnum {
    Library = 1,
    Browse,
    Document,
    ReadingList,
    ContentWatch,
    Test,
}

export default RoutesEnum;
