import './DownloadList.styles.scss';
import { FC } from 'react';
import { getFileData, iFile } from '../../core/models/File';
import { getFileTypeTitle, isFileDownloadable } from '../../core/enums/FileTypesEnum';
import { AnalyticsAction, getAnalyticsAttributes } from "../../core/models/AnalyticsAttributes";

const Item: FC<
    {
        file: iFile,
        reportId: number,
    }
> = (
    {
        file,
        reportId,
    }
) => {
    const { title, icon: Icon } = getFileData(file);

    return (
        <a
            download
            href={file.fileUrl}
            className="DownloadList__item"
            {...getAnalyticsAttributes({
                action: AnalyticsAction.Download,
                reportId,
                fileType: getFileTypeTitle(file.type),
            })}
        >
            <div className="DownloadList__icon">
                <Icon/>
            </div>
            {title}
        </a>
    );
};

interface iProps {
    items: iFile[];
    reportId: number;
}

const DownloadList: FC<iProps> = (
    {
        items,
        reportId,
    }
) => {
    const filteredItems = items.filter(isFileDownloadable);

    return (
        <div className="DownloadList">
            {filteredItems.map(file => (
                <Item
                    key={file.type}
                    file={file}
                    reportId={reportId}
                />
            ))}
        </div>
    );
};

export default DownloadList;