import React, { FC, ReactNode } from 'react';
import Header from '../Header/Header';
import { Helmet } from 'react-helmet-async';

import './Page.styles.scss';
import Scrollbar from '../Scrollbar/Scrollbar';
import { PlatformNavigation, usePlatformFeatureFlag } from "@datassential/platform-ui-lib";
import PlatformHeader from "./PlatformHeader";

interface iProps {
    title: string;
    children: ReactNode;
    headerContent?: ReactNode;
    headerRightContent?: ReactNode
    isShownStickyFooter?: boolean;
}

const Page: FC<iProps> = (
    {
        children,
        title,
        headerContent,
        headerRightContent,
        isShownStickyFooter,
    }
) => {
    const isPlatformEnabled = usePlatformFeatureFlag('enable platform');
    return (
        <div className="Page">
            <Scrollbar isShownStickyFooter={isShownStickyFooter}>
                <Helmet title={`Report Pro by Datassential${title === '' ? '' : ' | '}${title}`}/>
                {isPlatformEnabled && (
                    <>
                        <PlatformNavigation/>
                        <PlatformHeader/>
                    </>
                )}
                {!isPlatformEnabled && (
                    <Header
                    rightChildren={headerRightContent}
                >
                    {headerContent}
                </Header>
                )}
                <div className="Page__wrapper">{children}</div>
            </Scrollbar>
        </div>
    );
};

export default Page;
