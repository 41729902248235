import React from "react";
import { ID } from "../../core/models";

export interface iOption {
    id: ID;
    title: string | React.ReactNode;
    groupId?: number;
    groupName?: string;
    isOptGroup?: boolean;
    isPartialChecked?: boolean;
    isChecked?: boolean;
    isHidden?: boolean;
}
export interface iGroupedOption {
    label: string;
    options: iOption[];
}

export interface iGroup {
    id: number;
    name: string;
    isHidden: boolean;
}


export const filterDataBySearch = <T>(data: T[], searchQuery: string): T[] => {
    const trimmedSearchQuery = searchQuery.trim();
    if (!trimmedSearchQuery) return data;
    const isIncludes = (str: string) => {
        return str.toLowerCase().includes(searchQuery.toLowerCase())
    }
    return data.filter((item: any) => isIncludes(item.title || '') || isIncludes(item.subtitle || ''));
};

export const sortOptions = (field: string) => (a: any, b: any) => (a[field] < b[field]) ? -1 : (a[field] > b[field]) ? 1 : 0;

export const getGroups = (options: iOption[]) => {
    return Array.from(new Set(options.map(({ groupId, groupName }) => JSON.stringify({ id: groupId, name: groupName, isHidden: false }))))
        .map((group) => JSON.parse(group))
        .filter(({ id }) => !!id);
};

export const transformDataByGroup = (groups: iGroup[], data: iOption[], selectedIds: ID[], searchQuery: string) => {
    if ( !groups.length ) return data;

    let transformedData: iOption[] = [];
    const sorted = data.sort(sortOptions('id'));
    const allowCollpase = !searchQuery;

    groups.forEach((group) => {
        const currentGroupItems = sorted.filter((item) => item.groupId === group.id);
        const currentGroupIds = currentGroupItems.map(({ id }) => id);
        const allSelected = currentGroupIds.every((id) => selectedIds.includes(id));
        const partialSelected = currentGroupIds.some((id) => selectedIds.includes(id));

        const groupOption = {
            id: group.id,
            groupId: group.id,
            title: group.name,
            isOptGroup: true,
            isPartialChecked: partialSelected && !allSelected,
            isChecked: allSelected,
            isHidden: allowCollpase ? group.isHidden : false
        };
        transformedData = [...transformedData, ...(currentGroupItems.length > 0 ? [groupOption] : []), ...(group.isHidden && allowCollpase ? [] : currentGroupItems)];
    });

    return [...transformedData, ...sorted.filter((item) => !item.groupId)];
}