import axios, { AxiosResponse } from 'axios';
import { getApiLink } from '../api';
import { iPublicationClient__API } from '../apiSchema';
import { useData } from '../hooks/useData';
import { iPublication, Publication } from '../models/Publication';

async function getFeaturedPublications(): Promise<iPublication[]> {
    const response: AxiosResponse<iPublicationClient__API[]> = await axios.get(
        getApiLink('FeaturedContent/GetAllRandomPublications')
    );
    return response.data.map(i => new Publication({ apiModel: i }));
}

export default function useFeaturedPublications() {
    return useData<iPublication[]>([], getFeaturedPublications);
}