import { positionValues, Scrollbars } from 'react-custom-scrollbars';
import React, { useRef, useState } from 'react';
import { renderVerticalThumb } from './ScrollbarThumb';
import StickyFooter from '../StickyFooter/StickyFooter';
import { isIE } from "../../core/supportNativePDF";

export default function Scrollbar(props: React.PropsWithChildren<any>) {
    const scrollbarRef = useRef<any>();
    const { isShownStickyFooter, ...otherProps } = props;
    const [isActiveFooter, setIsActiveFooter] = useState(false);

    const handleScrollToTop = () => {
        if ( scrollbarRef && scrollbarRef.current ) {
            if ( isIE ) {
                scrollbarRef.current.scrollToTop();
            } else {
                scrollbarRef.current.view.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        }
    }

    const handleScroll = (values: positionValues) => {
        if ( !isShownStickyFooter ) return;
        const { scrollTop, clientHeight } = values;
        setIsActiveFooter(scrollTop > clientHeight);
    }

    return (
        <Scrollbars
            ref={scrollbarRef}
            onScrollFrame={handleScroll}
            renderThumbVertical={renderVerticalThumb}
            {...otherProps}
            renderView={(props) => (
                <div
                    {...props}
                    style={{
                        ...props.style,
                    }}
                />
            )}
        >
            {props.children}
            {/* TODO: implement possibility update content in StickyFooter for different pages */}
            { isShownStickyFooter && (
                <StickyFooter
                    scrollToTop={handleScrollToTop}
                    isShown={isActiveFooter}
                />
            )}
        </Scrollbars>
    );
}