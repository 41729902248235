import axios, { AxiosResponse } from 'axios';
import { iRibbon__API } from '../apiSchema';
import { getApiLink } from '../api';
import { useData } from '../hooks/useData';
import { iShortRibbon, ShortRibbon } from '../models/ShortRibbon';

async function getShortRibbons(): Promise<iShortRibbon[]> {
    const response: AxiosResponse<iRibbon__API[]> = await axios.get(
        getApiLink('Ribbon/GetAllShort', { page: 'JumpTo' })
    );
    return response.data.map(p => new ShortRibbon({ apiModel: p }));
}

export default function useShortRibbons() {
    return useData([], getShortRibbons);
}