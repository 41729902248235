import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { iPublication } from '../../../core/models/Publication';
import TileImage from '../../../components/TilleImage/TileImage';
import PublicationSubTitle from '../PublicationSubTitle/PublicationSubTitle';
import Button from '../../../components/Button/Button';
import { IconDownload } from '../../../icons';
import './ChildDocument.styles.scss';
import RoutesEnum from '../../../core/enums/RoutesEnum';
import { useHistory } from 'react-router-dom';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import { getApiLink } from '../../../core/api';
import DownloadButton from '../../../components/DownloadButton/DownloadButton';

interface iProps {
    items: iPublication[];
    title: string;
}

const ChildDocuments: FC<iProps> = (
    {
        items,
        title
    }
) => {
    let history = useHistory();
    return (
        <div className="ChildDocuments">
            <SectionTitle text={title} />
            <div className="ChildDocuments__items">
                {items.map(data => (
                    <div
                        className="ChildDocument"
                        key={data.id}
                    >
                        <Link
                            to={RoutesEnum.DocumentDetails.replace(':id', `${data.id}`)}
                            className="ChildDocument__link"
                        />
                        <div className="ChildDocument__info">
                            <div className="ChildDocument__image">
                                <TileImage src={data.thumbnail} isDisabled={!data.hasAccess} />
                            </div>
                            <div className="ChildDocument__content">
                                <div className="ChildDocument__title ChildDocument__content-item">{data.title}</div>
                                <div className="ChildDocument__sub-title ChildDocument__content-item">
                                    <PublicationSubTitle data={data} uppercase/>
                                </div>
                                <div className="ChildDocument__description ChildDocument__content-item">{data.description}</div>
                            </div>
                        </div>
                        <div className="ChildDocument__actions">
                            {data.files[0] ? (
                                <DownloadButton
                                    className="ChildDocument__button"
                                    fileUrl={data.files[0].fileUrl}
                                    id={data.id}
                                />
                            ) : null}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ChildDocuments;