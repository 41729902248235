import React, { ReactNode, useCallback, useContext, useEffect, useRef } from 'react';
import { createPortal } from "react-dom";
import { CSSTransition } from "react-transition-group";

import cn from "classnames";
import "./Modal.styles.scss";
import { IconClose } from "../../icons";
import { GlobalContext } from "../../GlobalContext";

interface iProps {
    isOpened: boolean;
    close: () => void;
    children: ReactNode;
    style?: { [key: string]: React.CSSProperties; };
    styleOverlay?: React.CSSProperties;
    hasInner?: boolean;
    className?: string;
    animationName?: string;
    closeOnEsc?: boolean;
}

const Modal: React.FC<iProps> = ({
    isOpened,
    style,
    styleOverlay,
    children,
    close,
    className,
    hasInner = true,
    animationName,
    closeOnEsc
}) => {
    const { setShouldOpenSearchOnType } = useContext(GlobalContext);
    const modalRef = useRef(null);

    const handleClose = useCallback(
        (e) => {
            if (e.key === "Escape") {
                close();
            }
        },
        [close]
    );

    useEffect(() => {
        if (closeOnEsc && isOpened) {
            document.addEventListener("keydown", handleClose, { passive: true });

        }
        return () => {
            document.removeEventListener("keydown", handleClose);
        };
    }, [isOpened, handleClose, closeOnEsc]);

    useEffect(() => {
        setShouldOpenSearchOnType(!isOpened);
    }, [isOpened]);

    return createPortal(
        <CSSTransition
            in={isOpened}
            timeout={600}
            classNames={`Modal${animationName ? `__${animationName}` : ""}`}
            unmountOnExit={true}
            nodeRef={modalRef}
        >
            <div className={cn("Modal", [className])} onClick={(e: React.MouseEvent) => e.stopPropagation()} ref={modalRef}>
                <div className="Modal__overlay" style={style?.overlay} />
                {hasInner ? (
                    <div className="Modal__inner" style={style?.container}>
                        {children}
                        <div className="Modal__close" onClick={close} style={style?.close}>
                            <IconClose className="Icon32" />
                        </div>
                    </div>
                ) : (
                    <>
                        {children}
                        <div className="Modal__close" onClick={close}>
                            <IconClose className="Icon32" />
                        </div>
                    </>
                )}
            </div>
        </CSSTransition>,
        document.getElementById("portal-root")!
    );
};

export default Modal;
