import React, { FC, useContext, useState } from 'react';
import axios from 'axios';
import cn from 'classnames';
import Button from '../Button/Button';
import { IconCheck, IconInfo } from '../../icons';
import useNotification from '../../core/hooks/useNotification';
import { GlobalContext } from "../../GlobalContext";
import './RequestAccessButton.styles.scss';
import { getApiLink } from '../../core/api';
import PublicationTypesEnum from '../../core/enums/PublicationTypesEnum';
import { AnalyticsAction } from "../../core/models/AnalyticsAttributes";

type ButtonType = "Button" | "Link";

interface iProps {
    className?: string;
    articleId: number;
    documentType: PublicationTypesEnum;
    type?: ButtonType;
    text?: string;
}

const RequestAccessButton: FC<iProps> = (
    {
        className,
        articleId,
        documentType,
        type="Button",
        text=""
    }
    ) => {
    const { getRem } = useContext(GlobalContext);
    const { addNotification } = useNotification();
    const [isRequested, setIsRequested] = useState(false);

    const requestAccess = () => {
        if ( !articleId ) return;
        axios.post(getApiLink(`UserAccessRequest/Save`, { articleId, documentType }));
        addNotification({
            message: <span>
                This specific title is outside of your company’s subscription. If you need help finding what you’re looking for, please reach out to <a
                href="mailto:support@datassential.com">support@datassential.com</a>
            </span>,
            icon: <IconInfo className="white" />,
            customWidth: `${getRem(400)}px`
        });
        setIsRequested(true);
    };

    const classNames = cn(
        'RequestAccessButton',
        className,
        isRequested && 'RequestAccessButton--success',
    );

    return (
        <>
            {type === "Button" && (
                <Button
                    options={['primary']}
                    className={classNames}
                    onClick={isRequested ? () => null : requestAccess}
                    analyticsData={{
                        action: AnalyticsAction.RequestAccess,
                        reportId: articleId,
                    }}
                >
                    {isRequested && (
                        <div className="RequestAccessButton__icon">
                            <IconCheck />
                        </div>
                    )}
                    {isRequested ? 'Request sent' : 'Request access'}
                </Button>
            )}

            {type === "Link" && (
                <span className={isRequested ? "is-disabled" : ""} onClick={isRequested ? () => null : requestAccess}>
                    {text}
                </span>
            )}
        </>

    );
};

export default RequestAccessButton;