import React, { useState } from 'react';
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom';
import Routes from './Routes';
import RoutesEnum from './enums/RoutesEnum';
import GlobalSearch from '../pages/__Common/GlobalSearch/GlobalSearch';
import { iPublication, Publication } from './models/Publication';
import Share from '../components/Share/Share';

class SharePopupContextData {
    isOpened: boolean = false;
    open: (sharedItem: iPublication) => void = () => null;
    close: () => void = () => null;
}

export const SharePopupContext: React.Context<SharePopupContextData> = React.createContext(
    new SharePopupContextData(),
);

export default function Router() {
    const [isSharePopupOpened, setIsSharePopupOpened] = useState(false);
    const [sharedItem, setSharedItem] = useState<iPublication>(new Publication());

    const openSharePopup = (sharedItem: iPublication) => {
        setIsSharePopupOpened(true);
        setSharedItem(sharedItem);
    };

    const closeSharePopup = () => {
        setIsSharePopupOpened(false);
    };

    return (
        <SharePopupContext.Provider value={{
            isOpened: isSharePopupOpened,
            open: openSharePopup,
            close: closeSharePopup,
        }}>
            <BrowserRouter basename={`${process.env.PUBLIC_URL}/`}>
                <Switch>
                    {Routes.map((route) => (
                        <Route key={route.path} {...route} />
                    ))}
                    <Redirect to={RoutesEnum.Home} />
                </Switch>
                <GlobalSearch />
            </BrowserRouter>
            <Share
                isOpened={isSharePopupOpened}
                sharedItem={sharedItem}
                close={closeSharePopup}
                showPreview
            />
        </SharePopupContext.Provider>
    );
}
