import { FC } from 'react';
import './NoAccessMessage.styles.scss';

interface iProps {
    className?: string;
}

const NoAccessMessage: FC<iProps> = (
    {
        children= 'Unlock this with a Report Pro package',
        className = '',
    }
) => {
    return (
        <div className={`NoAccessMessage ${className}`}>{children}</div>
    );
};

export default NoAccessMessage;