import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import VisibilitySensor from 'react-visibility-sensor';
import { GlobalContext } from "../../GlobalContext";
import Grid from '../../components/Grid/Grid';
import Loader from '../../components/Loader/Loader';
import NoResults from '../../components/NoResults/NoResults';
import Page from '../../components/Page/Page';
import { useDocumentsWithFilters } from '../../core/apiHooks/useDocumentsWithFilters';
import { getSlidesPerView } from '../../core/formatters';
import Routes from '../../core/Routes';
import { ContentRibbon } from '../../core/models/ContentRibbon';
import BackTo from '../../components/BackTo/BackTo';
import Filters from '../../components/Filters/Filters';
import "./Browse.styles.scss";
import { SORT_ENUM } from '../../components/Filters/Filters.helpers';
import DownloadDetailsButton from '../../components/DownloadDetailsButton/DownloadDetailsButton';
import { ID } from "../../core/models";

const INITIAL_ROWS = 5;

export interface iSelectedFilters {
    ribbonsIds: ID[];
    categoriesIds: ID[];
    subscriptionPackageIds: ID[];
    tagsIds: ID[];
    orderBy: string;
    sortOrder: string;
    hasAccess: boolean;
}

const Browse: React.FC = () => {
    const location = useLocation();
    const { wWidth, getRem } = useContext(GlobalContext);
    const tilesPerView = getSlidesPerView(wWidth - getRem(2 * 48), 210);
    const take = INITIAL_ROWS * tilesPerView;

    const [selectedFilters, setSelectedFilter] = useState<iSelectedFilters>({
        ribbonsIds: [],
        categoriesIds: [],
        subscriptionPackageIds: [],
        tagsIds: [],
        orderBy: SORT_ENUM.DATE,
        sortOrder: SORT_ENUM.DESC,
        hasAccess: false
    });

    const {
        isLoading,
        data: documents,
        hasNextItems,
        incSkip
    } = useDocumentsWithFilters(selectedFilters, take);

    const handlePageBottom = (visibility: boolean) => {
        if (visibility && hasNextItems && !isLoading) {
            incSkip();
        }
    };

    const route = Routes.find(({ path }) => path === location.pathname) || Routes[0];
    const title = route?.title || '';

    const handleUpdateFilters = useCallback((filters: iSelectedFilters) => setSelectedFilter(filters), []);

    const isAnyTagsSelected = useMemo(
        () => selectedFilters.tagsIds.length + selectedFilters.subscriptionPackageIds.length > 0,
        [selectedFilters.tagsIds, selectedFilters.subscriptionPackageIds]
    );

    return (
        <Page
            title={title}
            headerContent={<BackTo />}
        >
            <div className="Browse">
                <div className="Browse__filters">
                    <Filters handleUpdateFilters={handleUpdateFilters} />
                </div>

                <div className="Browse__wrapper">
                    <Grid
                        data={new ContentRibbon({
                            clientModel: {
                                items: documents,
                            }
                        })}
                        columnsNumber={tilesPerView}
                    />

                    {!documents.length && !isLoading && !isAnyTagsSelected && (
                        <NoResults
                            title={"We've got lots of great reports for you!"}
                            subTitle={
                                <>
                                    {"But you'll need a Report Pro subscription to access it all.  Learn more "}
                                    <DownloadDetailsButton />
                                    {"."}
                                </>
                            }
                        />
                    )}

                    {isLoading && (
                        <div className={`Browse__loader ${documents.length === 0 ? 'is-initial' : ''}`}>
                            <Loader />
                        </div>
                    )}

                    <VisibilitySensor onChange={handlePageBottom} partialVisibility>
                        {!isLoading ? <div className="Browse__sensor" /> : <div/>}
                    </VisibilitySensor>
                </div>
            </div>
        </Page>
    );
};

export default Browse;
