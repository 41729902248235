import { iButtonCategory__API } from "../apiSchema";

export interface iSubCategories {
    id: number;
    title: string;
}

export interface iCategory {
    id: number;
    title: string;
}

interface iData {
    apiModel?: iButtonCategory__API;
}

export class Category {
    buttonSubcategories: iCategory[] = [];
    titleSubcategories: iCategory[] = [];

    constructor(data?: iData) {
        if (data && data.apiModel) {
            this.mapFromApi(data.apiModel);
        }
    }

    private mapFromApi(data: iButtonCategory__API) {
        this.titleSubcategories = (data.titleCategories || []).map((c) => ({
            id: c.id,
            title: c.name || `Title #${c.id}`
        }));
        this.buttonSubcategories = (data.buttonCategories || []).map((c) => ({
            id: c.id,
            title: c.name || `Button #${c.id}`
        }));
    }
}
