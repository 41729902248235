import React, { FC, useContext } from 'react';
import { IconDownloadFilled } from '../../icons';
import Button, { ButtonTypeEnum } from '../Button/Button';
import { GlobalContext } from "../../GlobalContext";
import { AnalyticsAction } from "../../core/models/AnalyticsAttributes";
import { FileTypesStringEnum } from "../../core/enums/FileTypesEnum";

interface iProps {
    className: string;
    fileUrl: string;
    id: number;
    fileType?: FileTypesStringEnum;
}

const DownloadButton: FC<iProps> = (
    {
        fileUrl,
        className,
        fileType = FileTypesStringEnum.NotSet,
        id,
    }
) => {
    const { getRem } = useContext(GlobalContext);

    return (
        <Button
            type={ButtonTypeEnum.DownloadButton}
            link={fileUrl}
            className={className}
            tooltipText={`Download${fileType ? ` ${fileType}` : ''}`}
            analyticsData={{
                action: AnalyticsAction.Download,
                reportId: id,
                fileType: fileType,
            }}

        >
            <IconDownloadFilled className="Icon32" />
        </Button>
    );
};

export default DownloadButton;