import qs from "query-string";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { GlobalContext } from "../../GlobalContext";
import { getQueryString, MAC_CATEGORY_TITLE } from "../../core/api";
import useCategories from "../../core/apiHooks/useCategories";
import useShortRibbons from "../../core/apiHooks/useShortRibbons";
import { IconClose, IconReset } from "../../icons";
import { iSelectedFilters } from "../../pages/Browse/Browse";
import Button from "../Button/Button";
import Checkbox from "../Checkbox/Checkbox";
import Select from "../Select/Select";
import { iOption } from "../Select/Select.helpers";
import {
    getParamItems,
    getSortTypes,
    iCategoryButton,
    sortOptions,
    SortTypesEnum,
    FiltersEnum
} from "./Filters.helpers";
import "./Filters.styles.scss";
import React from 'react';
import useSubscriptionPackages from "../../core/apiHooks/useSubscriptionPackages";
import useTags from "../../core/apiHooks/useTags";
import { ID } from "../../core/models";
import { isEqualStrings } from '../../core/helpers';

interface iProps {
    handleUpdateFilters: (filters: iSelectedFilters) => void;
}

export enum TagsSelectType {
    Tag = 'tag',
    Package = 'package',
}

const Filters: React.FC<iProps> = ({ handleUpdateFilters }) => {
    const { getRem, inMyLibrary, setInMyLibrary } = useContext(GlobalContext);
    const location = useLocation();
    const history = useHistory();
    const queryString = qs.parse(location.search.replace(/^\?/g, ""));

    const initialRibbonsIds = getParamItems(queryString[FiltersEnum.ribbonsIds]);
    const initialCategoriesIds = getParamItems(queryString[FiltersEnum.categoriesIds]);
    const initialSubCategoriesIds = getParamItems(queryString[FiltersEnum.subCategoriesIds]);
    const initialSubscriptionPackageIds = getParamItems(queryString[FiltersEnum.packages]);
    const initialTagsIds = getParamItems(queryString[FiltersEnum.tags]);

    const ribbons = useShortRibbons();
    const categories = useCategories();
    const subscriptionPackages = useSubscriptionPackages();
    const tags = useTags();

    const [ribbonsIds, setRibbonsIds] = useState<ID[]>(initialRibbonsIds);
    const [categoriesIds, setCategoriesIds] = useState<ID[]>(initialCategoriesIds.length ? [initialCategoriesIds[0]] : []);
    const [subCategoriesIds, setSubCategoriesIds] = useState<ID[]>(!initialCategoriesIds.length ? initialSubCategoriesIds : []);
    const [subscriptionPackageIds, setSubscriptionPackageIds] = useState<ID[]>(initialSubscriptionPackageIds.length ? initialSubscriptionPackageIds : []);
    const [tagsIds, setTagsIds] = useState<ID[]>(initialTagsIds.length ? initialTagsIds : []);
    const [selectedSortType, setSelectedSortType] = useState<SortTypesEnum[]>([SortTypesEnum.DateDesc]);

    useEffect(() => {
        const oldSearch = qs.parse(location.search);
        const additionalParams: any = {};

        Object.keys(oldSearch).forEach(key => {
            if (!([
                FiltersEnum.ribbonsIds,
                FiltersEnum.categoriesIds,
                FiltersEnum.subCategoriesIds,
                FiltersEnum.packages,
                FiltersEnum.tags,
                FiltersEnum.sortOrder,
                FiltersEnum.orderBy,
            ] as string[]).includes(key)) {
                additionalParams[key] = oldSearch[key];
            }
        });

        const params: {
            [FiltersEnum.ribbonsIds]?: string;
            [FiltersEnum.categoriesIds]?: string;
            [FiltersEnum.subCategoriesIds]?: string;
            [FiltersEnum.packages]?: string;
            [FiltersEnum.tags]?: string;
            [FiltersEnum.sortOrder]?: string;
            [FiltersEnum.orderBy]?: string;
        } = {};

        if (ribbonsIds.length > 0) {
            params[FiltersEnum.ribbonsIds] = ribbonsIds.join(",");
        }

        if (categoriesIds.length > 0) {
            params[FiltersEnum.categoriesIds] = `${categoriesIds[0]}`;
        }

        if (subCategoriesIds.length > 0) {
            params[FiltersEnum.subCategoriesIds] = subCategoriesIds.join(",");
        }

        if (subscriptionPackageIds.length > 0) {
            params[FiltersEnum.packages] = subscriptionPackageIds.join(",");
        }

        if (tagsIds.length > 0) {
            params[FiltersEnum.tags] = tagsIds.join(",");
        }

        if (selectedSortType.length > 0) {
            const { direction, sortBy } = getSortTypes(selectedSortType[0]);
            params[FiltersEnum.sortOrder] = sortBy;
            params[FiltersEnum.orderBy] = direction;
        }

        history.replace({
            search: getQueryString({...params, ...additionalParams })
        });
    }, [history, categoriesIds, ribbonsIds, selectedSortType, subCategoriesIds, tagsIds, subscriptionPackageIds]);

    useEffect(() => {
        const { direction, sortBy } = getSortTypes(selectedSortType[0]);

        handleUpdateFilters({
            ribbonsIds,
            orderBy: sortBy,
            sortOrder: direction,
            categoriesIds: categoriesIds.length ? categoriesIds : subCategoriesIds,
            tagsIds,
            subscriptionPackageIds,
            hasAccess: inMyLibrary,
        });
    }, [handleUpdateFilters, categoriesIds, ribbonsIds, selectedSortType, subCategoriesIds, inMyLibrary, tagsIds, subscriptionPackageIds]);

    const categoryButtons = useMemo(
        () => {
            if (categories.data === null) return [];
            const { buttonSubcategories } = categories.data;

            const selectedRibbons = (ribbons.data || []).filter(i => ribbonsIds.includes(i.id));

            const macCategoryIndex = buttonSubcategories.findIndex(i => isEqualStrings(MAC_CATEGORY_TITLE, i.title));

            if (macCategoryIndex >= 0 && selectedRibbons.some(i => isEqualStrings(MAC_CATEGORY_TITLE, i.title))) {
                return [
                    ...buttonSubcategories.slice(0, macCategoryIndex),
                    ...buttonSubcategories.slice(macCategoryIndex + 1),
                ];
            }

            return buttonSubcategories;
        },
        [categories.data, ribbonsIds, ribbons],
    );

    const categoriesToOptions = useMemo(
        () => {
            if (categories.data === null) return [];
            const { titleSubcategories } = categories.data;

            const getRenderTitle = (title: string) => {
                if (!title) return title;

                const matched = title.match(/\(([^)]+)\)/);
                if (matched) {
                    const valueInsideBrackets = matched[1];
                    const valueBeforeBrackets = title.slice(0, matched.index);
                    return (
                        <div className="Filters__option">
                            <span className="Filters__option-text">{valueBeforeBrackets}</span>
                            <span className="Filters__option-text is-additional">{valueInsideBrackets}</span>
                        </div>
                    )
                }

                return title;
            }


            return titleSubcategories.filter(item => item.title.toLowerCase().trim() !== "none").map(item => {
                return {
                    ...item,
                    title: getRenderTitle(item.title)
                }
            });
        },
        [categories.data],
    );

    const tagsOptions: iOption[] = useMemo(() => {
        return [
            ...subscriptionPackages.data
                .filter(item => item.title.toLowerCase().trim().indexOf('enable login') === -1)
                .filter(item => !subscriptionPackageIds.includes(item.id))
                .map(item => ({
                    id: `${TagsSelectType.Package}-${item.id}`,
                    title: item.title,
                })),
            ...tags.data.filter(tag => !tagsIds.includes(tag.id)).map(tag => ({
                id: `${TagsSelectType.Tag}-${tag.id}`,
                title: tag.title,
            })),
        ];
    }, [
        tags.data,
        subscriptionPackages.data,
        tagsIds,
        subscriptionPackageIds,
    ]);

    const selectedTags = useMemo(() => {
        return [
            ...subscriptionPackages.data
                .filter(item => subscriptionPackageIds.includes(item.id))
                .map(item => ({
                    id: `${TagsSelectType.Package}-${item.id}`,
                    title: item.title,
                })),
            ...tags.data.filter(tag => tagsIds.includes(tag.id)).map(tag => ({
                id: `${TagsSelectType.Tag}-${tag.id}`,
                title: tag.title,
            })),
        ];
    }, [
        tags.data,
        subscriptionPackages.data,
        tagsIds,
        subscriptionPackageIds,
    ]);

    const handleRemoveTag = useCallback((id: ID) => {
        const parsedType = (id as string).split('-')[0];
        const parsedId = +(id as string).split('-')[1];

        if (parsedId) {
            switch (parsedType) {
                case TagsSelectType.Tag: {
                    setTagsIds(tagsIds.filter(i => i !== parsedId));
                    break;
                }
                case TagsSelectType.Package: {
                    setSubscriptionPackageIds(subscriptionPackageIds.filter(i => i !== parsedId));
                    break;
                }
            }
        }
    }, [tagsIds, subscriptionPackageIds]);

    const handleChangeTagsSelect = useCallback((ids: ID[]) => {
        const id = ids[0];

        if (id) {
            const parsedType = (id as string).split('-')[0];
            const parsedId = +(id as string).split('-')[1];

            if (parsedId) {
                switch (parsedType) {
                    case TagsSelectType.Tag: {
                        setTagsIds([...tagsIds, parsedId]);
                        break;
                    }
                    case TagsSelectType.Package: {
                        setSubscriptionPackageIds([...subscriptionPackageIds, parsedId]);
                        break;
                    }
                }
            }
        }
    }, [tagsIds, subscriptionPackageIds]);

    const handleResetFilters = () => {
        setSelectedSortType([SortTypesEnum.DateDesc]);
        setRibbonsIds([]);
        setCategoriesIds([]);
        setSubCategoriesIds([]);
        setSubscriptionPackageIds([]);
        setTagsIds([]);
    };

    const handleChangeCategories = (id: ID) => {
        setSubCategoriesIds([]);
        setRibbonsIds([]);
        setCategoriesIds((prevState) => (prevState.includes(id) ? [] : [id]));
    }

    const handleChangeRibbons = (ids: ID[]) => {
        setCategoriesIds([]);
        setSubCategoriesIds([]);
        setRibbonsIds(ids);
    }

    const handleChangeSubCategories = (ids: ID[]) => {
        setCategoriesIds([]);
        setRibbonsIds([]);
        setSubCategoriesIds(ids)
    }

    // Styles
    const isVisible = { opacity: !categories.isLoading && !ribbons.isLoading ? 1 : 0 };

    const selectStyles = {
        selectContainer: { marginRight: -getRem(15) },
        inputLabel: { height: getRem(40) },
        input: { height: getRem(40) },
        selectDropdown: { minWidth: getRem(400) }
    };

    return (
        <div className="Filters">
            <div className="Filters__wrapper">
                <div style={isVisible} className="Filters__row">
                    <div className="Filters__column">
                        <div className="Filters__buttons-group">
                            {categoryButtons.map(({ title, id }) => {
                                const isActive = categoriesIds.includes(id);
                                return (
                                    <Button
                                        key={id}
                                        options={["filters"]}
                                        className={`${isActive ? "is-active" : ""}`}
                                        onClick={() => handleChangeCategories(id)}
                                    >
                                        {title}
                                    </Button>
                                );
                            })}
                            <div className="Filters__button">
                                <Select
                                    inputButton
                                    valuePlaceholder="Themes"
                                    options={ribbons.data}
                                    isLoading={ribbons.isLoading}
                                    value={ribbonsIds}
                                    onChange={(ids) => handleChangeRibbons(ids)}
                                    buttonOptions={["filters"]}
                                    isSearchable
                                    autoFocus
                                    dropdownHeight={240}
                                    styles={{
                                        selectDropdown: { minWidth: getRem(270) },
                                    }}
                                />
                            </div>
                            <div className="Filters__button">
                                <Select
                                    inputButton
                                    valuePlaceholder="Tags"
                                    options={tagsOptions}
                                    isLoading={tags.isLoading || subscriptionPackages.isLoading}
                                    value={[]}
                                    onChange={handleChangeTagsSelect}
                                    buttonOptions={["filters"]}
                                    isActive={selectedTags.length > 0}
                                    isSearchable
                                    autoFocus
                                    dropdownHeight={240}
                                    closeOnSelect={false}
                                    styles={{
                                        selectDropdown: { minWidth: getRem(270) },
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="Filters__column is-right">
                        <Select
                            options={categoriesToOptions}
                            value={subCategoriesIds}
                            onChange={(ids) => handleChangeSubCategories(ids)}
                            isMultiple
                            dropdownHeight={240}
                            isLoading={categories.isLoading}
                            isSimpleInput
                            valuePlaceholder="SELECT BY TITLE"
                            dropdownPosition="right"
                            styles={selectStyles}
                        />
                    </div>
                </div>
                <div style={isVisible} className="Filters__row">
                    <div className="Filters__column">
                        <div className="Filters__toggle">
                            <Checkbox
                                isChecked={inMyLibrary}
                                label="In my library"
                                onChange={() => setInMyLibrary(!inMyLibrary)}
                                isToggle
                            />
                        </div>
                    </div>
                    <div className="Filters__column is-right">
                        <div className="Filters__reset">
                            <div className="Filters__reset-button" onClick={handleResetFilters}>
                                <div className="Filters__reset-icon">
                                    <IconReset/>
                                </div>
                                Reset filter
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="Filters__sort-select" style={isVisible}>
                <Select
                    title="Sort by:"
                    options={sortOptions}
                    value={selectedSortType}
                    isLoading={false}
                    onChange={(ids) => setSelectedSortType(ids as SortTypesEnum[])}
                    isCheckboxHidden
                    dropdownHeight={160}
                    isSimpleInput
                />
                <div className="Filters__tags">
                    {selectedTags.map(tag => (
                        <div
                            key={tag.id}
                            onClick={() => handleRemoveTag(tag.id)}
                            className="FilterTag"
                        >
                            {tag.title}
                            <IconClose className="FilterTag__icon"/>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Filters;
