import axios, { AxiosResponse } from 'axios';
import { iPublicationClient__API } from '../apiSchema';
import { getApiLink } from '../api';
import { useData } from '../hooks/useData';
import { iPublication, Publication } from '../models/Publication';

async function getReadingList(): Promise<iPublication[]> {
    const response: AxiosResponse<iPublicationClient__API[]> = await axios.get(
        getApiLink('FavouriteArticle/GetReadingList')
    );
    return response.data.map(p => new Publication({ apiModel: p }));
}

export default function useReadingList() {
    return useData([], getReadingList);
}