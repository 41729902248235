import React, { createContext, memo, useContext, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { iPublication } from '../../core/models/Publication';
import Tag from '../Tag/Tag';
import cn from 'classnames';
import PublicationSubTitle from '../../pages/__Common/PublicationSubTitle/PublicationSubTitle';
import TileImage from '../TilleImage/TileImage';
import RoutesEnum from '../../core/enums/RoutesEnum';
import ShareButton from '../ShareButton/ShareButton';

import './Tile.styles.scss';
import LikeButton from '../LikeButton/LikeButton';
import AddToReadingListButton from '../AddToReadingListButton/AddToReadingListButton';
import DownloadButton from '../DownloadButton/DownloadButton';
import RemoveFromReadingListButton from '../RemoveFromReadingListButton/RemoveFromReadingListButton';
import { LogoSnap } from '../../icons';
import Button, { ButtonTypeEnum } from '../Button/Button';
import RequestAccessButton from '../RequestAccessButton/RequestAccessButton';
import NoAccessMessage from '../NoAccessMessage/NoAccessMessage';
import PublicationTypesEnum from '../../core/enums/PublicationTypesEnum';
import { getFileTypeTitle } from '../../core/enums/FileTypesEnum';
import { AnalyticsAction, getAnalyticsAttributes } from "../../core/models/AnalyticsAttributes";
import { GlobalContext } from '../../GlobalContext';

interface iTileProps {
    data: iPublication;
    translateX?: number;
    parentId?: number;
    isSelected?: boolean;
    hasBottomReflection?: boolean;
    lazyLoadImages?: boolean;
    deferLoad?: boolean;
}

interface iTileContext {
    isActiveRemove?: boolean;
    isShared?: boolean;
}

export const TileContext = createContext<iTileContext>({
    isActiveRemove: false,
    isShared: false
});

const Tile: React.FC<iTileProps> = memo((
    {
        data,
        isSelected = false,
        hasBottomReflection = false,
        lazyLoadImages = false,
        deferLoad = false
    }
) => {
    const { isSearchDebugEnabled } = useContext(GlobalContext);
    const { isActiveRemove, isShared } = useContext(TileContext);
    const el = useRef<HTMLDivElement>(null);
    const [isHighlighted] = useState(isSelected);


    const className = cn(
        'Tile',
        isHighlighted && 'Tile--highlighted'
    );

    const image = (hideLoader = false) => (
        <TileImage
            src={data.thumbnail}
            isDisabled={!data.hasAccess}
            title={data.title}
            id={data.id}
            hideLoader={hideLoader}
            lazyLoad={lazyLoadImages}
            deferLoad={deferLoad}
        />
    );


    return (
        <div ref={el} className={className}>
            {hasBottomReflection && (
                <div className="TilePopup Tile__reflection">
                    <div className="TilePopup__image">
                        {image(true)}
                    </div>
                </div>
            )}

            <div className="Tile__popup TilePopup">
                <div className="TilePopup__image">
                    {image(true)}
                </div>
                {!data.hasAccess && (
                    <>
                        <NoAccessMessage className="TilePopup__access-msg" />
                        <RequestAccessButton
                            articleId={data.id}
                            className="TilePopup__access-button"
                            documentType={data.publicationType}
                        />
                    </>
                )}

                {data.customLink && data.hasAccess && (
                    <a
                        href={data.customLink}
                        target="_blank"
                        className="TilePopup__link"
                    />
                )}
                {!data.customLink && (
                    <Link
                        className="TilePopup__link"
                        to={RoutesEnum.DocumentDetails.replace(':id', `${data.id}`)}
                        {...getAnalyticsAttributes({
                            action: AnalyticsAction.Navigate,
                            reportId: data.id,
                        })}
                    />
                )}

                <div className="TilePopup__info">
                    <div className="TilePopup__title">{data.title}</div>
                    <div className="TilePopup__sub-title">
                        <PublicationSubTitle data={data} uppercase/>
                    </div>
                </div>

                <div className="TilePopup__actions">
                    {data.files[0] && data.hasAccess ? (
                        <DownloadButton
                            className="TilePopup__action"
                            fileUrl={data.files[0].fileUrl}
                            fileType={getFileTypeTitle(data.files[0].type)}
                            id={data.id}
                        />
                    ) : null}
                    {data.publicationType === PublicationTypesEnum.Mac && data.hasAccess &&  (
                        <Button
                            className="TilePopup__action"
                            type={ButtonTypeEnum.Anchor}
                            link={data.customLink}
                            tooltipText="View in SNAP"
                        >
                            <LogoSnap/>
                        </Button>
                    )}
                    {!isActiveRemove && (
                        <AddToReadingListButton
                            className="TilePopup__action"
                            data={data}
                        />
                    )}
                    {isActiveRemove && !isShared && (
                        <RemoveFromReadingListButton
                            className="TilePopup__action"
                            data={data}
                        />
                    )}
                    <LikeButton
                        className="TilePopup__action"
                        data={data}
                    />
                    <ShareButton
                        className="TilePopup__action"
                        data={data}
                    />
                </div>
                {isSearchDebugEnabled && !!data.score && (
                    <div className="TilePopup__score">
                        <span>search score</span>
                        <span>{data.score.toFixed(2)}</span>
                    </div>
                )}
            </div>
        </div>
    );
});

export default Tile;
