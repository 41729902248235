import { iPublication, Publication } from '../models/Publication';
import axios, { AxiosResponse } from 'axios';
import { iPublicationClientDetail__API } from '../apiSchema';
import { getApiLink } from '../api';
import { useData } from '../hooks/useData';

interface iData {
    publication: iPublication;
    chapters: iPublication[];
    relatedPublications: iPublication[];
}

const defaultData: iData = {
    publication: new Publication(),
    relatedPublications: [],
    chapters: []
};

async function getDocumentDetails(id: number): Promise<iData> {
    const response: AxiosResponse<iPublicationClientDetail__API> = await axios.get(
        getApiLink('PublicationClient/GetDetail', { id })
    );

    const {
        publication,
        relatedPublications,
    } = response.data;

    return {
        publication: new Publication({ apiModel: publication }),
        relatedPublications: relatedPublications.map(p => new Publication({ apiModel: p })),
        // chapters: relatedPublications.slice(0, 20).map(p => new Publication({ apiModel: p })),
        chapters: publication.chapters.map(p => new Publication({ apiModel: p })),
    };
}

export default function useDocumentDetails(id: number) {
    return useData(defaultData, getDocumentDetails, id);
}