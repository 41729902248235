import { useEffect, useState } from "react";

const useConfirmPopup = () => {
    const [shown, setShown] = useState(false);
    const [itemId, setItemId] = useState(0);
    const [itemTitle, setItemTitle] = useState("");

    useEffect(() => {
        let resetTimer: ReturnType<typeof setTimeout>;

        if (!shown) {
            resetTimer = setTimeout(() => {
                setItemId(0);
                setItemTitle("");
            }, 500);
        }

        return () => {
            clearInterval(resetTimer);
        }
    }, [shown]);

    const hide = () => {
        setShown(false);
    };

    const show = (id: number, title: string) => {
        setShown(true);
        setItemId(id);
        setItemTitle(title);
    };

    return { shown, setShown, itemId, itemTitle, setItemId, setItemTitle, hide, show };
};

export default useConfirmPopup;