import React, { FC } from 'react';
import { iPublication } from '../../../core/models/Publication';
import './FeaturedContent.styles.scss';
import Button, { ButtonTypeEnum } from '../../../components/Button/Button';
import PublicationSubTitle from '../PublicationSubTitle/PublicationSubTitle';
import RoutesEnum from '../../../core/enums/RoutesEnum';
import ShareButton from '../../../components/ShareButton/ShareButton';
import AddToReadingListButton from '../../../components/AddToReadingListButton/AddToReadingListButton';
import LikeButton from '../../../components/LikeButton/LikeButton';
import { getCategoryImageById } from '../../../core/enums/ImagedCategoryIdsEnum';
import { IconDeny } from '../../../icons';
import DownloadDetailsButton from '../../../components/DownloadDetailsButton/DownloadDetailsButton';

export interface iFeaturedContentProps {
    data: iPublication;
}

const FeaturedContent: FC<iFeaturedContentProps> = ({ data }) => {
    const Logo = getCategoryImageById(data.titleCategoryId);

    return (
        <div className="FeaturedContent">
            <div className="FeaturedContent__info FeaturedContentInfo">
                {Logo && (
                    <div className="FeaturedContentInfo__logo">
                        <Logo/>
                    </div>
                )}
                <div className="FeaturedContentInfo__title">{data.title}</div>
                <div className="FeaturedContentInfo__sub-title">
                    <PublicationSubTitle data={data} />
                </div>
                <div
                    className="FeaturedContentInfo__description"
                    dangerouslySetInnerHTML={{ __html: data.description }}
                />
                <div className="FeaturedContentInfo__tags">{}</div>
                <div className="FeaturedContentInfo__footer">
                    <Button
                        type={ButtonTypeEnum.Link}
                        link={RoutesEnum.DocumentDetails.replace(':id', `${data.id}`)}
                        options={['primary']}
                        className="FeaturedContentInfo__button"
                    >
                        Details
                    </Button>
                    <AddToReadingListButton
                        className="FeaturedContentInfo__button"
                        data={data}
                    />
                    <LikeButton
                        className="FeaturedContentInfo__button"
                        data={data}
                    />
                    <ShareButton
                        className="FeaturedContentInfo__button"
                        data={data}
                    />
                </div>

                {!data.hasAccess && (
                    <div className="FeaturedContentInfo__access-message">
                        <IconDeny className="Icon32" />
                        Unlock this by subscribing to Report Pro - learn more&nbsp;<DownloadDetailsButton />
                    </div>
                )}
            </div>
            <div
                className="FeaturedContent__thumbnail"
                style={{backgroundImage: `url(${data.featuredImage || data.image})`}}
            />
        </div>
    );
};

export default FeaturedContent;
