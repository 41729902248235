import { removeFromArray, toggleInArray } from "../../core/helpers";
import useComponentVisible from "../../core/hooks/useComponentVisible";
import Dropdown from "../Dropdown/Dropdown";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List, ListChildComponentProps } from "react-window";
import "./Autocomplete.styles.scss";
import CustomScrollbarsVirtualList from "../CustomScrollbarsVirtualList/CustomScrollbarsVirtualList";
import React, { useContext, useEffect, useRef, useState } from "react";
import { filterDataBySearch } from "../Select/Select.helpers";
import { IconClose } from "../../icons";
import Scrollbar from "../Scrollbar/Scrollbar";
import { GlobalContext } from "../../GlobalContext";

interface iOption {
    id: number | string;
    title: string;
    subtitle?: string;
}

interface iProps {
    isDisabled?: boolean;
    isLoading?: boolean;

    value: (string | number)[];
    options: iOption[];
    onChange: (ids: (string | number)[]) => void;
    autoHighlight?: boolean;
    multiple?: boolean;
}
interface iOptionProps extends ListChildComponentProps {
    data: {
        options: iOption[];
        handleSelect: (id: number | string) => void;
        activeOptionIndex: number;
        searchQuery: string;
    };
}
const Option: React.FC<iOptionProps> = ({ index, style, data: { options, handleSelect, activeOptionIndex, searchQuery } }) => {
    const item = options[index];

    return (
        <div
            className={`Autocomplete__option ${activeOptionIndex === index ? "is-selected" : ""}`}
            key={item.id}
            style={style}
        >
            <div className="Autocomplete__option-text" onClick={() => handleSelect(item.id)}>
                <div className="Autocomplete__option-title">
                    {item.subtitle && (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: item.subtitle.replace(
                                    new RegExp(`(${searchQuery})`, "gi"),
                                    "<span class='Autocomplete__option-highlight'>$1</span>"
                                )
                            }}
                        ></span>
                    )}
                </div>
                <div className="Autocomplete__option-subtitle">
                    {item.title && (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: item.title.replace(
                                    new RegExp(`(${searchQuery})`, "gi"),
                                    "<span class='Autocomplete__option-highlight'>$1</span>"
                                )
                            }}
                        ></span>
                    )}
                </div>
            </div>
        </div>
    );
};



interface iUserTag {
    name: string;
    onRemove: () => void;
}
const UserTag: React.FC<iUserTag> = ({ name, onRemove }) => {
    return (
        <div className="Autocomplete__tags-item">
            <div className="Autocomplete__tags-item-label">{name}</div>
            <div className="Autocomplete__tags-item-remove" onClick={onRemove}>
                <IconClose className="Icon24" />
            </div>
        </div>
    );
};

const Autocomplete: React.FC<iProps> = ({ isDisabled, isLoading, onChange, value, options }) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const [filteredData, setFilteredData] = useState<iOption[]>(options);
    const [searchQuery, setSearchQuery] = useState("");
    const [isDropdownToggling, setIsDropdownToggling] = useState(false);
    const listRef = useRef<any>(null);
    const DEFAULT_ACTIVE_OPTION_INDEX = -1;
    const [activeOptionIndex, setActiveOptionIndex] = useState(DEFAULT_ACTIVE_OPTION_INDEX);
    const { getRem } = useContext(GlobalContext);
    const ITEM_SIZE = getRem(56);
    const handleDropdownToggle = () => {
        if (!isDisabled && !isLoading) {
            setIsComponentVisible(!isComponentVisible);
            setSearchQuery("");
            setIsDropdownToggling(true);
            setActiveOptionIndex(DEFAULT_ACTIVE_OPTION_INDEX);
        }
    };

    const handleSelect = (userId: number | string) => {
        onChange(toggleInArray(value, userId));
        setIsComponentVisible(false);
        setSearchQuery("");
    };

    useEffect(() => {
        if (isDropdownToggling) return;
        const trimSearchQuery = searchQuery.trim();

        const filteredSelected = options.filter(({ id }) => !value.includes(id));
        const filteredBySearch = filterDataBySearch(filteredSelected, searchQuery);

        if (trimSearchQuery !== "") {
            filteredBySearch.sort((a, b) => {
                const isATitleBegins = a.title.toLowerCase().indexOf(trimSearchQuery) === 0;
                const isBTitleBegins = b.title.toLowerCase().indexOf(trimSearchQuery) === 0;
                const isASubTitleBegins = a.subtitle?.toLowerCase().indexOf(trimSearchQuery) === 0;
                const isBSubTitleBegins = b.subtitle?.toLowerCase().indexOf(trimSearchQuery) === 0;
                const aScore = +isATitleBegins * 10 + +isASubTitleBegins;
                const bScore = +isBTitleBegins * 10 + +isBSubTitleBegins;

                if (aScore === bScore) return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1;
                else if (aScore < bScore) return 1;
                return -1;
            });
        }

        setFilteredData(filteredBySearch);
    }, [options, searchQuery, value, isDropdownToggling]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
        setActiveOptionIndex(DEFAULT_ACTIVE_OPTION_INDEX);
    };

    useEffect(() => {
        if (!isComponentVisible && inputRef.current) {
            setSearchQuery("");
            setIsDropdownToggling(true);
            inputRef.current.blur();
        }
    }, [isComponentVisible, setIsComponentVisible]);

    const handleArrows = (e: any) => {
        switch (e.key) {
            case "ArrowDown":
                const downIdx = activeOptionIndex === filteredData.length - 1 ? filteredData.length - 1 : activeOptionIndex + 1;
                setActiveOptionIndex(downIdx);
                if (listRef.current) {
                    listRef.current.scrollToItem(downIdx);
                }
                break;
            case "ArrowUp":
                const upIdx = activeOptionIndex === 0 ? 0 : activeOptionIndex - 1;
                setActiveOptionIndex(upIdx);
                if (listRef.current) {
                    listRef.current.scrollToItem(upIdx);
                }
                break;
            case "Enter":
                e.preventDefault();
                const currentUser = filteredData[activeOptionIndex];
                handleSelect(currentUser.id);
                break;
        }
    };

    return (
        <div className="Autocomplete" ref={ref}>
            <div className="Autocomplete__form">
                <input
                    className="Autocomplete__input"
                    placeholder="Email"
                    type="text"
                    onFocus={handleDropdownToggle}
                    value={searchQuery}
                    onChange={handleSearch}
                    ref={inputRef}
                    onKeyDown={handleArrows}
                    disabled={isDisabled}
                />

                <Dropdown shown={isComponentVisible} onExited={() => setIsDropdownToggling(false)} onEntered={() => setIsDropdownToggling(false)}>
                    <div className="Autocomplete__dropdown">
                        {!!filteredData.length && (
                            <div className="Autocomplete__options" style={{ height: ITEM_SIZE * 4 }}>
                                <AutoSizer>
                                    {({ height, width }) => (
                                        <List
                                            className="List"
                                            outerElementType={CustomScrollbarsVirtualList}
                                            height={height}
                                            itemCount={filteredData.length}
                                            itemSize={ITEM_SIZE}
                                            width={width}
                                            ref={listRef}
                                            itemData={{
                                                options: filteredData,
                                                handleSelect,
                                                activeOptionIndex,
                                                searchQuery
                                            }}
                                        >
                                            {Option}
                                        </List>
                                    )}
                                </AutoSizer>
                            </div>
                        )}
                        {!filteredData.length && <div className="Autocomplete__no-match-items">No matches items...</div>}
                    </div>
                </Dropdown>
            </div>

            <div className="Autocomplete__tags">
                <Scrollbar autoHide autoHideTimeout={500} autoHideDuration={500}>
                    <div className="Autocomplete__tags-list">
                        {value
                            .map((id) => options.find((el) => el.id === id))
                            .map((item) => {
                                if (!item) return null;
                                return (
                                    <UserTag
                                        key={item.id}
                                        name={item.subtitle || item.title}
                                        onRemove={() => onChange(removeFromArray(value, [item.id]))}
                                    />
                                );
                            })}
                    </div>
                </Scrollbar>
            </div>
        </div>
    );
};

export default Autocomplete;
