import NotificationsItem, { iNotification } from "./components/Notifications/NotificationsItem";
import { toast } from "react-toastify";
import React from "react";

export const addNotify = (notify: iNotification) => toast(<NotificationsItem notify={notify} />, {
    onOpen: ({ toastProps }: any) => {
        const currentNotify = document.getElementById(toastProps.toastId);
        const currentHeight = currentNotify?.offsetHeight;
        currentNotify?.style.setProperty('--offset', (currentHeight ? -currentHeight : 0)  + "px");
    },
    toastId: notify.id

});

export const removeNotify = (id: string) => toast.dismiss(id);