import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../GlobalContext";
import { getSlidesPerView } from "../../core/formatters";
import { iContentRibbon } from "../../core/models/ContentRibbon";
import SectionTitle from "../SectionTitle/SectionTitle";
import Tile from "../Tile/Tile";
import VisibilitySensor from "react-visibility-sensor";
import "./Ribbon.styles.scss";
import ColumnSlider from '../ColumnSlider/ColumnSlider';

export interface iProps {
    data: iContentRibbon;
    approxTileWidth?: number;
    isFirstItemSelected?: boolean;
    hasSensor?: boolean;
}

const Ribbon: React.FC<iProps> = ({
    data: { id, title, items, subTitle },
    approxTileWidth = 210,
    hasSensor = false,
    children,
}) => {
    const [slidesPerView, setSlidesPerView] = useState<number>(0);
    const { wWidth } = useContext(GlobalContext);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setSlidesPerView(getSlidesPerView(wWidth - 100, approxTileWidth));
    }, [approxTileWidth, wWidth]);

    const handleChangeSensor = (visibility: boolean) => {
        if (visibility) {
            setIsLoaded(true);
        }
    };

    // const fullSlidesCount = (items.length - items.length%slidesPerView)/slidesPerView;
    // const preparedItems = children ? items.slice(0, fullSlidesCount * slidesPerView - 1) : items;

    return (
        <div className="Ribbon">
            <VisibilitySensor onChange={handleChangeSensor} partialVisibility active={hasSensor && !isLoaded} offset={{top: -200}}>
                <div className="Ribbon__sensor"/>
            </VisibilitySensor>
            <div className="Ribbon__title">
                <SectionTitle text={title} subText={subTitle} />
            </div>
            <div className="Ribbon__tiles">
                <ColumnSlider slidesPerView={slidesPerView}>
                    {items.map((item) => (
                        <Tile
                            key={item.id}
                            data={item}
                            parentId={id}
                        />
                    ))}
                    {items.length > slidesPerView && children}
                </ColumnSlider>
            </div>
        </div>
    );
};

export default Ribbon;