import { useEffect, useState } from "react";
import cn from "classnames";
import VisibilitySensor from "react-visibility-sensor";
import RoutesEnum from "../../core/enums/RoutesEnum";
import { IconBackToTop, IconShowAll } from "../../icons";
import Button, { ButtonTypeEnum } from "../Button/Button";
import "./StickyFooter.styles.scss";

interface iProps {
    scrollToTop?: any;
    isShown?: boolean;
}

const StickyFooter: React.FC<iProps> = ({ scrollToTop, isShown }) => {
    const [isActive, setIsActive] = useState(isShown);
    const [isLarge, setIsLarge] = useState(false);

    useEffect(() => {
        setIsActive(isShown);
    }, [isShown]);

    const handleChangeSensor = (visibility: boolean) => {
        setIsLarge(visibility);
    };
    const classNames = cn(
        'StickyFooter',
        isActive && "is-active",
        isLarge && "is-large"
    );

    return (
        <>
            <div className={classNames}>
                <div className="StickyFooter__actions">
                    <div className="StickyFooter__button">
                        <Button className="Header__button" type={ButtonTypeEnum.Button} onClick={scrollToTop}>
                            <IconBackToTop className="StickyFooter__button-icon" />
                            <div className="StickyFooter__button-text">Back to top</div>
                        </Button>
                    </div>

                    <div className="StickyFooter__button">
                        <Button className="Header__button" type={ButtonTypeEnum.NavLink} link={RoutesEnum.Browse}>
                            <IconShowAll className="StickyFooter__button-icon is-large" />
                            <div className="StickyFooter__button-text">Browse all</div>
                        </Button>
                    </div>
                </div>
            </div>
            <VisibilitySensor onChange={handleChangeSensor} partialVisibility>
                <div className="StickyFooter__sensor" />
            </VisibilitySensor>
        </>
    );
};

export default StickyFooter;
