import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import Page from '../../components/Page/Page';
import Loader from '../../components/Loader/Loader';
import RoutesEnum from '../../core/enums/RoutesEnum';
import Ribbon from '../../components/Ribbon/Ribbon';
import Tag from '../../components/Tag/Tag';
import Button from '../../components/Button/Button';
import './DocumentDetails.styles.scss';
import {
    IconAddedToRL,
    IconBack,
    IconBell,
    IconBellFilled,
    IconBellMinus,
    IconBellPlus,
    IconDownload,
    IconRead,
    IconRL
} from '../../icons';
import PublicationSubTitle from '../__Common/PublicationSubTitle/PublicationSubTitle';
import useDocumentDetails from '../../core/apiHooks/useDocumentDetails';
import ChildDocuments from '../__Common/ChildDocuments/ChildDocuments';
import TileImage from '../../components/TilleImage/TileImage';
import { ContentRibbon } from '../../core/models/ContentRibbon';
import ShareButton from '../../components/ShareButton/ShareButton';
import DownloadList from '../../components/DownloadList/DownloadList';
import FileTypesEnum, { getFileTypeTitle } from '../../core/enums/FileTypesEnum';
import LikeButton from '../../components/LikeButton/LikeButton';
import AddToReadingListButton from '../../components/AddToReadingListButton/AddToReadingListButton';
import BackTo from '../../components/BackTo/BackTo';
import { GlobalContext } from "../../GlobalContext";
import { getCategoryImageById } from '../../core/enums/ImagedCategoryIdsEnum';
import RequestAccessButton from '../../components/RequestAccessButton/RequestAccessButton';
import DownloadDetailsButton from '../../components/DownloadDetailsButton/DownloadDetailsButton';
import PdfReader from '../../components/PdfReader/PdfReader';
import { AnalyticsAction } from "../../core/models/AnalyticsAttributes";
import { FiltersEnum } from "../../components/Filters/Filters.helpers";
import { TagsSelectType } from "../../components/Filters/Filters";
import Tooltip from '../../components/Tooltip/Tooltip';
import useContentWatch from '../../core/apiHooks/useContentWatch';
import useNotification from '../../core/hooks/useNotification';

const TagContentWatch: FC<{
    title: string;
    link: string;
    isActive: boolean;
    onBellClick: () => void;
}> = ({
    title,
    link,
    isActive,
    onBellClick,
}) => {
    const { getRem } = useContext(GlobalContext);
    const { addNotification } = useNotification();

    const onClick = () => {
        const notifyMessage = isActive ? <span>You've removed <b>{title}</b> from your topics list</span>: <span>You've added <b>{title}</b> to your topics list</span>;
        addNotification({
            message: <span>{notifyMessage}</span>,
            icon: isActive ? (
                <IconBellMinus style={{ width: 16, height: 16, }}/>
            ) : (
                <IconBellPlus style={{ width: 16, height: 16, }}/>
            ),
        });
        onBellClick();
    }

    return (
        <Tag>
            <Link
                to={link}
                style={{
                    textDecoration: 'none',
                    padding: `${getRem(5)}px ${getRem(10)}px`
                }}
            >
                {title}
            </Link>
            <div
                style={{
                    width: getRem(16 + 20),
                    height: getRem(16 + 10),
                    cursor: 'pointer',
                    padding: `${getRem(5)}px ${getRem(10)}px`,
                }}
                onClick={onClick}
            >
                {isActive ? (
                    <IconBellFilled
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                    />
                ) : (
                    <IconBellPlus
                        style={{
                            width: '100%',
                            height: '100%',
                            opacity: 0.8,
                        }}
                    />
                )}
                <Tooltip options={['bottom', 'bottom-gutter', 'gray']}>
                    {isActive ? 'Remove from' : 'Add to'}&nbsp;Content Watch
                </Tooltip>
            </div>
        </Tag>
    );
}

const DocumentDetails: React.FC<any> = () => {
    const {
        getIsTagAdded,
        getIsSubscriptionPackageAdded,
        toggleSubscriptionPackage,
        toggleTag,
    } = useContentWatch();
    const match = useRouteMatch<{ id: string }>();
    const history = useHistory();
    const {
        isLoading,
        isSuccess,
        data: { publication: data, relatedPublications, chapters }
    } = useDocumentDetails(+match.params.id);

    const [isOpenedReadPopup, setIsOpenedReadPopup] = useState(false);
    const { getRem } = useContext(GlobalContext);
    const file = data.hasAccess
        ? data.files.find(f => f.type === FileTypesEnum.Pdf)
        : data.files.find(f => f.type === FileTypesEnum.PdfSample);

    useEffect(() => {
        if ((!isLoading && !isSuccess) || !match.params.id)
            history.push(RoutesEnum.Home);
    }, [history, isLoading, isSuccess, match.params.id]);

    const Logo = getCategoryImageById(data.titleCategoryId);

    const preparedSubscriptionPackages = useMemo(
        () => data.subscriptionPackages.filter(item => item.title.toLowerCase().trim().indexOf('enable login') === -1),
        [data.subscriptionPackages]
    );

    return (
        <Page
            title={data.title}
            headerContent={<BackTo/>}
        >
            {isLoading && <Loader/>}
            {!isLoading && (
                <div className="DocumentDetails">
                    <div className="DocumentDetailsData">
                        <div className="DocumentDetailsData__image">
                            <TileImage src={data.image} isDisabled={!data.hasAccess}
                                       style={{ borderRadius: getRem(8) }}/>
                        </div>
                        <div className="DocumentDetailsData__info">
                            {Logo && (
                                <div className="DocumentDetailsData__logo">
                                    <Logo/>
                                </div>
                            )}
                            {data.publicationId !== 0 && (
                                <Link
                                    className="DocumentDetailsData__pre-title"
                                    to={RoutesEnum.DocumentDetails.replace(':id', `${data.publicationId}`)}
                                >
                                    <IconBack className="Icon32"/>
                                    {data.publicationTitle}
                                </Link>
                            )}
                            <div className="DocumentDetailsData__title">
                                {data.title}
                            </div>
                            <div className="DocumentDetailsData__sub-title">
                                <PublicationSubTitle data={data}/>
                            </div>
                            <div
                                className="DocumentDetailsData__description"
                                dangerouslySetInnerHTML={{
                                    __html: data.description
                                }}
                            />
                            <div className="DocumentDetailsData__footer">
                                {!data.hasAccess && (
                                    <RequestAccessButton
                                        articleId={data.id}
                                        documentType={data.publicationType}
                                        className="DocumentDetailsData__button DocumentDetailsData__access-button"
                                    />
                                )}
                                {!!file && (
                                    <Button
                                        className="DocumentDetailsData__button"
                                        options={['secondary']}
                                        onClick={() => setIsOpenedReadPopup(true)}
                                        analyticsData={{
                                            action: data.hasAccess ? AnalyticsAction.Read : AnalyticsAction.ReadSample,
                                            reportId: data.id,
                                            fileType: getFileTypeTitle(file.type),
                                        }}
                                    >
                                        <IconRead className="Icon32"/>
                                        {data.hasAccess ? 'Read' : 'View Sample'}
                                    </Button>
                                )}

                                {data.hasAccess && (
                                    <div
                                        className="DocumentDetailsData__button-container"
                                    >
                                        <Button
                                            className="DocumentDetailsData__button"
                                            options={['primary']}
                                        >
                                            <IconDownload className="Icon32"/>
                                            Download
                                        </Button>
                                        <div className="Button__dropdown">
                                            <DownloadList items={data.files} reportId={data.id}/>
                                        </div>
                                    </div>
                                )}

                                <AddToReadingListButton
                                    className="DocumentDetailsData__button"
                                    data={data}
                                />

                                <LikeButton
                                    className="DocumentDetailsData__button"
                                    data={data}
                                />

                                <ShareButton
                                    className="DocumentDetailsData__button"
                                    data={data}
                                />
                            </div>

                            {!data.hasAccess && (
                                <div className="DocumentDetailsData__access-message">
                                    Download this report and hundreds more by subscribing to one of our amazing report
                                    packages.
                                    <br/>
                                    Want to learn more? Download the details&nbsp;
                                    <DownloadDetailsButton/>
                                    &nbsp;or&nbsp;
                                    <RequestAccessButton
                                        articleId={data.id}
                                        documentType={data.publicationType}
                                        text="contact us"
                                        type="Link"
                                    />
                                    &nbsp;– we'll get right back to you.
                                </div>
                            )}

                            {(data.tags.length > 0 || preparedSubscriptionPackages.length > 0) && (
                                <>
                                    <div className="DocumentDetailsData__tags-title">
                                        Related Topics
                                    </div>
                                    <div className="DocumentDetailsData__tags">
                                        {preparedSubscriptionPackages.map(item => (
                                            <TagContentWatch
                                                key={`${TagsSelectType.Package}-${item.id}`}
                                                link={`${RoutesEnum.Browse}?${FiltersEnum.packages}=${item.id}`}
                                                title={item.title}
                                                isActive={getIsSubscriptionPackageAdded(item.id)}
                                                onBellClick={() => toggleSubscriptionPackage(item.id)}
                                            />
                                        ))}
                                        {data.tags.map(item => (
                                            <TagContentWatch
                                                key={`${TagsSelectType.Tag}-${item.id}`}
                                                link={`${RoutesEnum.Browse}?${FiltersEnum.tags}=${item.id}`}
                                                title={item.title}
                                                isActive={getIsTagAdded(item.id)}
                                                onBellClick={() => toggleTag(item.id)}
                                            />
                                        ))}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    {chapters.length > 0 && data.hasAccess && (
                        <ChildDocuments
                            items={chapters}
                            title="Articles in this issue"
                        />
                    )}
                    {relatedPublications.length > 0 && (
                        <Ribbon
                            data={
                                new ContentRibbon({
                                    clientModel: {
                                        id: 2,
                                        title: 'You also might be interested in',
                                        items: relatedPublications
                                    }
                                })
                            }
                        />
                    )}
                </div>
            )}
            {!!file && !!file.name && (
                <PdfReader
                    isOpened={isOpenedReadPopup}
                    close={() => setIsOpenedReadPopup(false)}
                    title={data.title}
                    fileName={file.name}
                />
            )}
        </Page>
    );
};

export default DocumentDetails;
