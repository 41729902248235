import axios from 'axios';
import { deepCopy } from './helpers';

axios.defaults.withCredentials = true;

export function getApiLink(api: string, queryParams: any = {}) {
    const qs = getQueryString(queryParams);
    return `${process.env.REACT_APP_API}/${api}${qs ? '?' : ''}${qs}`;
}

export function getQueryString(queryParams: any = {}) {
    return Object.keys(queryParams).reduce((acc, param) => {
        let paramString: string = '';

        if (Array.isArray(queryParams[param])) {
            paramString = queryParams[param].reduce((accc: string, val: string) => {
                if (accc === '') {
                    return `${param}=${val}`
                }
                return `${accc}&${param}=${val}`;
            }, '');
        } else {
            paramString = `${param}=${queryParams[param]}`;
        }
        if (acc === '') {
            return `${paramString}`
        }
        return `${acc}&${paramString}`;
    }, '')
}


export const MAC_CATEGORY_TITLE = 'Menu Adoption Cycles';