import React, { useContext, useEffect, useState } from 'react';
import Page from '../../components/Page/Page';
import RoutesEnum from '../../core/enums/RoutesEnum';
import { useLocation } from 'react-router-dom';
import Routes from '../../core/Routes';
import FeaturedContent from '../__Common/FeaturedContent/FeaturedContent';
import Loader from '../../components/Loader/Loader';
import Ribbon from '../../components/Ribbon/Ribbon';
import useContentRibbons from '../../core/apiHooks/useContentRibbons';
import Button, { ButtonTypeEnum } from '../../components/Button/Button';
import './Library.styles.scss';
import { IconArrowRight, IconInfo } from '../../icons';
import useFeaturedPublications from '../../core/apiHooks/useFeaturedPublications';
import { FiltersEnum } from '../../components/Filters/Filters.helpers';
import { GlobalContext } from "../../GlobalContext";
import PdfReader from '../../components/PdfReader/PdfReader';
import Slider from '../../components/Slider/Slider';
import Checkbox from "../../components/Checkbox/Checkbox";

const NEW_RELEASES_ID = 18;

const Library: React.FC = () => {
    const {
        inMyLibrary,
        setInMyLibrary,
    } = useContext(GlobalContext);
    const { data, isLoading } = useContentRibbons(inMyLibrary);
    const { data: featuredPublications, isLoading: isFeaturedContentLoading } = useFeaturedPublications();
    const location = useLocation();
    const route = Routes.find(({ path }) => path === location.pathname) || Routes[0];
    const title = route?.title || '';
    const [isMacInfoPopupOpened, setIsMacInfoPopupOpened] = useState(false);

    const ribbons = data;

    return (
        <Page
            title={title}
            isShownStickyFooter={!!ribbons.length}
            headerRightContent={(
                <div className="Library__header-content">
                    <Checkbox
                        isChecked={inMyLibrary}
                        label="In my library"
                        onChange={() => setInMyLibrary(!inMyLibrary)}
                        isToggle
                    />
                </div>
            )}
        >
            <div className="Library">
                {!isFeaturedContentLoading && featuredPublications.length > 0 && (
                    <div className="Library__section">
                        <Slider>
                            {featuredPublications.map(p => (
                                <FeaturedContent key={p.id} data={p}/>
                            ))}
                        </Slider>
                    </div>
                )}
                {isFeaturedContentLoading && (
                    <div className="Library__section">
                        <Loader/>
                    </div>
                )}
                <div className="Library__section">
                    {isLoading && !isFeaturedContentLoading && (
                        <Loader/>
                    )}
                    {!isLoading && (
                        <>
                            {ribbons.map((ribbon) => {
                                const title = (
                                    <>
                                        {ribbon.title}
                                        {ribbon.isMacRibbon && (
                                            <>
                                                <Button
                                                    className="Library__title-icon"
                                                    onClick={() => setIsMacInfoPopupOpened(true)}
                                                >
                                                    <IconInfo/>
                                                </Button>
                                                <PdfReader
                                                    isOpened={isMacInfoPopupOpened}
                                                    close={() => setIsMacInfoPopupOpened(false)}
                                                    title="MAC Diagram"
                                                    fileUrl={'/MAC_DIAGRAM.pdf'}
                                                />
                                            </>
                                        )}
                                        <Button
                                            className="ShowAllButton"
                                            type={ButtonTypeEnum.Link}
                                            link={`${RoutesEnum.Browse}?${FiltersEnum.ribbonsIds}=${ribbon.id === NEW_RELEASES_ID ? [] : ribbon.id}`}
                                        >
                                            Show all
                                            <IconArrowRight
                                                style={{
                                                    transform: 'scale(0.8) translate(-30%, 3%)',
                                                }}
                                            />
                                        </Button>
                                    </>
                                );

                                return (
                                    <Ribbon key={ribbon.id} data={{ ...ribbon, title }}/>
                                );
                            })}
                        </>
                    )}
                </div>
            </div>
        </Page>
    );
};

export default Library;
