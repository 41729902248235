import "./LineSpinner.styles.scss";

const LineSpinner = () => {
    return (
        <div className="LineSpinner">
            <span className="LineSpinner__dot"></span>
            <span className="LineSpinner__dot"></span>
            <span className="LineSpinner__dot"></span>
        </div>
    );
};

export default LineSpinner;
