import React, { useContext, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { GlobalContext } from "../../GlobalContext";
import { renderVerticalThumb } from "../Scrollbar/ScrollbarThumb";
import "./Textarea.styles.scss";

interface iProps {
    placeholder?: string;
    value: string;
    onChange: (value: string) => void;
    isDisabled?: boolean;
}


const Textarea: React.FC<iProps> = ({onChange, placeholder = "", value, isDisabled}) => {
    const { getRem } = useContext(GlobalContext);
    const SCROLLBAR_WIDTH = getRem(6);
    const MAX_HEIGHT = getRem(170);
    const INITIAL_HEIGHT = MAX_HEIGHT - SCROLLBAR_WIDTH;
    const [height, setHeight] = useState(INITIAL_HEIGHT);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [isFocused, setIsFocused] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChange(e.target.value)

        if ( textareaRef.current ) {
            textareaRef.current.value = e.target.value;
            const currentHeight = e.target ? textareaRef.current.scrollHeight : INITIAL_HEIGHT;
            setHeight(currentHeight);
        }
    }
    return (
        <div className="Textarea">
            <Scrollbars
                className={`Textarea__scrollbars ${isFocused ? "is-focus":""}`}
                autoHeight
                autoHeightMin={MAX_HEIGHT}
                autoHeightMax={MAX_HEIGHT}
                renderThumbVertical={renderVerticalThumb}

            >
                <textarea
                    className="Textarea__input is-visible"
                    style={{height}}
                    placeholder={placeholder}
                    onChange={handleChange}
                    value={value}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    disabled={isDisabled}
                />
            </Scrollbars>

            <textarea ref={textareaRef} className="Textarea__input is-hidden" disabled={isDisabled}/>
        </div>
    );
};

export default Textarea;
