import { ReactComponent as AddToRL } from './Add to Reading list.svg';
import { ReactComponent as AddedToRL } from './Added.svg';
import { ReactComponent as ArrowDown } from './Arrow Down.svg';
import { ReactComponent as ArrowLeft } from './Arrow Left.svg';
import { ReactComponent as ArrowRight } from './Arrow Right.svg';
import { ReactComponent as ArrowUp } from './Arrow Up.svg';
import { ReactComponent as Back } from './Back.svg';
import { ReactComponent as Chapters } from './Chapters.svg';
import { ReactComponent as Chop } from './Chop.svg';
import { ReactComponent as Close } from './Close.svg';
import { ReactComponent as Download } from './Download.svg';
import { ReactComponent as Like } from './Like.svg';
import { ReactComponent as Logo } from './Logo.svg';
import { ReactComponent as LogoBig } from './Logo big.svg';
import { ReactComponent as Search } from './Search.svg';
import { ReactComponent as Share } from './Share.svg';
import { ReactComponent as RL } from './Reading lists.svg';
import { ReactComponent as Reset } from './Reset.svg';
import { ReactComponent as Check } from './Check.svg';
import { ReactComponent as Liked } from './Liked.svg';
import { ReactComponent as Read } from './Read.svg';
import { ReactComponent as ShowAll } from './Show All.svg';
import { ReactComponent as GridType1 } from './Grid_type_1.svg';
import { ReactComponent as GridType2 } from './Grid_type_2.svg';
import { ReactComponent as GridType3 } from './Grid_type_3.svg';
import { ReactComponent as ZoomIn } from './zoom-in.svg';
import { ReactComponent as ZoomOut } from './zoom-out.svg';
import { ReactComponent as Xls } from './xls.svg';
import { ReactComponent as Pdf } from './pdf.svg';
import { ReactComponent as Ppt } from './ppt.svg';
import { ReactComponent as Success } from './Success.svg';
import { ReactComponent as Errors } from './Error.svg';
import { ReactComponent as Deny } from './Deny.svg';
import { ReactComponent as Delete } from './Delete.svg';
import { ReactComponent as DownloadFilled } from './Download Filled.svg';
import { ReactComponent as Bell } from './bell.svg';
import { ReactComponent as PlusSquare } from './plus-square.svg';
import { ReactComponent as BellPlus } from './notifications-plus.svg';
import { ReactComponent as BellMinus } from './notifications-minus.svg';
import { ReactComponent as BellFilled } from './notifications-filled.svg';

import { ReactComponent as WorldBites } from './WB.svg';
import { ReactComponent as CreativeConcepts } from './CC.svg';
import { ReactComponent as InternationalConcepts } from './IC.svg';
import { ReactComponent as FoodBytes } from './FB.svg';
import { ReactComponent as HotShots } from './HotShots_Logo_White 1.svg';
import { ReactComponent as TrendWatch } from './TW.svg';
import { ReactComponent as DineAround } from './DA.svg';
import { ReactComponent as Keynotes } from './Keynote_Logo_White 1.svg';
import { ReactComponent as Pulse } from './Pulse_Logo_White 1.svg';
import { ReactComponent as Buzz } from './Buzz_Logo_White 1.svg';
import { ReactComponent as Mac } from './Group 313.svg';
import { ReactComponent as Snap } from './SNAP.svg';
import { ReactComponent as BackToTop } from './back-to-top.svg';
import { ReactComponent as Info } from './Info.svg';
import { FunctionComponent, SVGProps } from 'react';

type IconType = FunctionComponent<SVGProps<SVGSVGElement>>;

export const IconAddToRL: IconType = AddToRL;
export const IconAddedToRL: IconType = AddedToRL;
export const IconArrowDown: IconType = ArrowDown;
export const IconArrowLeft: IconType = ArrowLeft;
export const IconArrowRight: IconType = ArrowRight;
export const IconArrowUp: IconType = ArrowUp;
export const IconBack: IconType = Back;
export const IconChapters: IconType = Chapters;
export const IconChop: IconType = Chop;
export const IconClose: IconType = Close;
export const IconDownload: IconType = Download;
export const IconLike: IconType = Like;
export const IconLogo: IconType = Logo;
export const IconLogoBig: IconType = LogoBig;
export const IconSearch: IconType = Search;
export const IconShare: IconType = Share;
export const IconRL: IconType = RL;
export const IconCheck: IconType = Check;
export const IconLiked: IconType = Liked;
export const IconRead: IconType = Read;
export const IconShowAll: IconType = ShowAll;
export const IconGridType1: IconType = GridType1;
export const IconGridType2: IconType = GridType2;
export const IconGridType3: IconType = GridType3;
export const IconZoomIn: IconType = ZoomIn;
export const IconZoomOut: IconType = ZoomOut;
export const IconPdf: IconType = Pdf;
export const IconXls: IconType = Xls;
export const IconPpt: IconType = Ppt;
export const IconReset: IconType = Reset;
export const IconSuccess: IconType = Success;
export const IconError: IconType = Errors;
export const IconDeny: IconType = Deny;
export const IconDelete: IconType = Delete;
export const IconDownloadFilled: IconType = DownloadFilled;
export const IconBackToTop: IconType = BackToTop;
export const IconInfo: IconType = Info;
export const IconBell: IconType = Bell;
export const IconBellPlus: IconType = BellPlus;
export const IconBellMinus: IconType = BellMinus;
export const IconBellFilled: IconType = BellFilled;
export const IconPlusSquare: IconType = PlusSquare;

export const LogoSnap: IconType = Snap;
export const LogoWorldBites: IconType = WorldBites;
export const LogoCreativeConcepts: IconType = CreativeConcepts;
export const LogoInternationalConcepts: IconType = InternationalConcepts;
export const LogoFoodBytes: IconType = FoodBytes;
export const LogoHotShots: IconType = HotShots;
export const LogoTrendWatch: IconType = TrendWatch;
export const LogoDineAround: IconType = DineAround;
export const LogoKeynotes: IconType = Keynotes;
export const LogoPulse: IconType = Pulse;
export const LogoBuzz: IconType = Buzz;
export const LogoMac: IconType = Mac;
